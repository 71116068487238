import { api } from "./baseApi";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  jsonb: { input: any; output: any };
  timestamptz: { input: any; output: any };
  uuid: { input: any; output: any };
};

export type Body = {
  __typename?: "Body";
  access_token?: Maybe<Scalars["String"]["output"]>;
  expires_in?: Maybe<Scalars["Int"]["output"]>;
  id_token?: Maybe<Scalars["String"]["output"]>;
  refresh_token?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Scalars["String"]["output"]>;
  token_type?: Maybe<Scalars["String"]["output"]>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

export type DeleteUserInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteUserOutput = {
  __typename?: "DeleteUserOutput";
  body: DeleteUserResponseBody;
  statusCode?: Maybe<Scalars["Int"]["output"]>;
};

export type DeleteUserResponseBody = {
  __typename?: "DeleteUserResponseBody";
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ForgotPasswordInput = {
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type ForgotPasswordOutput = {
  __typename?: "ForgotPasswordOutput";
  body: ForgotPasswordResponseBody;
  statusCode?: Maybe<Scalars["Int"]["output"]>;
};

export type ForgotPasswordResponseBody = {
  __typename?: "ForgotPasswordResponseBody";
  message?: Maybe<Scalars["String"]["output"]>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]["input"]>;
  _gt?: InputMaybe<Scalars["Int"]["input"]>;
  _gte?: InputMaybe<Scalars["Int"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Int"]["input"]>;
  _lte?: InputMaybe<Scalars["Int"]["input"]>;
  _neq?: InputMaybe<Scalars["Int"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type LoginInput = {
  password?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginOutput = {
  __typename?: "LoginOutput";
  body?: Maybe<Body>;
};

export type RegisterUserInput = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type RegisterUserOutput = {
  __typename?: "RegisterUserOutput";
  body: RegisterUserResponseData;
};

export type RegisterUserResponseData = {
  __typename?: "RegisterUserResponseData";
  message?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _eq?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _gt?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _gte?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Array<Scalars["String"]["input"]>>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _lte?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _neq?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _nin?: InputMaybe<Array<Array<Scalars["String"]["input"]>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]["input"]>;
  _gt?: InputMaybe<Scalars["String"]["input"]>;
  _gte?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]["input"]>;
  _in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]["input"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]["input"]>;
  _lt?: InputMaybe<Scalars["String"]["input"]>;
  _lte?: InputMaybe<Scalars["String"]["input"]>;
  _neq?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]["input"]>;
};

export type User = {
  __typename?: "User";
  activated: Scalars["timestamptz"]["output"];
  created: Scalars["timestamptz"]["output"];
  profile?: Maybe<UserProfile>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  login?: Maybe<Scalars["String"]["output"]>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** columns and relationships of "job_inspection_questionaire" */
export type Job_Inspection_Questionaire = {
  __typename?: "job_inspection_questionaire";
  array_value?: Maybe<Array<Scalars["String"]["output"]>>;
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars["uuid"]["output"];
  question: Scalars["uuid"]["output"];
  questionaire: Scalars["uuid"]["output"];
  /** An object relationship */
  questionaire_section: Questionaire_Sections;
  /** An object relationship */
  schema?: Maybe<Questionaire_Schemas>;
  section: Scalars["uuid"]["output"];
  unit_id: Scalars["uuid"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

/** aggregated selection of "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Aggregate = {
  __typename?: "job_inspection_questionaire_aggregate";
  aggregate?: Maybe<Job_Inspection_Questionaire_Aggregate_Fields>;
  nodes: Array<Job_Inspection_Questionaire>;
};

export type Job_Inspection_Questionaire_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Inspection_Questionaire_Aggregate_Bool_Exp_Count>;
};

export type Job_Inspection_Questionaire_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Aggregate_Fields = {
  __typename?: "job_inspection_questionaire_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Job_Inspection_Questionaire_Max_Fields>;
  min?: Maybe<Job_Inspection_Questionaire_Min_Fields>;
};

/** aggregate fields of "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Inspection_Questionaire_Max_Order_By>;
  min?: InputMaybe<Job_Inspection_Questionaire_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Arr_Rel_Insert_Input = {
  data: Array<Job_Inspection_Questionaire_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Inspection_Questionaire_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_inspection_questionaire". All fields are combined with a logical 'AND'. */
export type Job_Inspection_Questionaire_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Inspection_Questionaire_Bool_Exp>>;
  _not?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Inspection_Questionaire_Bool_Exp>>;
  array_value?: InputMaybe<String_Array_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Uuid_Comparison_Exp>;
  questionaire?: InputMaybe<Uuid_Comparison_Exp>;
  questionaire_section?: InputMaybe<Questionaire_Sections_Bool_Exp>;
  schema?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
  section?: InputMaybe<Uuid_Comparison_Exp>;
  unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_inspection_questionaire" */
export enum Job_Inspection_Questionaire_Constraint {
  /** unique or primary key constraint on columns "unit_id", "section", "question", "job_id", "questionaire" */
  JobInspectionQuestionairePkey = "job_inspection_questionaire_pkey",
}

/** input type for inserting data into table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Insert_Input = {
  array_value?: InputMaybe<Array<Scalars["String"]["input"]>>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  question?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire_section?: InputMaybe<Questionaire_Sections_Obj_Rel_Insert_Input>;
  schema?: InputMaybe<Questionaire_Schemas_Obj_Rel_Insert_Input>;
  section?: InputMaybe<Scalars["uuid"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Job_Inspection_Questionaire_Max_Fields = {
  __typename?: "job_inspection_questionaire_max_fields";
  array_value?: Maybe<Array<Scalars["String"]["output"]>>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  question?: Maybe<Scalars["uuid"]["output"]>;
  questionaire?: Maybe<Scalars["uuid"]["output"]>;
  section?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Max_Order_By = {
  array_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Inspection_Questionaire_Min_Fields = {
  __typename?: "job_inspection_questionaire_min_fields";
  array_value?: Maybe<Array<Scalars["String"]["output"]>>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  question?: Maybe<Scalars["uuid"]["output"]>;
  questionaire?: Maybe<Scalars["uuid"]["output"]>;
  section?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Min_Order_By = {
  array_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Mutation_Response = {
  __typename?: "job_inspection_questionaire_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Inspection_Questionaire>;
};

/** on_conflict condition type for table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_On_Conflict = {
  constraint: Job_Inspection_Questionaire_Constraint;
  update_columns?: Array<Job_Inspection_Questionaire_Update_Column>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

/** Ordering options when selecting data from "job_inspection_questionaire". */
export type Job_Inspection_Questionaire_Order_By = {
  array_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  job_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  questionaire_section?: InputMaybe<Questionaire_Sections_Order_By>;
  schema?: InputMaybe<Questionaire_Schemas_Order_By>;
  section?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_inspection_questionaire */
export type Job_Inspection_Questionaire_Pk_Columns_Input = {
  job_id: Scalars["uuid"]["input"];
  question: Scalars["uuid"]["input"];
  questionaire: Scalars["uuid"]["input"];
  section: Scalars["uuid"]["input"];
  unit_id: Scalars["uuid"]["input"];
};

/** select columns of table "job_inspection_questionaire" */
export enum Job_Inspection_Questionaire_Select_Column {
  /** column name */
  ArrayValue = "array_value",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  JobId = "job_id",
  /** column name */
  Question = "question",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  Section = "section",
  /** column name */
  UnitId = "unit_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Set_Input = {
  array_value?: InputMaybe<Array<Scalars["String"]["input"]>>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  question?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["uuid"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "job_inspection_questionaire" */
export type Job_Inspection_Questionaire_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Inspection_Questionaire_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Inspection_Questionaire_Stream_Cursor_Value_Input = {
  array_value?: InputMaybe<Array<Scalars["String"]["input"]>>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  question?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["uuid"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "job_inspection_questionaire" */
export enum Job_Inspection_Questionaire_Update_Column {
  /** column name */
  ArrayValue = "array_value",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  JobId = "job_id",
  /** column name */
  Question = "question",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  Section = "section",
  /** column name */
  UnitId = "unit_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Job_Inspection_Questionaire_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Inspection_Questionaire_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Inspection_Questionaire_Bool_Exp;
};

/** columns and relationships of "job_status" */
export type Job_Status = {
  __typename?: "job_status";
  description?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

/** aggregated selection of "job_status" */
export type Job_Status_Aggregate = {
  __typename?: "job_status_aggregate";
  aggregate?: Maybe<Job_Status_Aggregate_Fields>;
  nodes: Array<Job_Status>;
};

/** aggregate fields of "job_status" */
export type Job_Status_Aggregate_Fields = {
  __typename?: "job_status_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Job_Status_Max_Fields>;
  min?: Maybe<Job_Status_Min_Fields>;
};

/** aggregate fields of "job_status" */
export type Job_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "job_status". All fields are combined with a logical 'AND'. */
export type Job_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Status_Bool_Exp>>;
  _not?: InputMaybe<Job_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_status" */
export enum Job_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  JobStatusPkey = "job_status_pkey",
}

/** input type for inserting data into table "job_status" */
export type Job_Status_Insert_Input = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Job_Status_Max_Fields = {
  __typename?: "job_status_max_fields";
  description?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Job_Status_Min_Fields = {
  __typename?: "job_status_min_fields";
  description?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "job_status" */
export type Job_Status_Mutation_Response = {
  __typename?: "job_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Status>;
};

/** on_conflict condition type for table "job_status" */
export type Job_Status_On_Conflict = {
  constraint: Job_Status_Constraint;
  update_columns?: Array<Job_Status_Update_Column>;
  where?: InputMaybe<Job_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "job_status". */
export type Job_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_status */
export type Job_Status_Pk_Columns_Input = {
  status: Scalars["String"]["input"];
};

/** select columns of table "job_status" */
export enum Job_Status_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "job_status" */
export type Job_Status_Set_Input = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "job_status" */
export type Job_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "job_status" */
export enum Job_Status_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Status = "status",
}

export type Job_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Status_Bool_Exp;
};

/** columns and relationships of "job_unit_steps" */
export type Job_Unit_Steps = {
  __typename?: "job_unit_steps";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  job?: Maybe<Jobs>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  step_id?: Maybe<Scalars["uuid"]["output"]>;
  /** An object relationship */
  steps_type?: Maybe<Step_Types>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregated selection of "job_unit_steps" */
export type Job_Unit_Steps_Aggregate = {
  __typename?: "job_unit_steps_aggregate";
  aggregate?: Maybe<Job_Unit_Steps_Aggregate_Fields>;
  nodes: Array<Job_Unit_Steps>;
};

export type Job_Unit_Steps_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Unit_Steps_Aggregate_Bool_Exp_Count>;
};

export type Job_Unit_Steps_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_unit_steps" */
export type Job_Unit_Steps_Aggregate_Fields = {
  __typename?: "job_unit_steps_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Job_Unit_Steps_Max_Fields>;
  min?: Maybe<Job_Unit_Steps_Min_Fields>;
};

/** aggregate fields of "job_unit_steps" */
export type Job_Unit_Steps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "job_unit_steps" */
export type Job_Unit_Steps_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Unit_Steps_Max_Order_By>;
  min?: InputMaybe<Job_Unit_Steps_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job_unit_steps" */
export type Job_Unit_Steps_Arr_Rel_Insert_Input = {
  data: Array<Job_Unit_Steps_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Unit_Steps_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_unit_steps". All fields are combined with a logical 'AND'. */
export type Job_Unit_Steps_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Unit_Steps_Bool_Exp>>;
  _not?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Unit_Steps_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  photo?: InputMaybe<String_Comparison_Exp>;
  step_id?: InputMaybe<Uuid_Comparison_Exp>;
  steps_type?: InputMaybe<Step_Types_Bool_Exp>;
  unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_unit_steps" */
export enum Job_Unit_Steps_Constraint {
  /** unique or primary key constraint on columns "unit_id", "step_id", "job_id" */
  JobUnitStepsJobIdUnitIdStepIdKey = "job_unit_steps_job_id_unit_id_step_id_key",
  /** unique or primary key constraint on columns "id" */
  JobUnitStepsPkey = "job_unit_steps_pkey",
}

/** input type for inserting data into table "job_unit_steps" */
export type Job_Unit_Steps_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  step_id?: InputMaybe<Scalars["uuid"]["input"]>;
  steps_type?: InputMaybe<Step_Types_Obj_Rel_Insert_Input>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Job_Unit_Steps_Max_Fields = {
  __typename?: "job_unit_steps_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  step_id?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "job_unit_steps" */
export type Job_Unit_Steps_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  step_id?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Unit_Steps_Min_Fields = {
  __typename?: "job_unit_steps_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  step_id?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "job_unit_steps" */
export type Job_Unit_Steps_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  step_id?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_unit_steps" */
export type Job_Unit_Steps_Mutation_Response = {
  __typename?: "job_unit_steps_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Unit_Steps>;
};

/** on_conflict condition type for table "job_unit_steps" */
export type Job_Unit_Steps_On_Conflict = {
  constraint: Job_Unit_Steps_Constraint;
  update_columns?: Array<Job_Unit_Steps_Update_Column>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

/** Ordering options when selecting data from "job_unit_steps". */
export type Job_Unit_Steps_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  job_id?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  step_id?: InputMaybe<Order_By>;
  steps_type?: InputMaybe<Step_Types_Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_unit_steps */
export type Job_Unit_Steps_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "job_unit_steps" */
export enum Job_Unit_Steps_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  JobId = "job_id",
  /** column name */
  Photo = "photo",
  /** column name */
  StepId = "step_id",
  /** column name */
  UnitId = "unit_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "job_unit_steps" */
export type Job_Unit_Steps_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  step_id?: InputMaybe<Scalars["uuid"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "job_unit_steps" */
export type Job_Unit_Steps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Unit_Steps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Unit_Steps_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  step_id?: InputMaybe<Scalars["uuid"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "job_unit_steps" */
export enum Job_Unit_Steps_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  JobId = "job_id",
  /** column name */
  Photo = "photo",
  /** column name */
  StepId = "step_id",
  /** column name */
  UnitId = "unit_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Job_Unit_Steps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Unit_Steps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Unit_Steps_Bool_Exp;
};

/** columns and relationships of "job_units" */
export type Job_Units = {
  __typename?: "job_units";
  checklist_submitted?: Maybe<Scalars["Boolean"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An array relationship */
  inspection: Array<Job_Inspection_Questionaire>;
  /** An aggregate relationship */
  inspection_aggregate: Job_Inspection_Questionaire_Aggregate;
  is_gas_or_electric_unit?: Maybe<Scalars["Boolean"]["output"]>;
  /** An object relationship */
  job?: Maybe<Jobs>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  model_no?: Maybe<Scalars["String"]["output"]>;
  photo_barcode_url?: Maybe<Scalars["String"]["output"]>;
  serial_no?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  startup_verify_checklists: Array<Startup_Verify_Checklist>;
  /** An aggregate relationship */
  startup_verify_checklists_aggregate: Startup_Verify_Checklist_Aggregate;
  /** An array relationship */
  steps: Array<Job_Unit_Steps>;
  /** An aggregate relationship */
  steps_aggregate: Job_Unit_Steps_Aggregate;
  unit_name?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  verify_checklists: Array<Verify_Checklist>;
  /** An aggregate relationship */
  verify_checklists_aggregate: Verify_Checklist_Aggregate;
};

/** columns and relationships of "job_units" */
export type Job_UnitsInspectionArgs = {
  distinct_on?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Inspection_Questionaire_Order_By>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsInspection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Inspection_Questionaire_Order_By>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsStartup_Verify_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Startup_Verify_Checklist_Order_By>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsStartup_Verify_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Startup_Verify_Checklist_Order_By>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsStepsArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsSteps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsVerify_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

/** columns and relationships of "job_units" */
export type Job_UnitsVerify_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

/** aggregated selection of "job_units" */
export type Job_Units_Aggregate = {
  __typename?: "job_units_aggregate";
  aggregate?: Maybe<Job_Units_Aggregate_Fields>;
  nodes: Array<Job_Units>;
};

export type Job_Units_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Job_Units_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Job_Units_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Job_Units_Aggregate_Bool_Exp_Count>;
};

export type Job_Units_Aggregate_Bool_Exp_Bool_And = {
  arguments: Job_Units_Select_Column_Job_Units_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Job_Units_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Job_Units_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Job_Units_Select_Column_Job_Units_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Job_Units_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Job_Units_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Units_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Job_Units_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_units" */
export type Job_Units_Aggregate_Fields = {
  __typename?: "job_units_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Job_Units_Max_Fields>;
  min?: Maybe<Job_Units_Min_Fields>;
};

/** aggregate fields of "job_units" */
export type Job_Units_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Units_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "job_units" */
export type Job_Units_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Units_Max_Order_By>;
  min?: InputMaybe<Job_Units_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job_units" */
export type Job_Units_Arr_Rel_Insert_Input = {
  data: Array<Job_Units_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Units_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_units". All fields are combined with a logical 'AND'. */
export type Job_Units_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Units_Bool_Exp>>;
  _not?: InputMaybe<Job_Units_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Units_Bool_Exp>>;
  checklist_submitted?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspection?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
  inspection_aggregate?: InputMaybe<Job_Inspection_Questionaire_Aggregate_Bool_Exp>;
  is_gas_or_electric_unit?: InputMaybe<Boolean_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  model_no?: InputMaybe<String_Comparison_Exp>;
  photo_barcode_url?: InputMaybe<String_Comparison_Exp>;
  serial_no?: InputMaybe<String_Comparison_Exp>;
  startup_verify_checklists?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
  startup_verify_checklists_aggregate?: InputMaybe<Startup_Verify_Checklist_Aggregate_Bool_Exp>;
  steps?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
  steps_aggregate?: InputMaybe<Job_Unit_Steps_Aggregate_Bool_Exp>;
  unit_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  verify_checklists?: InputMaybe<Verify_Checklist_Bool_Exp>;
  verify_checklists_aggregate?: InputMaybe<Verify_Checklist_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "job_units" */
export enum Job_Units_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobUnitsPkey = "job_units_pkey",
}

/** input type for inserting data into table "job_units" */
export type Job_Units_Insert_Input = {
  checklist_submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  inspection?: InputMaybe<Job_Inspection_Questionaire_Arr_Rel_Insert_Input>;
  is_gas_or_electric_unit?: InputMaybe<Scalars["Boolean"]["input"]>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  model_no?: InputMaybe<Scalars["String"]["input"]>;
  photo_barcode_url?: InputMaybe<Scalars["String"]["input"]>;
  serial_no?: InputMaybe<Scalars["String"]["input"]>;
  startup_verify_checklists?: InputMaybe<Startup_Verify_Checklist_Arr_Rel_Insert_Input>;
  steps?: InputMaybe<Job_Unit_Steps_Arr_Rel_Insert_Input>;
  unit_name?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  verify_checklists?: InputMaybe<Verify_Checklist_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Job_Units_Max_Fields = {
  __typename?: "job_units_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  model_no?: Maybe<Scalars["String"]["output"]>;
  photo_barcode_url?: Maybe<Scalars["String"]["output"]>;
  serial_no?: Maybe<Scalars["String"]["output"]>;
  unit_name?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "job_units" */
export type Job_Units_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  model_no?: InputMaybe<Order_By>;
  photo_barcode_url?: InputMaybe<Order_By>;
  serial_no?: InputMaybe<Order_By>;
  unit_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Units_Min_Fields = {
  __typename?: "job_units_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  model_no?: Maybe<Scalars["String"]["output"]>;
  photo_barcode_url?: Maybe<Scalars["String"]["output"]>;
  serial_no?: Maybe<Scalars["String"]["output"]>;
  unit_name?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "job_units" */
export type Job_Units_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  model_no?: InputMaybe<Order_By>;
  photo_barcode_url?: InputMaybe<Order_By>;
  serial_no?: InputMaybe<Order_By>;
  unit_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_units" */
export type Job_Units_Mutation_Response = {
  __typename?: "job_units_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Units>;
};

/** input type for inserting object relation for remote table "job_units" */
export type Job_Units_Obj_Rel_Insert_Input = {
  data: Job_Units_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Units_On_Conflict>;
};

/** on_conflict condition type for table "job_units" */
export type Job_Units_On_Conflict = {
  constraint: Job_Units_Constraint;
  update_columns?: Array<Job_Units_Update_Column>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

/** Ordering options when selecting data from "job_units". */
export type Job_Units_Order_By = {
  checklist_submitted?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspection_aggregate?: InputMaybe<Job_Inspection_Questionaire_Aggregate_Order_By>;
  is_gas_or_electric_unit?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  job_id?: InputMaybe<Order_By>;
  model_no?: InputMaybe<Order_By>;
  photo_barcode_url?: InputMaybe<Order_By>;
  serial_no?: InputMaybe<Order_By>;
  startup_verify_checklists_aggregate?: InputMaybe<Startup_Verify_Checklist_Aggregate_Order_By>;
  steps_aggregate?: InputMaybe<Job_Unit_Steps_Aggregate_Order_By>;
  unit_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verify_checklists_aggregate?: InputMaybe<Verify_Checklist_Aggregate_Order_By>;
};

/** primary key columns input for table: job_units */
export type Job_Units_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "job_units" */
export enum Job_Units_Select_Column {
  /** column name */
  ChecklistSubmitted = "checklist_submitted",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsGasOrElectricUnit = "is_gas_or_electric_unit",
  /** column name */
  JobId = "job_id",
  /** column name */
  ModelNo = "model_no",
  /** column name */
  PhotoBarcodeUrl = "photo_barcode_url",
  /** column name */
  SerialNo = "serial_no",
  /** column name */
  UnitName = "unit_name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "job_units_aggregate_bool_exp_bool_and_arguments_columns" columns of table "job_units" */
export enum Job_Units_Select_Column_Job_Units_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ChecklistSubmitted = "checklist_submitted",
  /** column name */
  IsGasOrElectricUnit = "is_gas_or_electric_unit",
}

/** select "job_units_aggregate_bool_exp_bool_or_arguments_columns" columns of table "job_units" */
export enum Job_Units_Select_Column_Job_Units_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ChecklistSubmitted = "checklist_submitted",
  /** column name */
  IsGasOrElectricUnit = "is_gas_or_electric_unit",
}

/** input type for updating data in table "job_units" */
export type Job_Units_Set_Input = {
  checklist_submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_gas_or_electric_unit?: InputMaybe<Scalars["Boolean"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  model_no?: InputMaybe<Scalars["String"]["input"]>;
  photo_barcode_url?: InputMaybe<Scalars["String"]["input"]>;
  serial_no?: InputMaybe<Scalars["String"]["input"]>;
  unit_name?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "job_units" */
export type Job_Units_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Units_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Units_Stream_Cursor_Value_Input = {
  checklist_submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_gas_or_electric_unit?: InputMaybe<Scalars["Boolean"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  model_no?: InputMaybe<Scalars["String"]["input"]>;
  photo_barcode_url?: InputMaybe<Scalars["String"]["input"]>;
  serial_no?: InputMaybe<Scalars["String"]["input"]>;
  unit_name?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "job_units" */
export enum Job_Units_Update_Column {
  /** column name */
  ChecklistSubmitted = "checklist_submitted",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsGasOrElectricUnit = "is_gas_or_electric_unit",
  /** column name */
  JobId = "job_id",
  /** column name */
  ModelNo = "model_no",
  /** column name */
  PhotoBarcodeUrl = "photo_barcode_url",
  /** column name */
  SerialNo = "serial_no",
  /** column name */
  UnitName = "unit_name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Job_Units_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Units_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Units_Bool_Exp;
};

/** columns and relationships of "jobs" */
export type Jobs = {
  __typename?: "jobs";
  address?: Maybe<Scalars["String"]["output"]>;
  business_name?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  company?: Maybe<Register>;
  company_id?: Maybe<Scalars["uuid"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["uuid"]["output"];
  job_date?: Maybe<Scalars["timestamptz"]["output"]>;
  job_type: Scalars["String"]["output"];
  /** An array relationship */
  job_unit_steps: Array<Job_Unit_Steps>;
  /** An aggregate relationship */
  job_unit_steps_aggregate: Job_Unit_Steps_Aggregate;
  /** An array relationship */
  job_units: Array<Job_Units>;
  /** An aggregate relationship */
  job_units_aggregate: Job_Units_Aggregate;
  location_number?: Maybe<Scalars["Int"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  report_submitted?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  technician?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user_id: Scalars["String"]["output"];
  /** An array relationship */
  verify_checklists: Array<Verify_Checklist>;
  /** An aggregate relationship */
  verify_checklists_aggregate: Verify_Checklist_Aggregate;
  zipcode?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "jobs" */
export type JobsJob_Unit_StepsArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsJob_Unit_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsJob_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Job_Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Units_Order_By>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsJob_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Units_Order_By>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsVerify_ChecklistsArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsVerify_Checklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

/** aggregated selection of "jobs" */
export type Jobs_Aggregate = {
  __typename?: "jobs_aggregate";
  aggregate?: Maybe<Jobs_Aggregate_Fields>;
  nodes: Array<Jobs>;
};

/** aggregate fields of "jobs" */
export type Jobs_Aggregate_Fields = {
  __typename?: "jobs_aggregate_fields";
  avg?: Maybe<Jobs_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Jobs_Max_Fields>;
  min?: Maybe<Jobs_Min_Fields>;
  stddev?: Maybe<Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Jobs_Sum_Fields>;
  var_pop?: Maybe<Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Jobs_Var_Samp_Fields>;
  variance?: Maybe<Jobs_Variance_Fields>;
};

/** aggregate fields of "jobs" */
export type Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Jobs_Avg_Fields = {
  __typename?: "jobs_avg_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "jobs". All fields are combined with a logical 'AND'. */
export type Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Jobs_Bool_Exp>>;
  _not?: InputMaybe<Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Jobs_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  business_name?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Register_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  job_type?: InputMaybe<String_Comparison_Exp>;
  job_unit_steps?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
  job_unit_steps_aggregate?: InputMaybe<Job_Unit_Steps_Aggregate_Bool_Exp>;
  job_units?: InputMaybe<Job_Units_Bool_Exp>;
  job_units_aggregate?: InputMaybe<Job_Units_Aggregate_Bool_Exp>;
  location_number?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  report_submitted?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  technician?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  verify_checklists?: InputMaybe<Verify_Checklist_Bool_Exp>;
  verify_checklists_aggregate?: InputMaybe<Verify_Checklist_Aggregate_Bool_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "jobs" */
export enum Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobsPkey = "jobs_pkey",
}

/** input type for incrementing numeric columns in table "jobs" */
export type Jobs_Inc_Input = {
  location_number?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "jobs" */
export type Jobs_Insert_Input = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  business_name?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  company?: InputMaybe<Register_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars["uuid"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job_date?: InputMaybe<Scalars["timestamptz"]["input"]>;
  job_type?: InputMaybe<Scalars["String"]["input"]>;
  job_unit_steps?: InputMaybe<Job_Unit_Steps_Arr_Rel_Insert_Input>;
  job_units?: InputMaybe<Job_Units_Arr_Rel_Insert_Input>;
  location_number?: InputMaybe<Scalars["Int"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  report_submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  technician?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
  verify_checklists?: InputMaybe<Verify_Checklist_Arr_Rel_Insert_Input>;
  zipcode?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Jobs_Max_Fields = {
  __typename?: "jobs_max_fields";
  address?: Maybe<Scalars["String"]["output"]>;
  business_name?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["uuid"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job_date?: Maybe<Scalars["timestamptz"]["output"]>;
  job_type?: Maybe<Scalars["String"]["output"]>;
  location_number?: Maybe<Scalars["Int"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  technician?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user_id?: Maybe<Scalars["String"]["output"]>;
  zipcode?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Jobs_Min_Fields = {
  __typename?: "jobs_min_fields";
  address?: Maybe<Scalars["String"]["output"]>;
  business_name?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["uuid"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job_date?: Maybe<Scalars["timestamptz"]["output"]>;
  job_type?: Maybe<Scalars["String"]["output"]>;
  location_number?: Maybe<Scalars["Int"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  technician?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user_id?: Maybe<Scalars["String"]["output"]>;
  zipcode?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "jobs" */
export type Jobs_Mutation_Response = {
  __typename?: "jobs_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Jobs>;
};

/** input type for inserting object relation for remote table "jobs" */
export type Jobs_Obj_Rel_Insert_Input = {
  data: Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** on_conflict condition type for table "jobs" */
export type Jobs_On_Conflict = {
  constraint: Jobs_Constraint;
  update_columns?: Array<Jobs_Update_Column>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "jobs". */
export type Jobs_Order_By = {
  address?: InputMaybe<Order_By>;
  business_name?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  company?: InputMaybe<Register_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_date?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  job_unit_steps_aggregate?: InputMaybe<Job_Unit_Steps_Aggregate_Order_By>;
  job_units_aggregate?: InputMaybe<Job_Units_Aggregate_Order_By>;
  location_number?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  report_submitted?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  technician?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  verify_checklists_aggregate?: InputMaybe<Verify_Checklist_Aggregate_Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: jobs */
export type Jobs_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "jobs" */
export enum Jobs_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  BusinessName = "business_name",
  /** column name */
  City = "city",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  JobDate = "job_date",
  /** column name */
  JobType = "job_type",
  /** column name */
  LocationNumber = "location_number",
  /** column name */
  Notes = "notes",
  /** column name */
  ReportSubmitted = "report_submitted",
  /** column name */
  State = "state",
  /** column name */
  Status = "status",
  /** column name */
  Technician = "technician",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
  /** column name */
  Zipcode = "zipcode",
}

/** input type for updating data in table "jobs" */
export type Jobs_Set_Input = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  business_name?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["uuid"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job_date?: InputMaybe<Scalars["timestamptz"]["input"]>;
  job_type?: InputMaybe<Scalars["String"]["input"]>;
  location_number?: InputMaybe<Scalars["Int"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  report_submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  technician?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
  zipcode?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type Jobs_Stddev_Fields = {
  __typename?: "jobs_stddev_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Jobs_Stddev_Pop_Fields = {
  __typename?: "jobs_stddev_pop_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Jobs_Stddev_Samp_Fields = {
  __typename?: "jobs_stddev_samp_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "jobs" */
export type Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobs_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  business_name?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["uuid"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job_date?: InputMaybe<Scalars["timestamptz"]["input"]>;
  job_type?: InputMaybe<Scalars["String"]["input"]>;
  location_number?: InputMaybe<Scalars["Int"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  report_submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  technician?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_id?: InputMaybe<Scalars["String"]["input"]>;
  zipcode?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type Jobs_Sum_Fields = {
  __typename?: "jobs_sum_fields";
  location_number?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "jobs" */
export enum Jobs_Update_Column {
  /** column name */
  Address = "address",
  /** column name */
  BusinessName = "business_name",
  /** column name */
  City = "city",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  JobDate = "job_date",
  /** column name */
  JobType = "job_type",
  /** column name */
  LocationNumber = "location_number",
  /** column name */
  Notes = "notes",
  /** column name */
  ReportSubmitted = "report_submitted",
  /** column name */
  State = "state",
  /** column name */
  Status = "status",
  /** column name */
  Technician = "technician",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
  /** column name */
  Zipcode = "zipcode",
}

export type Jobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Jobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Jobs_Var_Pop_Fields = {
  __typename?: "jobs_var_pop_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Jobs_Var_Samp_Fields = {
  __typename?: "jobs_var_samp_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Jobs_Variance_Fields = {
  __typename?: "jobs_variance_fields";
  location_number?: Maybe<Scalars["Float"]["output"]>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]["input"]>;
  _eq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gte?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]["input"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _lte?: InputMaybe<Scalars["jsonb"]["input"]>;
  _neq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** deleteUser */
  deleteUser?: Maybe<DeleteUserOutput>;
  /** delete data from the table: "job_inspection_questionaire" */
  delete_job_inspection_questionaire?: Maybe<Job_Inspection_Questionaire_Mutation_Response>;
  /** delete single row from the table: "job_inspection_questionaire" */
  delete_job_inspection_questionaire_by_pk?: Maybe<Job_Inspection_Questionaire>;
  /** delete data from the table: "job_status" */
  delete_job_status?: Maybe<Job_Status_Mutation_Response>;
  /** delete single row from the table: "job_status" */
  delete_job_status_by_pk?: Maybe<Job_Status>;
  /** delete data from the table: "job_unit_steps" */
  delete_job_unit_steps?: Maybe<Job_Unit_Steps_Mutation_Response>;
  /** delete single row from the table: "job_unit_steps" */
  delete_job_unit_steps_by_pk?: Maybe<Job_Unit_Steps>;
  /** delete data from the table: "job_units" */
  delete_job_units?: Maybe<Job_Units_Mutation_Response>;
  /** delete single row from the table: "job_units" */
  delete_job_units_by_pk?: Maybe<Job_Units>;
  /** delete data from the table: "jobs" */
  delete_jobs?: Maybe<Jobs_Mutation_Response>;
  /** delete single row from the table: "jobs" */
  delete_jobs_by_pk?: Maybe<Jobs>;
  /** delete data from the table: "questionaire_data_type" */
  delete_questionaire_data_type?: Maybe<Questionaire_Data_Type_Mutation_Response>;
  /** delete single row from the table: "questionaire_data_type" */
  delete_questionaire_data_type_by_pk?: Maybe<Questionaire_Data_Type>;
  /** delete data from the table: "questionaire_schemas" */
  delete_questionaire_schemas?: Maybe<Questionaire_Schemas_Mutation_Response>;
  /** delete single row from the table: "questionaire_schemas" */
  delete_questionaire_schemas_by_pk?: Maybe<Questionaire_Schemas>;
  /** delete data from the table: "questionaire_sections" */
  delete_questionaire_sections?: Maybe<Questionaire_Sections_Mutation_Response>;
  /** delete single row from the table: "questionaire_sections" */
  delete_questionaire_sections_by_pk?: Maybe<Questionaire_Sections>;
  /** delete data from the table: "questionaires" */
  delete_questionaires?: Maybe<Questionaires_Mutation_Response>;
  /** delete single row from the table: "questionaires" */
  delete_questionaires_by_pk?: Maybe<Questionaires>;
  /** delete data from the table: "register" */
  delete_register?: Maybe<Register_Mutation_Response>;
  /** delete single row from the table: "register" */
  delete_register_by_pk?: Maybe<Register>;
  /** delete data from the table: "startup_verify_checklist" */
  delete_startup_verify_checklist?: Maybe<Startup_Verify_Checklist_Mutation_Response>;
  /** delete single row from the table: "startup_verify_checklist" */
  delete_startup_verify_checklist_by_pk?: Maybe<Startup_Verify_Checklist>;
  /** delete data from the table: "step_types" */
  delete_step_types?: Maybe<Step_Types_Mutation_Response>;
  /** delete single row from the table: "step_types" */
  delete_step_types_by_pk?: Maybe<Step_Types>;
  /** delete data from the table: "verify_checklist" */
  delete_verify_checklist?: Maybe<Verify_Checklist_Mutation_Response>;
  /** delete single row from the table: "verify_checklist" */
  delete_verify_checklist_by_pk?: Maybe<Verify_Checklist>;
  /** forgotPassword */
  forgotPassword?: Maybe<ForgotPasswordOutput>;
  /** insert data into the table: "job_inspection_questionaire" */
  insert_job_inspection_questionaire?: Maybe<Job_Inspection_Questionaire_Mutation_Response>;
  /** insert a single row into the table: "job_inspection_questionaire" */
  insert_job_inspection_questionaire_one?: Maybe<Job_Inspection_Questionaire>;
  /** insert data into the table: "job_status" */
  insert_job_status?: Maybe<Job_Status_Mutation_Response>;
  /** insert a single row into the table: "job_status" */
  insert_job_status_one?: Maybe<Job_Status>;
  /** insert data into the table: "job_unit_steps" */
  insert_job_unit_steps?: Maybe<Job_Unit_Steps_Mutation_Response>;
  /** insert a single row into the table: "job_unit_steps" */
  insert_job_unit_steps_one?: Maybe<Job_Unit_Steps>;
  /** insert data into the table: "job_units" */
  insert_job_units?: Maybe<Job_Units_Mutation_Response>;
  /** insert a single row into the table: "job_units" */
  insert_job_units_one?: Maybe<Job_Units>;
  /** insert data into the table: "jobs" */
  insert_jobs?: Maybe<Jobs_Mutation_Response>;
  /** insert a single row into the table: "jobs" */
  insert_jobs_one?: Maybe<Jobs>;
  /** insert data into the table: "questionaire_data_type" */
  insert_questionaire_data_type?: Maybe<Questionaire_Data_Type_Mutation_Response>;
  /** insert a single row into the table: "questionaire_data_type" */
  insert_questionaire_data_type_one?: Maybe<Questionaire_Data_Type>;
  /** insert data into the table: "questionaire_schemas" */
  insert_questionaire_schemas?: Maybe<Questionaire_Schemas_Mutation_Response>;
  /** insert a single row into the table: "questionaire_schemas" */
  insert_questionaire_schemas_one?: Maybe<Questionaire_Schemas>;
  /** insert data into the table: "questionaire_sections" */
  insert_questionaire_sections?: Maybe<Questionaire_Sections_Mutation_Response>;
  /** insert a single row into the table: "questionaire_sections" */
  insert_questionaire_sections_one?: Maybe<Questionaire_Sections>;
  /** insert data into the table: "questionaires" */
  insert_questionaires?: Maybe<Questionaires_Mutation_Response>;
  /** insert a single row into the table: "questionaires" */
  insert_questionaires_one?: Maybe<Questionaires>;
  /** insert data into the table: "register" */
  insert_register?: Maybe<Register_Mutation_Response>;
  /** insert a single row into the table: "register" */
  insert_register_one?: Maybe<Register>;
  /** insert data into the table: "startup_verify_checklist" */
  insert_startup_verify_checklist?: Maybe<Startup_Verify_Checklist_Mutation_Response>;
  /** insert a single row into the table: "startup_verify_checklist" */
  insert_startup_verify_checklist_one?: Maybe<Startup_Verify_Checklist>;
  /** insert data into the table: "step_types" */
  insert_step_types?: Maybe<Step_Types_Mutation_Response>;
  /** insert a single row into the table: "step_types" */
  insert_step_types_one?: Maybe<Step_Types>;
  /** insert data into the table: "verify_checklist" */
  insert_verify_checklist?: Maybe<Verify_Checklist_Mutation_Response>;
  /** insert a single row into the table: "verify_checklist" */
  insert_verify_checklist_one?: Maybe<Verify_Checklist>;
  /** login */
  login?: Maybe<LoginOutput>;
  loginOld?: Maybe<LoginOutput>;
  /** registerUser */
  registerUser?: Maybe<RegisterUserOutput>;
  /** update data of the table: "job_inspection_questionaire" */
  update_job_inspection_questionaire?: Maybe<Job_Inspection_Questionaire_Mutation_Response>;
  /** update single row of the table: "job_inspection_questionaire" */
  update_job_inspection_questionaire_by_pk?: Maybe<Job_Inspection_Questionaire>;
  /** update multiples rows of table: "job_inspection_questionaire" */
  update_job_inspection_questionaire_many?: Maybe<
    Array<Maybe<Job_Inspection_Questionaire_Mutation_Response>>
  >;
  /** update data of the table: "job_status" */
  update_job_status?: Maybe<Job_Status_Mutation_Response>;
  /** update single row of the table: "job_status" */
  update_job_status_by_pk?: Maybe<Job_Status>;
  /** update multiples rows of table: "job_status" */
  update_job_status_many?: Maybe<Array<Maybe<Job_Status_Mutation_Response>>>;
  /** update data of the table: "job_unit_steps" */
  update_job_unit_steps?: Maybe<Job_Unit_Steps_Mutation_Response>;
  /** update single row of the table: "job_unit_steps" */
  update_job_unit_steps_by_pk?: Maybe<Job_Unit_Steps>;
  /** update multiples rows of table: "job_unit_steps" */
  update_job_unit_steps_many?: Maybe<
    Array<Maybe<Job_Unit_Steps_Mutation_Response>>
  >;
  /** update data of the table: "job_units" */
  update_job_units?: Maybe<Job_Units_Mutation_Response>;
  /** update single row of the table: "job_units" */
  update_job_units_by_pk?: Maybe<Job_Units>;
  /** update multiples rows of table: "job_units" */
  update_job_units_many?: Maybe<Array<Maybe<Job_Units_Mutation_Response>>>;
  /** update data of the table: "jobs" */
  update_jobs?: Maybe<Jobs_Mutation_Response>;
  /** update single row of the table: "jobs" */
  update_jobs_by_pk?: Maybe<Jobs>;
  /** update multiples rows of table: "jobs" */
  update_jobs_many?: Maybe<Array<Maybe<Jobs_Mutation_Response>>>;
  /** update data of the table: "questionaire_data_type" */
  update_questionaire_data_type?: Maybe<Questionaire_Data_Type_Mutation_Response>;
  /** update single row of the table: "questionaire_data_type" */
  update_questionaire_data_type_by_pk?: Maybe<Questionaire_Data_Type>;
  /** update multiples rows of table: "questionaire_data_type" */
  update_questionaire_data_type_many?: Maybe<
    Array<Maybe<Questionaire_Data_Type_Mutation_Response>>
  >;
  /** update data of the table: "questionaire_schemas" */
  update_questionaire_schemas?: Maybe<Questionaire_Schemas_Mutation_Response>;
  /** update single row of the table: "questionaire_schemas" */
  update_questionaire_schemas_by_pk?: Maybe<Questionaire_Schemas>;
  /** update multiples rows of table: "questionaire_schemas" */
  update_questionaire_schemas_many?: Maybe<
    Array<Maybe<Questionaire_Schemas_Mutation_Response>>
  >;
  /** update data of the table: "questionaire_sections" */
  update_questionaire_sections?: Maybe<Questionaire_Sections_Mutation_Response>;
  /** update single row of the table: "questionaire_sections" */
  update_questionaire_sections_by_pk?: Maybe<Questionaire_Sections>;
  /** update multiples rows of table: "questionaire_sections" */
  update_questionaire_sections_many?: Maybe<
    Array<Maybe<Questionaire_Sections_Mutation_Response>>
  >;
  /** update data of the table: "questionaires" */
  update_questionaires?: Maybe<Questionaires_Mutation_Response>;
  /** update single row of the table: "questionaires" */
  update_questionaires_by_pk?: Maybe<Questionaires>;
  /** update multiples rows of table: "questionaires" */
  update_questionaires_many?: Maybe<
    Array<Maybe<Questionaires_Mutation_Response>>
  >;
  /** update data of the table: "register" */
  update_register?: Maybe<Register_Mutation_Response>;
  /** update single row of the table: "register" */
  update_register_by_pk?: Maybe<Register>;
  /** update multiples rows of table: "register" */
  update_register_many?: Maybe<Array<Maybe<Register_Mutation_Response>>>;
  /** update data of the table: "startup_verify_checklist" */
  update_startup_verify_checklist?: Maybe<Startup_Verify_Checklist_Mutation_Response>;
  /** update single row of the table: "startup_verify_checklist" */
  update_startup_verify_checklist_by_pk?: Maybe<Startup_Verify_Checklist>;
  /** update multiples rows of table: "startup_verify_checklist" */
  update_startup_verify_checklist_many?: Maybe<
    Array<Maybe<Startup_Verify_Checklist_Mutation_Response>>
  >;
  /** update data of the table: "step_types" */
  update_step_types?: Maybe<Step_Types_Mutation_Response>;
  /** update single row of the table: "step_types" */
  update_step_types_by_pk?: Maybe<Step_Types>;
  /** update multiples rows of table: "step_types" */
  update_step_types_many?: Maybe<Array<Maybe<Step_Types_Mutation_Response>>>;
  /** update data of the table: "verify_checklist" */
  update_verify_checklist?: Maybe<Verify_Checklist_Mutation_Response>;
  /** update single row of the table: "verify_checklist" */
  update_verify_checklist_by_pk?: Maybe<Verify_Checklist>;
  /** update multiples rows of table: "verify_checklist" */
  update_verify_checklist_many?: Maybe<
    Array<Maybe<Verify_Checklist_Mutation_Response>>
  >;
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  arg1: DeleteUserInput;
};

/** mutation root */
export type Mutation_RootDelete_Job_Inspection_QuestionaireArgs = {
  where: Job_Inspection_Questionaire_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Inspection_Questionaire_By_PkArgs = {
  job_id: Scalars["uuid"]["input"];
  question: Scalars["uuid"]["input"];
  questionaire: Scalars["uuid"]["input"];
  section: Scalars["uuid"]["input"];
  unit_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Job_StatusArgs = {
  where: Job_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_By_PkArgs = {
  status: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Job_Unit_StepsArgs = {
  where: Job_Unit_Steps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Unit_Steps_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Job_UnitsArgs = {
  where: Job_Units_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Units_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_JobsArgs = {
  where: Jobs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Jobs_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Questionaire_Data_TypeArgs = {
  where: Questionaire_Data_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionaire_Data_Type_By_PkArgs = {
  option: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Questionaire_SchemasArgs = {
  where: Questionaire_Schemas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionaire_Schemas_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Questionaire_SectionsArgs = {
  where: Questionaire_Sections_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionaire_Sections_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_QuestionairesArgs = {
  where: Questionaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionaires_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_RegisterArgs = {
  where: Register_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Register_By_PkArgs = {
  company_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Startup_Verify_ChecklistArgs = {
  where: Startup_Verify_Checklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Startup_Verify_Checklist_By_PkArgs = {
  check_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Step_TypesArgs = {
  where: Step_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Step_Types_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Verify_ChecklistArgs = {
  where: Verify_Checklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Verify_Checklist_By_PkArgs = {
  check_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  arg1: ForgotPasswordInput;
};

/** mutation root */
export type Mutation_RootInsert_Job_Inspection_QuestionaireArgs = {
  objects: Array<Job_Inspection_Questionaire_Insert_Input>;
  on_conflict?: InputMaybe<Job_Inspection_Questionaire_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Inspection_Questionaire_OneArgs = {
  object: Job_Inspection_Questionaire_Insert_Input;
  on_conflict?: InputMaybe<Job_Inspection_Questionaire_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_StatusArgs = {
  objects: Array<Job_Status_Insert_Input>;
  on_conflict?: InputMaybe<Job_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_OneArgs = {
  object: Job_Status_Insert_Input;
  on_conflict?: InputMaybe<Job_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Unit_StepsArgs = {
  objects: Array<Job_Unit_Steps_Insert_Input>;
  on_conflict?: InputMaybe<Job_Unit_Steps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Unit_Steps_OneArgs = {
  object: Job_Unit_Steps_Insert_Input;
  on_conflict?: InputMaybe<Job_Unit_Steps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_UnitsArgs = {
  objects: Array<Job_Units_Insert_Input>;
  on_conflict?: InputMaybe<Job_Units_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Units_OneArgs = {
  object: Job_Units_Insert_Input;
  on_conflict?: InputMaybe<Job_Units_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_JobsArgs = {
  objects: Array<Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Jobs_OneArgs = {
  object: Jobs_Insert_Input;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaire_Data_TypeArgs = {
  objects: Array<Questionaire_Data_Type_Insert_Input>;
  on_conflict?: InputMaybe<Questionaire_Data_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaire_Data_Type_OneArgs = {
  object: Questionaire_Data_Type_Insert_Input;
  on_conflict?: InputMaybe<Questionaire_Data_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaire_SchemasArgs = {
  objects: Array<Questionaire_Schemas_Insert_Input>;
  on_conflict?: InputMaybe<Questionaire_Schemas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaire_Schemas_OneArgs = {
  object: Questionaire_Schemas_Insert_Input;
  on_conflict?: InputMaybe<Questionaire_Schemas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaire_SectionsArgs = {
  objects: Array<Questionaire_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Questionaire_Sections_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaire_Sections_OneArgs = {
  object: Questionaire_Sections_Insert_Input;
  on_conflict?: InputMaybe<Questionaire_Sections_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QuestionairesArgs = {
  objects: Array<Questionaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionaires_OneArgs = {
  object: Questionaires_Insert_Input;
  on_conflict?: InputMaybe<Questionaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RegisterArgs = {
  objects: Array<Register_Insert_Input>;
  on_conflict?: InputMaybe<Register_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Register_OneArgs = {
  object: Register_Insert_Input;
  on_conflict?: InputMaybe<Register_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Startup_Verify_ChecklistArgs = {
  objects: Array<Startup_Verify_Checklist_Insert_Input>;
  on_conflict?: InputMaybe<Startup_Verify_Checklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Startup_Verify_Checklist_OneArgs = {
  object: Startup_Verify_Checklist_Insert_Input;
  on_conflict?: InputMaybe<Startup_Verify_Checklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Step_TypesArgs = {
  objects: Array<Step_Types_Insert_Input>;
  on_conflict?: InputMaybe<Step_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Step_Types_OneArgs = {
  object: Step_Types_Insert_Input;
  on_conflict?: InputMaybe<Step_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Verify_ChecklistArgs = {
  objects: Array<Verify_Checklist_Insert_Input>;
  on_conflict?: InputMaybe<Verify_Checklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Verify_Checklist_OneArgs = {
  object: Verify_Checklist_Insert_Input;
  on_conflict?: InputMaybe<Verify_Checklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootLoginArgs = {
  arg1: LoginInput;
};

/** mutation root */
export type Mutation_RootLoginOldArgs = {
  arg1: LoginInput;
};

/** mutation root */
export type Mutation_RootRegisterUserArgs = {
  arg1: RegisterUserInput;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Inspection_QuestionaireArgs = {
  _set?: InputMaybe<Job_Inspection_Questionaire_Set_Input>;
  where: Job_Inspection_Questionaire_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Inspection_Questionaire_By_PkArgs = {
  _set?: InputMaybe<Job_Inspection_Questionaire_Set_Input>;
  pk_columns: Job_Inspection_Questionaire_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Inspection_Questionaire_ManyArgs = {
  updates: Array<Job_Inspection_Questionaire_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_StatusArgs = {
  _set?: InputMaybe<Job_Status_Set_Input>;
  where: Job_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_By_PkArgs = {
  _set?: InputMaybe<Job_Status_Set_Input>;
  pk_columns: Job_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_ManyArgs = {
  updates: Array<Job_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Unit_StepsArgs = {
  _set?: InputMaybe<Job_Unit_Steps_Set_Input>;
  where: Job_Unit_Steps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Unit_Steps_By_PkArgs = {
  _set?: InputMaybe<Job_Unit_Steps_Set_Input>;
  pk_columns: Job_Unit_Steps_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Unit_Steps_ManyArgs = {
  updates: Array<Job_Unit_Steps_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_UnitsArgs = {
  _set?: InputMaybe<Job_Units_Set_Input>;
  where: Job_Units_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Units_By_PkArgs = {
  _set?: InputMaybe<Job_Units_Set_Input>;
  pk_columns: Job_Units_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Units_ManyArgs = {
  updates: Array<Job_Units_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_JobsArgs = {
  _inc?: InputMaybe<Jobs_Inc_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  where: Jobs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Jobs_By_PkArgs = {
  _inc?: InputMaybe<Jobs_Inc_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  pk_columns: Jobs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Jobs_ManyArgs = {
  updates: Array<Jobs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Data_TypeArgs = {
  _set?: InputMaybe<Questionaire_Data_Type_Set_Input>;
  where: Questionaire_Data_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Data_Type_By_PkArgs = {
  _set?: InputMaybe<Questionaire_Data_Type_Set_Input>;
  pk_columns: Questionaire_Data_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Data_Type_ManyArgs = {
  updates: Array<Questionaire_Data_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_SchemasArgs = {
  _append?: InputMaybe<Questionaire_Schemas_Append_Input>;
  _delete_at_path?: InputMaybe<Questionaire_Schemas_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionaire_Schemas_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionaire_Schemas_Delete_Key_Input>;
  _inc?: InputMaybe<Questionaire_Schemas_Inc_Input>;
  _prepend?: InputMaybe<Questionaire_Schemas_Prepend_Input>;
  _set?: InputMaybe<Questionaire_Schemas_Set_Input>;
  where: Questionaire_Schemas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Schemas_By_PkArgs = {
  _append?: InputMaybe<Questionaire_Schemas_Append_Input>;
  _delete_at_path?: InputMaybe<Questionaire_Schemas_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionaire_Schemas_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionaire_Schemas_Delete_Key_Input>;
  _inc?: InputMaybe<Questionaire_Schemas_Inc_Input>;
  _prepend?: InputMaybe<Questionaire_Schemas_Prepend_Input>;
  _set?: InputMaybe<Questionaire_Schemas_Set_Input>;
  pk_columns: Questionaire_Schemas_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Schemas_ManyArgs = {
  updates: Array<Questionaire_Schemas_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_SectionsArgs = {
  _inc?: InputMaybe<Questionaire_Sections_Inc_Input>;
  _set?: InputMaybe<Questionaire_Sections_Set_Input>;
  where: Questionaire_Sections_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Sections_By_PkArgs = {
  _inc?: InputMaybe<Questionaire_Sections_Inc_Input>;
  _set?: InputMaybe<Questionaire_Sections_Set_Input>;
  pk_columns: Questionaire_Sections_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaire_Sections_ManyArgs = {
  updates: Array<Questionaire_Sections_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QuestionairesArgs = {
  _set?: InputMaybe<Questionaires_Set_Input>;
  where: Questionaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaires_By_PkArgs = {
  _set?: InputMaybe<Questionaires_Set_Input>;
  pk_columns: Questionaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionaires_ManyArgs = {
  updates: Array<Questionaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RegisterArgs = {
  _set?: InputMaybe<Register_Set_Input>;
  where: Register_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Register_By_PkArgs = {
  _set?: InputMaybe<Register_Set_Input>;
  pk_columns: Register_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Register_ManyArgs = {
  updates: Array<Register_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Startup_Verify_ChecklistArgs = {
  _append?: InputMaybe<Startup_Verify_Checklist_Append_Input>;
  _delete_at_path?: InputMaybe<Startup_Verify_Checklist_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Startup_Verify_Checklist_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Startup_Verify_Checklist_Delete_Key_Input>;
  _prepend?: InputMaybe<Startup_Verify_Checklist_Prepend_Input>;
  _set?: InputMaybe<Startup_Verify_Checklist_Set_Input>;
  where: Startup_Verify_Checklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Startup_Verify_Checklist_By_PkArgs = {
  _append?: InputMaybe<Startup_Verify_Checklist_Append_Input>;
  _delete_at_path?: InputMaybe<Startup_Verify_Checklist_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Startup_Verify_Checklist_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Startup_Verify_Checklist_Delete_Key_Input>;
  _prepend?: InputMaybe<Startup_Verify_Checklist_Prepend_Input>;
  _set?: InputMaybe<Startup_Verify_Checklist_Set_Input>;
  pk_columns: Startup_Verify_Checklist_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Startup_Verify_Checklist_ManyArgs = {
  updates: Array<Startup_Verify_Checklist_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Step_TypesArgs = {
  _set?: InputMaybe<Step_Types_Set_Input>;
  where: Step_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Step_Types_By_PkArgs = {
  _set?: InputMaybe<Step_Types_Set_Input>;
  pk_columns: Step_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Step_Types_ManyArgs = {
  updates: Array<Step_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Verify_ChecklistArgs = {
  _set?: InputMaybe<Verify_Checklist_Set_Input>;
  where: Verify_Checklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Verify_Checklist_By_PkArgs = {
  _set?: InputMaybe<Verify_Checklist_Set_Input>;
  pk_columns: Verify_Checklist_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Verify_Checklist_ManyArgs = {
  updates: Array<Verify_Checklist_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "job_inspection_questionaire" */
  job_inspection_questionaire: Array<Job_Inspection_Questionaire>;
  /** fetch aggregated fields from the table: "job_inspection_questionaire" */
  job_inspection_questionaire_aggregate: Job_Inspection_Questionaire_Aggregate;
  /** fetch data from the table: "job_inspection_questionaire" using primary key columns */
  job_inspection_questionaire_by_pk?: Maybe<Job_Inspection_Questionaire>;
  /** fetch data from the table: "job_status" */
  job_status: Array<Job_Status>;
  /** fetch aggregated fields from the table: "job_status" */
  job_status_aggregate: Job_Status_Aggregate;
  /** fetch data from the table: "job_status" using primary key columns */
  job_status_by_pk?: Maybe<Job_Status>;
  /** An array relationship */
  job_unit_steps: Array<Job_Unit_Steps>;
  /** An aggregate relationship */
  job_unit_steps_aggregate: Job_Unit_Steps_Aggregate;
  /** fetch data from the table: "job_unit_steps" using primary key columns */
  job_unit_steps_by_pk?: Maybe<Job_Unit_Steps>;
  /** An array relationship */
  job_units: Array<Job_Units>;
  /** An aggregate relationship */
  job_units_aggregate: Job_Units_Aggregate;
  /** fetch data from the table: "job_units" using primary key columns */
  job_units_by_pk?: Maybe<Job_Units>;
  /** fetch data from the table: "jobs" */
  jobs: Array<Jobs>;
  /** fetch aggregated fields from the table: "jobs" */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table: "questionaire_data_type" */
  questionaire_data_type: Array<Questionaire_Data_Type>;
  /** fetch aggregated fields from the table: "questionaire_data_type" */
  questionaire_data_type_aggregate: Questionaire_Data_Type_Aggregate;
  /** fetch data from the table: "questionaire_data_type" using primary key columns */
  questionaire_data_type_by_pk?: Maybe<Questionaire_Data_Type>;
  /** fetch data from the table: "questionaire_schemas" */
  questionaire_schemas: Array<Questionaire_Schemas>;
  /** fetch aggregated fields from the table: "questionaire_schemas" */
  questionaire_schemas_aggregate: Questionaire_Schemas_Aggregate;
  /** fetch data from the table: "questionaire_schemas" using primary key columns */
  questionaire_schemas_by_pk?: Maybe<Questionaire_Schemas>;
  /** fetch data from the table: "questionaire_sections" */
  questionaire_sections: Array<Questionaire_Sections>;
  /** fetch aggregated fields from the table: "questionaire_sections" */
  questionaire_sections_aggregate: Questionaire_Sections_Aggregate;
  /** fetch data from the table: "questionaire_sections" using primary key columns */
  questionaire_sections_by_pk?: Maybe<Questionaire_Sections>;
  /** fetch data from the table: "questionaires" */
  questionaires: Array<Questionaires>;
  /** fetch aggregated fields from the table: "questionaires" */
  questionaires_aggregate: Questionaires_Aggregate;
  /** fetch data from the table: "questionaires" using primary key columns */
  questionaires_by_pk?: Maybe<Questionaires>;
  /** fetch data from the table: "register" */
  register: Array<Register>;
  /** fetch aggregated fields from the table: "register" */
  register_aggregate: Register_Aggregate;
  /** fetch data from the table: "register" using primary key columns */
  register_by_pk?: Maybe<Register>;
  /** fetch data from the table: "startup_verify_checklist" */
  startup_verify_checklist: Array<Startup_Verify_Checklist>;
  /** fetch aggregated fields from the table: "startup_verify_checklist" */
  startup_verify_checklist_aggregate: Startup_Verify_Checklist_Aggregate;
  /** fetch data from the table: "startup_verify_checklist" using primary key columns */
  startup_verify_checklist_by_pk?: Maybe<Startup_Verify_Checklist>;
  /** fetch data from the table: "step_types" */
  step_types: Array<Step_Types>;
  /** fetch aggregated fields from the table: "step_types" */
  step_types_aggregate: Step_Types_Aggregate;
  /** fetch data from the table: "step_types" using primary key columns */
  step_types_by_pk?: Maybe<Step_Types>;
  /** fetch data from the table: "verify_checklist" */
  verify_checklist: Array<Verify_Checklist>;
  /** fetch aggregated fields from the table: "verify_checklist" */
  verify_checklist_aggregate: Verify_Checklist_Aggregate;
  /** fetch data from the table: "verify_checklist" using primary key columns */
  verify_checklist_by_pk?: Maybe<Verify_Checklist>;
};

export type Query_RootJob_Inspection_QuestionaireArgs = {
  distinct_on?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Inspection_Questionaire_Order_By>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

export type Query_RootJob_Inspection_Questionaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Inspection_Questionaire_Order_By>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

export type Query_RootJob_Inspection_Questionaire_By_PkArgs = {
  job_id: Scalars["uuid"]["input"];
  question: Scalars["uuid"]["input"];
  questionaire: Scalars["uuid"]["input"];
  section: Scalars["uuid"]["input"];
  unit_id: Scalars["uuid"]["input"];
};

export type Query_RootJob_StatusArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Status_Order_By>>;
  where?: InputMaybe<Job_Status_Bool_Exp>;
};

export type Query_RootJob_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Status_Order_By>>;
  where?: InputMaybe<Job_Status_Bool_Exp>;
};

export type Query_RootJob_Status_By_PkArgs = {
  status: Scalars["String"]["input"];
};

export type Query_RootJob_Unit_StepsArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

export type Query_RootJob_Unit_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

export type Query_RootJob_Unit_Steps_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootJob_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Job_Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Units_Order_By>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

export type Query_RootJob_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Units_Order_By>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

export type Query_RootJob_Units_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Query_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Query_RootJobs_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootQuestionaire_Data_TypeArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Data_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Data_Type_Order_By>>;
  where?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
};

export type Query_RootQuestionaire_Data_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Data_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Data_Type_Order_By>>;
  where?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
};

export type Query_RootQuestionaire_Data_Type_By_PkArgs = {
  option: Scalars["String"]["input"];
};

export type Query_RootQuestionaire_SchemasArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Schemas_Order_By>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

export type Query_RootQuestionaire_Schemas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Schemas_Order_By>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

export type Query_RootQuestionaire_Schemas_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootQuestionaire_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Sections_Order_By>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

export type Query_RootQuestionaire_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Sections_Order_By>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

export type Query_RootQuestionaire_Sections_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootQuestionairesArgs = {
  distinct_on?: InputMaybe<Array<Questionaires_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaires_Order_By>>;
  where?: InputMaybe<Questionaires_Bool_Exp>;
};

export type Query_RootQuestionaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaires_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaires_Order_By>>;
  where?: InputMaybe<Questionaires_Bool_Exp>;
};

export type Query_RootQuestionaires_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootRegisterArgs = {
  distinct_on?: InputMaybe<Array<Register_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Register_Order_By>>;
  where?: InputMaybe<Register_Bool_Exp>;
};

export type Query_RootRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Register_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Register_Order_By>>;
  where?: InputMaybe<Register_Bool_Exp>;
};

export type Query_RootRegister_By_PkArgs = {
  company_id: Scalars["uuid"]["input"];
};

export type Query_RootStartup_Verify_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Startup_Verify_Checklist_Order_By>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

export type Query_RootStartup_Verify_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Startup_Verify_Checklist_Order_By>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

export type Query_RootStartup_Verify_Checklist_By_PkArgs = {
  check_id: Scalars["uuid"]["input"];
};

export type Query_RootStep_TypesArgs = {
  distinct_on?: InputMaybe<Array<Step_Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Step_Types_Order_By>>;
  where?: InputMaybe<Step_Types_Bool_Exp>;
};

export type Query_RootStep_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Step_Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Step_Types_Order_By>>;
  where?: InputMaybe<Step_Types_Bool_Exp>;
};

export type Query_RootStep_Types_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootVerify_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

export type Query_RootVerify_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

export type Query_RootVerify_Checklist_By_PkArgs = {
  check_id: Scalars["uuid"]["input"];
};

/** columns and relationships of "questionaire_data_type" */
export type Questionaire_Data_Type = {
  __typename?: "questionaire_data_type";
  option: Scalars["String"]["output"];
};

/** aggregated selection of "questionaire_data_type" */
export type Questionaire_Data_Type_Aggregate = {
  __typename?: "questionaire_data_type_aggregate";
  aggregate?: Maybe<Questionaire_Data_Type_Aggregate_Fields>;
  nodes: Array<Questionaire_Data_Type>;
};

/** aggregate fields of "questionaire_data_type" */
export type Questionaire_Data_Type_Aggregate_Fields = {
  __typename?: "questionaire_data_type_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Questionaire_Data_Type_Max_Fields>;
  min?: Maybe<Questionaire_Data_Type_Min_Fields>;
};

/** aggregate fields of "questionaire_data_type" */
export type Questionaire_Data_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionaire_Data_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "questionaire_data_type". All fields are combined with a logical 'AND'. */
export type Questionaire_Data_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Questionaire_Data_Type_Bool_Exp>>;
  _not?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Questionaire_Data_Type_Bool_Exp>>;
  option?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionaire_data_type" */
export enum Questionaire_Data_Type_Constraint {
  /** unique or primary key constraint on columns "option" */
  QuestionaireDataTypePkey = "questionaire_data_type_pkey",
}

export enum Questionaire_Data_Type_Enum {
  Boolean = "BOOLEAN",
  Multiplechoice = "MULTIPLECHOICE",
  Picture = "PICTURE",
  Selection = "SELECTION",
  Textbox = "TEXTBOX",
}

/** Boolean expression to compare columns of type "questionaire_data_type_enum". All fields are combined with logical 'AND'. */
export type Questionaire_Data_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Questionaire_Data_Type_Enum>;
  _in?: InputMaybe<Array<Questionaire_Data_Type_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<Questionaire_Data_Type_Enum>;
  _nin?: InputMaybe<Array<Questionaire_Data_Type_Enum>>;
};

/** input type for inserting data into table "questionaire_data_type" */
export type Questionaire_Data_Type_Insert_Input = {
  option?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Questionaire_Data_Type_Max_Fields = {
  __typename?: "questionaire_data_type_max_fields";
  option?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Questionaire_Data_Type_Min_Fields = {
  __typename?: "questionaire_data_type_min_fields";
  option?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "questionaire_data_type" */
export type Questionaire_Data_Type_Mutation_Response = {
  __typename?: "questionaire_data_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Questionaire_Data_Type>;
};

/** on_conflict condition type for table "questionaire_data_type" */
export type Questionaire_Data_Type_On_Conflict = {
  constraint: Questionaire_Data_Type_Constraint;
  update_columns?: Array<Questionaire_Data_Type_Update_Column>;
  where?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "questionaire_data_type". */
export type Questionaire_Data_Type_Order_By = {
  option?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionaire_data_type */
export type Questionaire_Data_Type_Pk_Columns_Input = {
  option: Scalars["String"]["input"];
};

/** select columns of table "questionaire_data_type" */
export enum Questionaire_Data_Type_Select_Column {
  /** column name */
  Option = "option",
}

/** input type for updating data in table "questionaire_data_type" */
export type Questionaire_Data_Type_Set_Input = {
  option?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "questionaire_data_type" */
export type Questionaire_Data_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionaire_Data_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionaire_Data_Type_Stream_Cursor_Value_Input = {
  option?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "questionaire_data_type" */
export enum Questionaire_Data_Type_Update_Column {
  /** column name */
  Option = "option",
}

export type Questionaire_Data_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionaire_Data_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionaire_Data_Type_Bool_Exp;
};

/** table_to_store_questionaire_schema */
export type Questionaire_Schemas = {
  __typename?: "questionaire_schemas";
  conditional: Scalars["Boolean"]["output"];
  conditional_on?: Maybe<Scalars["jsonb"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  label: Scalars["String"]["output"];
  optional: Scalars["Boolean"]["output"];
  options?: Maybe<Array<Scalars["String"]["output"]>>;
  order: Scalars["Int"]["output"];
  questionaire: Scalars["uuid"]["output"];
  section: Scalars["uuid"]["output"];
  type: Questionaire_Data_Type_Enum;
  updated_at: Scalars["timestamptz"]["output"];
};

/** table_to_store_questionaire_schema */
export type Questionaire_SchemasConditional_OnArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "questionaire_schemas" */
export type Questionaire_Schemas_Aggregate = {
  __typename?: "questionaire_schemas_aggregate";
  aggregate?: Maybe<Questionaire_Schemas_Aggregate_Fields>;
  nodes: Array<Questionaire_Schemas>;
};

export type Questionaire_Schemas_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Questionaire_Schemas_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Questionaire_Schemas_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Questionaire_Schemas_Aggregate_Bool_Exp_Count>;
};

export type Questionaire_Schemas_Aggregate_Bool_Exp_Bool_And = {
  arguments: Questionaire_Schemas_Select_Column_Questionaire_Schemas_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questionaire_Schemas_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Questionaire_Schemas_Select_Column_Questionaire_Schemas_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questionaire_Schemas_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionaire_schemas" */
export type Questionaire_Schemas_Aggregate_Fields = {
  __typename?: "questionaire_schemas_aggregate_fields";
  avg?: Maybe<Questionaire_Schemas_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Questionaire_Schemas_Max_Fields>;
  min?: Maybe<Questionaire_Schemas_Min_Fields>;
  stddev?: Maybe<Questionaire_Schemas_Stddev_Fields>;
  stddev_pop?: Maybe<Questionaire_Schemas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionaire_Schemas_Stddev_Samp_Fields>;
  sum?: Maybe<Questionaire_Schemas_Sum_Fields>;
  var_pop?: Maybe<Questionaire_Schemas_Var_Pop_Fields>;
  var_samp?: Maybe<Questionaire_Schemas_Var_Samp_Fields>;
  variance?: Maybe<Questionaire_Schemas_Variance_Fields>;
};

/** aggregate fields of "questionaire_schemas" */
export type Questionaire_Schemas_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "questionaire_schemas" */
export type Questionaire_Schemas_Aggregate_Order_By = {
  avg?: InputMaybe<Questionaire_Schemas_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionaire_Schemas_Max_Order_By>;
  min?: InputMaybe<Questionaire_Schemas_Min_Order_By>;
  stddev?: InputMaybe<Questionaire_Schemas_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questionaire_Schemas_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questionaire_Schemas_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questionaire_Schemas_Sum_Order_By>;
  var_pop?: InputMaybe<Questionaire_Schemas_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questionaire_Schemas_Var_Samp_Order_By>;
  variance?: InputMaybe<Questionaire_Schemas_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questionaire_Schemas_Append_Input = {
  conditional_on?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "questionaire_schemas" */
export type Questionaire_Schemas_Arr_Rel_Insert_Input = {
  data: Array<Questionaire_Schemas_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionaire_Schemas_On_Conflict>;
};

/** aggregate avg on columns */
export type Questionaire_Schemas_Avg_Fields = {
  __typename?: "questionaire_schemas_avg_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questionaire_schemas". All fields are combined with a logical 'AND'. */
export type Questionaire_Schemas_Bool_Exp = {
  _and?: InputMaybe<Array<Questionaire_Schemas_Bool_Exp>>;
  _not?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
  _or?: InputMaybe<Array<Questionaire_Schemas_Bool_Exp>>;
  conditional?: InputMaybe<Boolean_Comparison_Exp>;
  conditional_on?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  optional?: InputMaybe<Boolean_Comparison_Exp>;
  options?: InputMaybe<String_Array_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  questionaire?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Questionaire_Data_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionaire_schemas" */
export enum Questionaire_Schemas_Constraint {
  /** unique or primary key constraint on columns "section", "id" */
  QuestionaireSchemasIdSectionKey = "questionaire_schemas_id_section_key",
  /** unique or primary key constraint on columns "id" */
  QuestionaireSchemasPkey = "questionaire_schemas_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questionaire_Schemas_Delete_At_Path_Input = {
  conditional_on?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questionaire_Schemas_Delete_Elem_Input = {
  conditional_on?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questionaire_Schemas_Delete_Key_Input = {
  conditional_on?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "questionaire_schemas" */
export type Questionaire_Schemas_Inc_Input = {
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "questionaire_schemas" */
export type Questionaire_Schemas_Insert_Input = {
  conditional?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditional_on?: InputMaybe<Scalars["jsonb"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  optional?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<Array<Scalars["String"]["input"]>>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["uuid"]["input"]>;
  type?: InputMaybe<Questionaire_Data_Type_Enum>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Questionaire_Schemas_Max_Fields = {
  __typename?: "questionaire_schemas_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<Scalars["String"]["output"]>>;
  order?: Maybe<Scalars["Int"]["output"]>;
  questionaire?: Maybe<Scalars["uuid"]["output"]>;
  section?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionaire_Schemas_Min_Fields = {
  __typename?: "questionaire_schemas_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<Scalars["String"]["output"]>>;
  order?: Maybe<Scalars["Int"]["output"]>;
  questionaire?: Maybe<Scalars["uuid"]["output"]>;
  section?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionaire_schemas" */
export type Questionaire_Schemas_Mutation_Response = {
  __typename?: "questionaire_schemas_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Questionaire_Schemas>;
};

/** input type for inserting object relation for remote table "questionaire_schemas" */
export type Questionaire_Schemas_Obj_Rel_Insert_Input = {
  data: Questionaire_Schemas_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionaire_Schemas_On_Conflict>;
};

/** on_conflict condition type for table "questionaire_schemas" */
export type Questionaire_Schemas_On_Conflict = {
  constraint: Questionaire_Schemas_Constraint;
  update_columns?: Array<Questionaire_Schemas_Update_Column>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

/** Ordering options when selecting data from "questionaire_schemas". */
export type Questionaire_Schemas_Order_By = {
  conditional?: InputMaybe<Order_By>;
  conditional_on?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  optional?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionaire_schemas */
export type Questionaire_Schemas_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questionaire_Schemas_Prepend_Input = {
  conditional_on?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "questionaire_schemas" */
export enum Questionaire_Schemas_Select_Column {
  /** column name */
  Conditional = "conditional",
  /** column name */
  ConditionalOn = "conditional_on",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Optional = "optional",
  /** column name */
  Options = "options",
  /** column name */
  Order = "order",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  Section = "section",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "questionaire_schemas_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questionaire_schemas" */
export enum Questionaire_Schemas_Select_Column_Questionaire_Schemas_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Conditional = "conditional",
  /** column name */
  Optional = "optional",
}

/** select "questionaire_schemas_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questionaire_schemas" */
export enum Questionaire_Schemas_Select_Column_Questionaire_Schemas_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Conditional = "conditional",
  /** column name */
  Optional = "optional",
}

/** input type for updating data in table "questionaire_schemas" */
export type Questionaire_Schemas_Set_Input = {
  conditional?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditional_on?: InputMaybe<Scalars["jsonb"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  optional?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<Array<Scalars["String"]["input"]>>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["uuid"]["input"]>;
  type?: InputMaybe<Questionaire_Data_Type_Enum>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Questionaire_Schemas_Stddev_Fields = {
  __typename?: "questionaire_schemas_stddev_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questionaire_Schemas_Stddev_Pop_Fields = {
  __typename?: "questionaire_schemas_stddev_pop_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questionaire_Schemas_Stddev_Samp_Fields = {
  __typename?: "questionaire_schemas_stddev_samp_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "questionaire_schemas" */
export type Questionaire_Schemas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionaire_Schemas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionaire_Schemas_Stream_Cursor_Value_Input = {
  conditional?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditional_on?: InputMaybe<Scalars["jsonb"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  optional?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<Array<Scalars["String"]["input"]>>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["uuid"]["input"]>;
  type?: InputMaybe<Questionaire_Data_Type_Enum>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Questionaire_Schemas_Sum_Fields = {
  __typename?: "questionaire_schemas_sum_fields";
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "questionaire_schemas" */
export enum Questionaire_Schemas_Update_Column {
  /** column name */
  Conditional = "conditional",
  /** column name */
  ConditionalOn = "conditional_on",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Optional = "optional",
  /** column name */
  Options = "options",
  /** column name */
  Order = "order",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  Section = "section",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Questionaire_Schemas_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questionaire_Schemas_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questionaire_Schemas_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questionaire_Schemas_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questionaire_Schemas_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questionaire_Schemas_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questionaire_Schemas_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionaire_Schemas_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionaire_Schemas_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questionaire_Schemas_Var_Pop_Fields = {
  __typename?: "questionaire_schemas_var_pop_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questionaire_Schemas_Var_Samp_Fields = {
  __typename?: "questionaire_schemas_var_samp_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questionaire_Schemas_Variance_Fields = {
  __typename?: "questionaire_schemas_variance_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "questionaire_schemas" */
export type Questionaire_Schemas_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "questionaire_sections" */
export type Questionaire_Sections = {
  __typename?: "questionaire_sections";
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  order: Scalars["Int"]["output"];
  questionaire: Scalars["uuid"]["output"];
  section: Scalars["String"]["output"];
  /** An array relationship */
  section_fields: Array<Questionaire_Schemas>;
  /** An aggregate relationship */
  section_fields_aggregate: Questionaire_Schemas_Aggregate;
  updated_at: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "questionaire_sections" */
export type Questionaire_SectionsSection_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Schemas_Order_By>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

/** columns and relationships of "questionaire_sections" */
export type Questionaire_SectionsSection_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Schemas_Order_By>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

/** aggregated selection of "questionaire_sections" */
export type Questionaire_Sections_Aggregate = {
  __typename?: "questionaire_sections_aggregate";
  aggregate?: Maybe<Questionaire_Sections_Aggregate_Fields>;
  nodes: Array<Questionaire_Sections>;
};

export type Questionaire_Sections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questionaire_Sections_Aggregate_Bool_Exp_Count>;
};

export type Questionaire_Sections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Questionaire_Sections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionaire_sections" */
export type Questionaire_Sections_Aggregate_Fields = {
  __typename?: "questionaire_sections_aggregate_fields";
  avg?: Maybe<Questionaire_Sections_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Questionaire_Sections_Max_Fields>;
  min?: Maybe<Questionaire_Sections_Min_Fields>;
  stddev?: Maybe<Questionaire_Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Questionaire_Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionaire_Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Questionaire_Sections_Sum_Fields>;
  var_pop?: Maybe<Questionaire_Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Questionaire_Sections_Var_Samp_Fields>;
  variance?: Maybe<Questionaire_Sections_Variance_Fields>;
};

/** aggregate fields of "questionaire_sections" */
export type Questionaire_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "questionaire_sections" */
export type Questionaire_Sections_Aggregate_Order_By = {
  avg?: InputMaybe<Questionaire_Sections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionaire_Sections_Max_Order_By>;
  min?: InputMaybe<Questionaire_Sections_Min_Order_By>;
  stddev?: InputMaybe<Questionaire_Sections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questionaire_Sections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questionaire_Sections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questionaire_Sections_Sum_Order_By>;
  var_pop?: InputMaybe<Questionaire_Sections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questionaire_Sections_Var_Samp_Order_By>;
  variance?: InputMaybe<Questionaire_Sections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "questionaire_sections" */
export type Questionaire_Sections_Arr_Rel_Insert_Input = {
  data: Array<Questionaire_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionaire_Sections_On_Conflict>;
};

/** aggregate avg on columns */
export type Questionaire_Sections_Avg_Fields = {
  __typename?: "questionaire_sections_avg_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questionaire_sections". All fields are combined with a logical 'AND'. */
export type Questionaire_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Questionaire_Sections_Bool_Exp>>;
  _not?: InputMaybe<Questionaire_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Questionaire_Sections_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  questionaire?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<String_Comparison_Exp>;
  section_fields?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
  section_fields_aggregate?: InputMaybe<Questionaire_Schemas_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionaire_sections" */
export enum Questionaire_Sections_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionaireSectionsPkey = "questionaire_sections_pkey",
  /** unique or primary key constraint on columns "section", "questionaire" */
  QuestionaireSectionsQuestionaireSectionKey = "questionaire_sections_questionaire_section_key",
}

/** input type for incrementing numeric columns in table "questionaire_sections" */
export type Questionaire_Sections_Inc_Input = {
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "questionaire_sections" */
export type Questionaire_Sections_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["String"]["input"]>;
  section_fields?: InputMaybe<Questionaire_Schemas_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Questionaire_Sections_Max_Fields = {
  __typename?: "questionaire_sections_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  questionaire?: Maybe<Scalars["uuid"]["output"]>;
  section?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionaire_Sections_Min_Fields = {
  __typename?: "questionaire_sections_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  questionaire?: Maybe<Scalars["uuid"]["output"]>;
  section?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionaire_sections" */
export type Questionaire_Sections_Mutation_Response = {
  __typename?: "questionaire_sections_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Questionaire_Sections>;
};

/** input type for inserting object relation for remote table "questionaire_sections" */
export type Questionaire_Sections_Obj_Rel_Insert_Input = {
  data: Questionaire_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionaire_Sections_On_Conflict>;
};

/** on_conflict condition type for table "questionaire_sections" */
export type Questionaire_Sections_On_Conflict = {
  constraint: Questionaire_Sections_Constraint;
  update_columns?: Array<Questionaire_Sections_Update_Column>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "questionaire_sections". */
export type Questionaire_Sections_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  section_fields_aggregate?: InputMaybe<Questionaire_Schemas_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionaire_sections */
export type Questionaire_Sections_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "questionaire_sections" */
export enum Questionaire_Sections_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Order = "order",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  Section = "section",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "questionaire_sections" */
export type Questionaire_Sections_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Questionaire_Sections_Stddev_Fields = {
  __typename?: "questionaire_sections_stddev_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questionaire_Sections_Stddev_Pop_Fields = {
  __typename?: "questionaire_sections_stddev_pop_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questionaire_Sections_Stddev_Samp_Fields = {
  __typename?: "questionaire_sections_stddev_samp_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "questionaire_sections" */
export type Questionaire_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionaire_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionaire_Sections_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionaire?: InputMaybe<Scalars["uuid"]["input"]>;
  section?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Questionaire_Sections_Sum_Fields = {
  __typename?: "questionaire_sections_sum_fields";
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "questionaire_sections" */
export enum Questionaire_Sections_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Order = "order",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  Section = "section",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Questionaire_Sections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questionaire_Sections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionaire_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionaire_Sections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questionaire_Sections_Var_Pop_Fields = {
  __typename?: "questionaire_sections_var_pop_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questionaire_Sections_Var_Samp_Fields = {
  __typename?: "questionaire_sections_var_samp_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questionaire_Sections_Variance_Fields = {
  __typename?: "questionaire_sections_variance_fields";
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "questionaire_sections" */
export type Questionaire_Sections_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "questionaires" */
export type Questionaires = {
  __typename?: "questionaires";
  created_at: Scalars["timestamptz"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  questionaire: Scalars["String"]["output"];
  /** An array relationship */
  sections: Array<Questionaire_Sections>;
  /** An aggregate relationship */
  sections_aggregate: Questionaire_Sections_Aggregate;
  updated_at: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "questionaires" */
export type QuestionairesSectionsArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Sections_Order_By>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

/** columns and relationships of "questionaires" */
export type QuestionairesSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Sections_Order_By>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

/** aggregated selection of "questionaires" */
export type Questionaires_Aggregate = {
  __typename?: "questionaires_aggregate";
  aggregate?: Maybe<Questionaires_Aggregate_Fields>;
  nodes: Array<Questionaires>;
};

/** aggregate fields of "questionaires" */
export type Questionaires_Aggregate_Fields = {
  __typename?: "questionaires_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Questionaires_Max_Fields>;
  min?: Maybe<Questionaires_Min_Fields>;
};

/** aggregate fields of "questionaires" */
export type Questionaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionaires_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "questionaires". All fields are combined with a logical 'AND'. */
export type Questionaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionaires_Bool_Exp>>;
  _not?: InputMaybe<Questionaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionaires_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionaire?: InputMaybe<String_Comparison_Exp>;
  sections?: InputMaybe<Questionaire_Sections_Bool_Exp>;
  sections_aggregate?: InputMaybe<Questionaire_Sections_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionaires" */
export enum Questionaires_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionairesPkey = "questionaires_pkey",
}

/** input type for inserting data into table "questionaires" */
export type Questionaires_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<Questionaire_Sections_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Questionaires_Max_Fields = {
  __typename?: "questionaires_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  questionaire?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Questionaires_Min_Fields = {
  __typename?: "questionaires_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  questionaire?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "questionaires" */
export type Questionaires_Mutation_Response = {
  __typename?: "questionaires_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Questionaires>;
};

/** on_conflict condition type for table "questionaires" */
export type Questionaires_On_Conflict = {
  constraint: Questionaires_Constraint;
  update_columns?: Array<Questionaires_Update_Column>;
  where?: InputMaybe<Questionaires_Bool_Exp>;
};

/** Ordering options when selecting data from "questionaires". */
export type Questionaires_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionaire?: InputMaybe<Order_By>;
  sections_aggregate?: InputMaybe<Questionaire_Sections_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionaires */
export type Questionaires_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "questionaires" */
export enum Questionaires_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "questionaires" */
export type Questionaires_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "questionaires" */
export type Questionaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionaires_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  questionaire?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "questionaires" */
export enum Questionaires_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Questionaire = "questionaire",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Questionaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionaires_Bool_Exp;
};

/** columns and relationships of "register" */
export type Register = {
  __typename?: "register";
  account_delete_status?: Maybe<Scalars["Boolean"]["output"]>;
  company_address?: Maybe<Scalars["String"]["output"]>;
  company_city?: Maybe<Scalars["String"]["output"]>;
  company_email?: Maybe<Scalars["String"]["output"]>;
  company_id: Scalars["uuid"]["output"];
  company_landline?: Maybe<Scalars["String"]["output"]>;
  company_name?: Maybe<Scalars["String"]["output"]>;
  company_state?: Maybe<Scalars["String"]["output"]>;
  company_zip_code?: Maybe<Scalars["String"]["output"]>;
};

/** aggregated selection of "register" */
export type Register_Aggregate = {
  __typename?: "register_aggregate";
  aggregate?: Maybe<Register_Aggregate_Fields>;
  nodes: Array<Register>;
};

/** aggregate fields of "register" */
export type Register_Aggregate_Fields = {
  __typename?: "register_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Register_Max_Fields>;
  min?: Maybe<Register_Min_Fields>;
};

/** aggregate fields of "register" */
export type Register_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Register_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "register". All fields are combined with a logical 'AND'. */
export type Register_Bool_Exp = {
  _and?: InputMaybe<Array<Register_Bool_Exp>>;
  _not?: InputMaybe<Register_Bool_Exp>;
  _or?: InputMaybe<Array<Register_Bool_Exp>>;
  account_delete_status?: InputMaybe<Boolean_Comparison_Exp>;
  company_address?: InputMaybe<String_Comparison_Exp>;
  company_city?: InputMaybe<String_Comparison_Exp>;
  company_email?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_landline?: InputMaybe<String_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  company_state?: InputMaybe<String_Comparison_Exp>;
  company_zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "register" */
export enum Register_Constraint {
  /** unique or primary key constraint on columns "company_email" */
  RegisterCompanyEmailKey = "register_company_email_key",
  /** unique or primary key constraint on columns "company_id" */
  RegisterPkey = "register_pkey",
}

/** input type for inserting data into table "register" */
export type Register_Insert_Input = {
  account_delete_status?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_address?: InputMaybe<Scalars["String"]["input"]>;
  company_city?: InputMaybe<Scalars["String"]["input"]>;
  company_email?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["uuid"]["input"]>;
  company_landline?: InputMaybe<Scalars["String"]["input"]>;
  company_name?: InputMaybe<Scalars["String"]["input"]>;
  company_state?: InputMaybe<Scalars["String"]["input"]>;
  company_zip_code?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Register_Max_Fields = {
  __typename?: "register_max_fields";
  company_address?: Maybe<Scalars["String"]["output"]>;
  company_city?: Maybe<Scalars["String"]["output"]>;
  company_email?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["uuid"]["output"]>;
  company_landline?: Maybe<Scalars["String"]["output"]>;
  company_name?: Maybe<Scalars["String"]["output"]>;
  company_state?: Maybe<Scalars["String"]["output"]>;
  company_zip_code?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Register_Min_Fields = {
  __typename?: "register_min_fields";
  company_address?: Maybe<Scalars["String"]["output"]>;
  company_city?: Maybe<Scalars["String"]["output"]>;
  company_email?: Maybe<Scalars["String"]["output"]>;
  company_id?: Maybe<Scalars["uuid"]["output"]>;
  company_landline?: Maybe<Scalars["String"]["output"]>;
  company_name?: Maybe<Scalars["String"]["output"]>;
  company_state?: Maybe<Scalars["String"]["output"]>;
  company_zip_code?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "register" */
export type Register_Mutation_Response = {
  __typename?: "register_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Register>;
};

/** input type for inserting object relation for remote table "register" */
export type Register_Obj_Rel_Insert_Input = {
  data: Register_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Register_On_Conflict>;
};

/** on_conflict condition type for table "register" */
export type Register_On_Conflict = {
  constraint: Register_Constraint;
  update_columns?: Array<Register_Update_Column>;
  where?: InputMaybe<Register_Bool_Exp>;
};

/** Ordering options when selecting data from "register". */
export type Register_Order_By = {
  account_delete_status?: InputMaybe<Order_By>;
  company_address?: InputMaybe<Order_By>;
  company_city?: InputMaybe<Order_By>;
  company_email?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_landline?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  company_state?: InputMaybe<Order_By>;
  company_zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: register */
export type Register_Pk_Columns_Input = {
  company_id: Scalars["uuid"]["input"];
};

/** select columns of table "register" */
export enum Register_Select_Column {
  /** column name */
  AccountDeleteStatus = "account_delete_status",
  /** column name */
  CompanyAddress = "company_address",
  /** column name */
  CompanyCity = "company_city",
  /** column name */
  CompanyEmail = "company_email",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CompanyLandline = "company_landline",
  /** column name */
  CompanyName = "company_name",
  /** column name */
  CompanyState = "company_state",
  /** column name */
  CompanyZipCode = "company_zip_code",
}

/** input type for updating data in table "register" */
export type Register_Set_Input = {
  account_delete_status?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_address?: InputMaybe<Scalars["String"]["input"]>;
  company_city?: InputMaybe<Scalars["String"]["input"]>;
  company_email?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["uuid"]["input"]>;
  company_landline?: InputMaybe<Scalars["String"]["input"]>;
  company_name?: InputMaybe<Scalars["String"]["input"]>;
  company_state?: InputMaybe<Scalars["String"]["input"]>;
  company_zip_code?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "register" */
export type Register_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Register_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Register_Stream_Cursor_Value_Input = {
  account_delete_status?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_address?: InputMaybe<Scalars["String"]["input"]>;
  company_city?: InputMaybe<Scalars["String"]["input"]>;
  company_email?: InputMaybe<Scalars["String"]["input"]>;
  company_id?: InputMaybe<Scalars["uuid"]["input"]>;
  company_landline?: InputMaybe<Scalars["String"]["input"]>;
  company_name?: InputMaybe<Scalars["String"]["input"]>;
  company_state?: InputMaybe<Scalars["String"]["input"]>;
  company_zip_code?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "register" */
export enum Register_Update_Column {
  /** column name */
  AccountDeleteStatus = "account_delete_status",
  /** column name */
  CompanyAddress = "company_address",
  /** column name */
  CompanyCity = "company_city",
  /** column name */
  CompanyEmail = "company_email",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CompanyLandline = "company_landline",
  /** column name */
  CompanyName = "company_name",
  /** column name */
  CompanyState = "company_state",
  /** column name */
  CompanyZipCode = "company_zip_code",
}

export type Register_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Register_Set_Input>;
  /** filter the rows which have to be updated */
  where: Register_Bool_Exp;
};

/** Verify checklist fir Startup job type */
export type Startup_Verify_Checklist = {
  __typename?: "startup_verify_checklist";
  checkListType?: Maybe<Scalars["String"]["output"]>;
  check_id: Scalars["uuid"]["output"];
  electrical: Scalars["jsonb"]["output"];
  general: Scalars["jsonb"]["output"];
  job_id: Scalars["uuid"]["output"];
  pressures: Scalars["jsonb"]["output"];
  temperatures: Scalars["jsonb"]["output"];
  unit_id: Scalars["uuid"]["output"];
};

/** Verify checklist fir Startup job type */
export type Startup_Verify_ChecklistElectricalArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** Verify checklist fir Startup job type */
export type Startup_Verify_ChecklistGeneralArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** Verify checklist fir Startup job type */
export type Startup_Verify_ChecklistPressuresArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** Verify checklist fir Startup job type */
export type Startup_Verify_ChecklistTemperaturesArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "startup_verify_checklist" */
export type Startup_Verify_Checklist_Aggregate = {
  __typename?: "startup_verify_checklist_aggregate";
  aggregate?: Maybe<Startup_Verify_Checklist_Aggregate_Fields>;
  nodes: Array<Startup_Verify_Checklist>;
};

export type Startup_Verify_Checklist_Aggregate_Bool_Exp = {
  count?: InputMaybe<Startup_Verify_Checklist_Aggregate_Bool_Exp_Count>;
};

export type Startup_Verify_Checklist_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "startup_verify_checklist" */
export type Startup_Verify_Checklist_Aggregate_Fields = {
  __typename?: "startup_verify_checklist_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Startup_Verify_Checklist_Max_Fields>;
  min?: Maybe<Startup_Verify_Checklist_Min_Fields>;
};

/** aggregate fields of "startup_verify_checklist" */
export type Startup_Verify_Checklist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Startup_Verify_Checklist_Max_Order_By>;
  min?: InputMaybe<Startup_Verify_Checklist_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Startup_Verify_Checklist_Append_Input = {
  electrical?: InputMaybe<Scalars["jsonb"]["input"]>;
  general?: InputMaybe<Scalars["jsonb"]["input"]>;
  pressures?: InputMaybe<Scalars["jsonb"]["input"]>;
  temperatures?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Arr_Rel_Insert_Input = {
  data: Array<Startup_Verify_Checklist_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Startup_Verify_Checklist_On_Conflict>;
};

/** Boolean expression to filter rows from the table "startup_verify_checklist". All fields are combined with a logical 'AND'. */
export type Startup_Verify_Checklist_Bool_Exp = {
  _and?: InputMaybe<Array<Startup_Verify_Checklist_Bool_Exp>>;
  _not?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
  _or?: InputMaybe<Array<Startup_Verify_Checklist_Bool_Exp>>;
  checkListType?: InputMaybe<String_Comparison_Exp>;
  check_id?: InputMaybe<Uuid_Comparison_Exp>;
  electrical?: InputMaybe<Jsonb_Comparison_Exp>;
  general?: InputMaybe<Jsonb_Comparison_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  pressures?: InputMaybe<Jsonb_Comparison_Exp>;
  temperatures?: InputMaybe<Jsonb_Comparison_Exp>;
  unit_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "startup_verify_checklist" */
export enum Startup_Verify_Checklist_Constraint {
  /** unique or primary key constraint on columns "check_id" */
  StartupVerifyChecklistPkey = "startup_verify_checklist_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Startup_Verify_Checklist_Delete_At_Path_Input = {
  electrical?: InputMaybe<Array<Scalars["String"]["input"]>>;
  general?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pressures?: InputMaybe<Array<Scalars["String"]["input"]>>;
  temperatures?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Startup_Verify_Checklist_Delete_Elem_Input = {
  electrical?: InputMaybe<Scalars["Int"]["input"]>;
  general?: InputMaybe<Scalars["Int"]["input"]>;
  pressures?: InputMaybe<Scalars["Int"]["input"]>;
  temperatures?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Startup_Verify_Checklist_Delete_Key_Input = {
  electrical?: InputMaybe<Scalars["String"]["input"]>;
  general?: InputMaybe<Scalars["String"]["input"]>;
  pressures?: InputMaybe<Scalars["String"]["input"]>;
  temperatures?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for inserting data into table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Insert_Input = {
  checkListType?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["uuid"]["input"]>;
  electrical?: InputMaybe<Scalars["jsonb"]["input"]>;
  general?: InputMaybe<Scalars["jsonb"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  pressures?: InputMaybe<Scalars["jsonb"]["input"]>;
  temperatures?: InputMaybe<Scalars["jsonb"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Startup_Verify_Checklist_Max_Fields = {
  __typename?: "startup_verify_checklist_max_fields";
  checkListType?: Maybe<Scalars["String"]["output"]>;
  check_id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Max_Order_By = {
  checkListType?: InputMaybe<Order_By>;
  check_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Startup_Verify_Checklist_Min_Fields = {
  __typename?: "startup_verify_checklist_min_fields";
  checkListType?: Maybe<Scalars["String"]["output"]>;
  check_id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Min_Order_By = {
  checkListType?: InputMaybe<Order_By>;
  check_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Mutation_Response = {
  __typename?: "startup_verify_checklist_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Startup_Verify_Checklist>;
};

/** on_conflict condition type for table "startup_verify_checklist" */
export type Startup_Verify_Checklist_On_Conflict = {
  constraint: Startup_Verify_Checklist_Constraint;
  update_columns?: Array<Startup_Verify_Checklist_Update_Column>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

/** Ordering options when selecting data from "startup_verify_checklist". */
export type Startup_Verify_Checklist_Order_By = {
  checkListType?: InputMaybe<Order_By>;
  check_id?: InputMaybe<Order_By>;
  electrical?: InputMaybe<Order_By>;
  general?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pressures?: InputMaybe<Order_By>;
  temperatures?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: startup_verify_checklist */
export type Startup_Verify_Checklist_Pk_Columns_Input = {
  check_id: Scalars["uuid"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Startup_Verify_Checklist_Prepend_Input = {
  electrical?: InputMaybe<Scalars["jsonb"]["input"]>;
  general?: InputMaybe<Scalars["jsonb"]["input"]>;
  pressures?: InputMaybe<Scalars["jsonb"]["input"]>;
  temperatures?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "startup_verify_checklist" */
export enum Startup_Verify_Checklist_Select_Column {
  /** column name */
  CheckListType = "checkListType",
  /** column name */
  CheckId = "check_id",
  /** column name */
  Electrical = "electrical",
  /** column name */
  General = "general",
  /** column name */
  JobId = "job_id",
  /** column name */
  Pressures = "pressures",
  /** column name */
  Temperatures = "temperatures",
  /** column name */
  UnitId = "unit_id",
}

/** input type for updating data in table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Set_Input = {
  checkListType?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["uuid"]["input"]>;
  electrical?: InputMaybe<Scalars["jsonb"]["input"]>;
  general?: InputMaybe<Scalars["jsonb"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  pressures?: InputMaybe<Scalars["jsonb"]["input"]>;
  temperatures?: InputMaybe<Scalars["jsonb"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "startup_verify_checklist" */
export type Startup_Verify_Checklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Startup_Verify_Checklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Startup_Verify_Checklist_Stream_Cursor_Value_Input = {
  checkListType?: InputMaybe<Scalars["String"]["input"]>;
  check_id?: InputMaybe<Scalars["uuid"]["input"]>;
  electrical?: InputMaybe<Scalars["jsonb"]["input"]>;
  general?: InputMaybe<Scalars["jsonb"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  pressures?: InputMaybe<Scalars["jsonb"]["input"]>;
  temperatures?: InputMaybe<Scalars["jsonb"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "startup_verify_checklist" */
export enum Startup_Verify_Checklist_Update_Column {
  /** column name */
  CheckListType = "checkListType",
  /** column name */
  CheckId = "check_id",
  /** column name */
  Electrical = "electrical",
  /** column name */
  General = "general",
  /** column name */
  JobId = "job_id",
  /** column name */
  Pressures = "pressures",
  /** column name */
  Temperatures = "temperatures",
  /** column name */
  UnitId = "unit_id",
}

export type Startup_Verify_Checklist_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Startup_Verify_Checklist_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Startup_Verify_Checklist_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Startup_Verify_Checklist_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Startup_Verify_Checklist_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Startup_Verify_Checklist_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Startup_Verify_Checklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Startup_Verify_Checklist_Bool_Exp;
};

/** columns and relationships of "step_types" */
export type Step_Types = {
  __typename?: "step_types";
  created_at: Scalars["timestamptz"]["output"];
  definition: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  is_gas_electric_step: Scalars["Boolean"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "step_types" */
export type Step_Types_Aggregate = {
  __typename?: "step_types_aggregate";
  aggregate?: Maybe<Step_Types_Aggregate_Fields>;
  nodes: Array<Step_Types>;
};

/** aggregate fields of "step_types" */
export type Step_Types_Aggregate_Fields = {
  __typename?: "step_types_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Step_Types_Max_Fields>;
  min?: Maybe<Step_Types_Min_Fields>;
};

/** aggregate fields of "step_types" */
export type Step_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Step_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "step_types". All fields are combined with a logical 'AND'. */
export type Step_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Step_Types_Bool_Exp>>;
  _not?: InputMaybe<Step_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Step_Types_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  definition?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_gas_electric_step?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "step_types" */
export enum Step_Types_Constraint {
  /** unique or primary key constraint on columns "definition" */
  StepTypesDefinitionKey = "step_types_definition_key",
  /** unique or primary key constraint on columns "id" */
  StepTypesPkey = "step_types_pkey",
}

/** input type for inserting data into table "step_types" */
export type Step_Types_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  definition?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_gas_electric_step?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Step_Types_Max_Fields = {
  __typename?: "step_types_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  definition?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Step_Types_Min_Fields = {
  __typename?: "step_types_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  definition?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "step_types" */
export type Step_Types_Mutation_Response = {
  __typename?: "step_types_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Step_Types>;
};

/** input type for inserting object relation for remote table "step_types" */
export type Step_Types_Obj_Rel_Insert_Input = {
  data: Step_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Step_Types_On_Conflict>;
};

/** on_conflict condition type for table "step_types" */
export type Step_Types_On_Conflict = {
  constraint: Step_Types_Constraint;
  update_columns?: Array<Step_Types_Update_Column>;
  where?: InputMaybe<Step_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "step_types". */
export type Step_Types_Order_By = {
  created_at?: InputMaybe<Order_By>;
  definition?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_gas_electric_step?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: step_types */
export type Step_Types_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "step_types" */
export enum Step_Types_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Definition = "definition",
  /** column name */
  Id = "id",
  /** column name */
  IsGasElectricStep = "is_gas_electric_step",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "step_types" */
export type Step_Types_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  definition?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_gas_electric_step?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "step_types" */
export type Step_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Step_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Step_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  definition?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_gas_electric_step?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "step_types" */
export enum Step_Types_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Definition = "definition",
  /** column name */
  Id = "id",
  /** column name */
  IsGasElectricStep = "is_gas_electric_step",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Step_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Step_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Step_Types_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "job_inspection_questionaire" */
  job_inspection_questionaire: Array<Job_Inspection_Questionaire>;
  /** fetch aggregated fields from the table: "job_inspection_questionaire" */
  job_inspection_questionaire_aggregate: Job_Inspection_Questionaire_Aggregate;
  /** fetch data from the table: "job_inspection_questionaire" using primary key columns */
  job_inspection_questionaire_by_pk?: Maybe<Job_Inspection_Questionaire>;
  /** fetch data from the table in a streaming manner: "job_inspection_questionaire" */
  job_inspection_questionaire_stream: Array<Job_Inspection_Questionaire>;
  /** fetch data from the table: "job_status" */
  job_status: Array<Job_Status>;
  /** fetch aggregated fields from the table: "job_status" */
  job_status_aggregate: Job_Status_Aggregate;
  /** fetch data from the table: "job_status" using primary key columns */
  job_status_by_pk?: Maybe<Job_Status>;
  /** fetch data from the table in a streaming manner: "job_status" */
  job_status_stream: Array<Job_Status>;
  /** An array relationship */
  job_unit_steps: Array<Job_Unit_Steps>;
  /** An aggregate relationship */
  job_unit_steps_aggregate: Job_Unit_Steps_Aggregate;
  /** fetch data from the table: "job_unit_steps" using primary key columns */
  job_unit_steps_by_pk?: Maybe<Job_Unit_Steps>;
  /** fetch data from the table in a streaming manner: "job_unit_steps" */
  job_unit_steps_stream: Array<Job_Unit_Steps>;
  /** An array relationship */
  job_units: Array<Job_Units>;
  /** An aggregate relationship */
  job_units_aggregate: Job_Units_Aggregate;
  /** fetch data from the table: "job_units" using primary key columns */
  job_units_by_pk?: Maybe<Job_Units>;
  /** fetch data from the table in a streaming manner: "job_units" */
  job_units_stream: Array<Job_Units>;
  /** fetch data from the table: "jobs" */
  jobs: Array<Jobs>;
  /** fetch aggregated fields from the table: "jobs" */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table in a streaming manner: "jobs" */
  jobs_stream: Array<Jobs>;
  /** fetch data from the table: "questionaire_data_type" */
  questionaire_data_type: Array<Questionaire_Data_Type>;
  /** fetch aggregated fields from the table: "questionaire_data_type" */
  questionaire_data_type_aggregate: Questionaire_Data_Type_Aggregate;
  /** fetch data from the table: "questionaire_data_type" using primary key columns */
  questionaire_data_type_by_pk?: Maybe<Questionaire_Data_Type>;
  /** fetch data from the table in a streaming manner: "questionaire_data_type" */
  questionaire_data_type_stream: Array<Questionaire_Data_Type>;
  /** fetch data from the table: "questionaire_schemas" */
  questionaire_schemas: Array<Questionaire_Schemas>;
  /** fetch aggregated fields from the table: "questionaire_schemas" */
  questionaire_schemas_aggregate: Questionaire_Schemas_Aggregate;
  /** fetch data from the table: "questionaire_schemas" using primary key columns */
  questionaire_schemas_by_pk?: Maybe<Questionaire_Schemas>;
  /** fetch data from the table in a streaming manner: "questionaire_schemas" */
  questionaire_schemas_stream: Array<Questionaire_Schemas>;
  /** fetch data from the table: "questionaire_sections" */
  questionaire_sections: Array<Questionaire_Sections>;
  /** fetch aggregated fields from the table: "questionaire_sections" */
  questionaire_sections_aggregate: Questionaire_Sections_Aggregate;
  /** fetch data from the table: "questionaire_sections" using primary key columns */
  questionaire_sections_by_pk?: Maybe<Questionaire_Sections>;
  /** fetch data from the table in a streaming manner: "questionaire_sections" */
  questionaire_sections_stream: Array<Questionaire_Sections>;
  /** fetch data from the table: "questionaires" */
  questionaires: Array<Questionaires>;
  /** fetch aggregated fields from the table: "questionaires" */
  questionaires_aggregate: Questionaires_Aggregate;
  /** fetch data from the table: "questionaires" using primary key columns */
  questionaires_by_pk?: Maybe<Questionaires>;
  /** fetch data from the table in a streaming manner: "questionaires" */
  questionaires_stream: Array<Questionaires>;
  /** fetch data from the table: "register" */
  register: Array<Register>;
  /** fetch aggregated fields from the table: "register" */
  register_aggregate: Register_Aggregate;
  /** fetch data from the table: "register" using primary key columns */
  register_by_pk?: Maybe<Register>;
  /** fetch data from the table in a streaming manner: "register" */
  register_stream: Array<Register>;
  /** fetch data from the table: "startup_verify_checklist" */
  startup_verify_checklist: Array<Startup_Verify_Checklist>;
  /** fetch aggregated fields from the table: "startup_verify_checklist" */
  startup_verify_checklist_aggregate: Startup_Verify_Checklist_Aggregate;
  /** fetch data from the table: "startup_verify_checklist" using primary key columns */
  startup_verify_checklist_by_pk?: Maybe<Startup_Verify_Checklist>;
  /** fetch data from the table in a streaming manner: "startup_verify_checklist" */
  startup_verify_checklist_stream: Array<Startup_Verify_Checklist>;
  /** fetch data from the table: "step_types" */
  step_types: Array<Step_Types>;
  /** fetch aggregated fields from the table: "step_types" */
  step_types_aggregate: Step_Types_Aggregate;
  /** fetch data from the table: "step_types" using primary key columns */
  step_types_by_pk?: Maybe<Step_Types>;
  /** fetch data from the table in a streaming manner: "step_types" */
  step_types_stream: Array<Step_Types>;
  /** fetch data from the table: "verify_checklist" */
  verify_checklist: Array<Verify_Checklist>;
  /** fetch aggregated fields from the table: "verify_checklist" */
  verify_checklist_aggregate: Verify_Checklist_Aggregate;
  /** fetch data from the table: "verify_checklist" using primary key columns */
  verify_checklist_by_pk?: Maybe<Verify_Checklist>;
  /** fetch data from the table in a streaming manner: "verify_checklist" */
  verify_checklist_stream: Array<Verify_Checklist>;
};

export type Subscription_RootJob_Inspection_QuestionaireArgs = {
  distinct_on?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Inspection_Questionaire_Order_By>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

export type Subscription_RootJob_Inspection_Questionaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Inspection_Questionaire_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Inspection_Questionaire_Order_By>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

export type Subscription_RootJob_Inspection_Questionaire_By_PkArgs = {
  job_id: Scalars["uuid"]["input"];
  question: Scalars["uuid"]["input"];
  questionaire: Scalars["uuid"]["input"];
  section: Scalars["uuid"]["input"];
  unit_id: Scalars["uuid"]["input"];
};

export type Subscription_RootJob_Inspection_Questionaire_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Job_Inspection_Questionaire_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Inspection_Questionaire_Bool_Exp>;
};

export type Subscription_RootJob_StatusArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Status_Order_By>>;
  where?: InputMaybe<Job_Status_Bool_Exp>;
};

export type Subscription_RootJob_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Status_Order_By>>;
  where?: InputMaybe<Job_Status_Bool_Exp>;
};

export type Subscription_RootJob_Status_By_PkArgs = {
  status: Scalars["String"]["input"];
};

export type Subscription_RootJob_Status_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Job_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Status_Bool_Exp>;
};

export type Subscription_RootJob_Unit_StepsArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

export type Subscription_RootJob_Unit_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Unit_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Unit_Steps_Order_By>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

export type Subscription_RootJob_Unit_Steps_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootJob_Unit_Steps_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Job_Unit_Steps_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Unit_Steps_Bool_Exp>;
};

export type Subscription_RootJob_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Job_Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Units_Order_By>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

export type Subscription_RootJob_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Job_Units_Order_By>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

export type Subscription_RootJob_Units_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootJob_Units_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Job_Units_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Units_Bool_Exp>;
};

export type Subscription_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Subscription_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Subscription_RootJobs_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootJobs_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Data_TypeArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Data_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Data_Type_Order_By>>;
  where?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Data_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Data_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Data_Type_Order_By>>;
  where?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Data_Type_By_PkArgs = {
  option: Scalars["String"]["input"];
};

export type Subscription_RootQuestionaire_Data_Type_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Questionaire_Data_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionaire_Data_Type_Bool_Exp>;
};

export type Subscription_RootQuestionaire_SchemasArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Schemas_Order_By>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Schemas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Schemas_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Schemas_Order_By>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Schemas_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootQuestionaire_Schemas_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Questionaire_Schemas_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionaire_Schemas_Bool_Exp>;
};

export type Subscription_RootQuestionaire_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Sections_Order_By>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaire_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaire_Sections_Order_By>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

export type Subscription_RootQuestionaire_Sections_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootQuestionaire_Sections_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Questionaire_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionaire_Sections_Bool_Exp>;
};

export type Subscription_RootQuestionairesArgs = {
  distinct_on?: InputMaybe<Array<Questionaires_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaires_Order_By>>;
  where?: InputMaybe<Questionaires_Bool_Exp>;
};

export type Subscription_RootQuestionaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionaires_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Questionaires_Order_By>>;
  where?: InputMaybe<Questionaires_Bool_Exp>;
};

export type Subscription_RootQuestionaires_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootQuestionaires_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Questionaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionaires_Bool_Exp>;
};

export type Subscription_RootRegisterArgs = {
  distinct_on?: InputMaybe<Array<Register_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Register_Order_By>>;
  where?: InputMaybe<Register_Bool_Exp>;
};

export type Subscription_RootRegister_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Register_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Register_Order_By>>;
  where?: InputMaybe<Register_Bool_Exp>;
};

export type Subscription_RootRegister_By_PkArgs = {
  company_id: Scalars["uuid"]["input"];
};

export type Subscription_RootRegister_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Register_Stream_Cursor_Input>>;
  where?: InputMaybe<Register_Bool_Exp>;
};

export type Subscription_RootStartup_Verify_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Startup_Verify_Checklist_Order_By>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

export type Subscription_RootStartup_Verify_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Startup_Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Startup_Verify_Checklist_Order_By>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

export type Subscription_RootStartup_Verify_Checklist_By_PkArgs = {
  check_id: Scalars["uuid"]["input"];
};

export type Subscription_RootStartup_Verify_Checklist_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Startup_Verify_Checklist_Stream_Cursor_Input>>;
  where?: InputMaybe<Startup_Verify_Checklist_Bool_Exp>;
};

export type Subscription_RootStep_TypesArgs = {
  distinct_on?: InputMaybe<Array<Step_Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Step_Types_Order_By>>;
  where?: InputMaybe<Step_Types_Bool_Exp>;
};

export type Subscription_RootStep_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Step_Types_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Step_Types_Order_By>>;
  where?: InputMaybe<Step_Types_Bool_Exp>;
};

export type Subscription_RootStep_Types_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootStep_Types_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Step_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Step_Types_Bool_Exp>;
};

export type Subscription_RootVerify_ChecklistArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

export type Subscription_RootVerify_Checklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Verify_Checklist_Order_By>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

export type Subscription_RootVerify_Checklist_By_PkArgs = {
  check_id: Scalars["uuid"]["input"];
};

export type Subscription_RootVerify_Checklist_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Verify_Checklist_Stream_Cursor_Input>>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]["input"]>;
  _gt?: InputMaybe<Scalars["uuid"]["input"]>;
  _gte?: InputMaybe<Scalars["uuid"]["input"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["uuid"]["input"]>;
  _lte?: InputMaybe<Scalars["uuid"]["input"]>;
  _neq?: InputMaybe<Scalars["uuid"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
};

/** columns and relationships of "verify_checklist" */
export type Verify_Checklist = {
  __typename?: "verify_checklist";
  air_filters_clean?: Maybe<Scalars["Boolean"]["output"]>;
  check_id: Scalars["uuid"]["output"];
  compressors_rotating?: Maybe<Scalars["Boolean"]["output"]>;
  configuration_values?: Maybe<Scalars["Boolean"]["output"]>;
  has_no_leaks?: Maybe<Scalars["Boolean"]["output"]>;
  heaters_energized?: Maybe<Scalars["Boolean"]["output"]>;
  /** An object relationship */
  job: Jobs;
  job_id: Scalars["uuid"]["output"];
  /** An object relationship */
  job_unit: Job_Units;
  position_outdoor_fan?: Maybe<Scalars["Boolean"]["output"]>;
  space_sensors_installed?: Maybe<Scalars["Boolean"]["output"]>;
  specific_range?: Maybe<Scalars["Boolean"]["output"]>;
  unit_id: Scalars["uuid"]["output"];
};

/** aggregated selection of "verify_checklist" */
export type Verify_Checklist_Aggregate = {
  __typename?: "verify_checklist_aggregate";
  aggregate?: Maybe<Verify_Checklist_Aggregate_Fields>;
  nodes: Array<Verify_Checklist>;
};

export type Verify_Checklist_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Verify_Checklist_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Verify_Checklist_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Verify_Checklist_Aggregate_Bool_Exp_Count>;
};

export type Verify_Checklist_Aggregate_Bool_Exp_Bool_And = {
  arguments: Verify_Checklist_Select_Column_Verify_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Verify_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Verify_Checklist_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Verify_Checklist_Select_Column_Verify_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Verify_Checklist_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Verify_Checklist_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Verify_Checklist_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "verify_checklist" */
export type Verify_Checklist_Aggregate_Fields = {
  __typename?: "verify_checklist_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Verify_Checklist_Max_Fields>;
  min?: Maybe<Verify_Checklist_Min_Fields>;
};

/** aggregate fields of "verify_checklist" */
export type Verify_Checklist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verify_Checklist_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "verify_checklist" */
export type Verify_Checklist_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Verify_Checklist_Max_Order_By>;
  min?: InputMaybe<Verify_Checklist_Min_Order_By>;
};

/** input type for inserting array relation for remote table "verify_checklist" */
export type Verify_Checklist_Arr_Rel_Insert_Input = {
  data: Array<Verify_Checklist_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Verify_Checklist_On_Conflict>;
};

/** Boolean expression to filter rows from the table "verify_checklist". All fields are combined with a logical 'AND'. */
export type Verify_Checklist_Bool_Exp = {
  _and?: InputMaybe<Array<Verify_Checklist_Bool_Exp>>;
  _not?: InputMaybe<Verify_Checklist_Bool_Exp>;
  _or?: InputMaybe<Array<Verify_Checklist_Bool_Exp>>;
  air_filters_clean?: InputMaybe<Boolean_Comparison_Exp>;
  check_id?: InputMaybe<Uuid_Comparison_Exp>;
  compressors_rotating?: InputMaybe<Boolean_Comparison_Exp>;
  configuration_values?: InputMaybe<Boolean_Comparison_Exp>;
  has_no_leaks?: InputMaybe<Boolean_Comparison_Exp>;
  heaters_energized?: InputMaybe<Boolean_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  job_unit?: InputMaybe<Job_Units_Bool_Exp>;
  position_outdoor_fan?: InputMaybe<Boolean_Comparison_Exp>;
  space_sensors_installed?: InputMaybe<Boolean_Comparison_Exp>;
  specific_range?: InputMaybe<Boolean_Comparison_Exp>;
  unit_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "verify_checklist" */
export enum Verify_Checklist_Constraint {
  /** unique or primary key constraint on columns "unit_id", "job_id" */
  VerifyChecklistJobIdUnitIdKey = "verify_checklist_job_id_unit_id_key",
  /** unique or primary key constraint on columns "check_id" */
  VerifyChecklistPkey = "verify_checklist_pkey",
}

/** input type for inserting data into table "verify_checklist" */
export type Verify_Checklist_Insert_Input = {
  air_filters_clean?: InputMaybe<Scalars["Boolean"]["input"]>;
  check_id?: InputMaybe<Scalars["uuid"]["input"]>;
  compressors_rotating?: InputMaybe<Scalars["Boolean"]["input"]>;
  configuration_values?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_no_leaks?: InputMaybe<Scalars["Boolean"]["input"]>;
  heaters_energized?: InputMaybe<Scalars["Boolean"]["input"]>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  job_unit?: InputMaybe<Job_Units_Obj_Rel_Insert_Input>;
  position_outdoor_fan?: InputMaybe<Scalars["Boolean"]["input"]>;
  space_sensors_installed?: InputMaybe<Scalars["Boolean"]["input"]>;
  specific_range?: InputMaybe<Scalars["Boolean"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Verify_Checklist_Max_Fields = {
  __typename?: "verify_checklist_max_fields";
  check_id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "verify_checklist" */
export type Verify_Checklist_Max_Order_By = {
  check_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Verify_Checklist_Min_Fields = {
  __typename?: "verify_checklist_min_fields";
  check_id?: Maybe<Scalars["uuid"]["output"]>;
  job_id?: Maybe<Scalars["uuid"]["output"]>;
  unit_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "verify_checklist" */
export type Verify_Checklist_Min_Order_By = {
  check_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "verify_checklist" */
export type Verify_Checklist_Mutation_Response = {
  __typename?: "verify_checklist_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Verify_Checklist>;
};

/** on_conflict condition type for table "verify_checklist" */
export type Verify_Checklist_On_Conflict = {
  constraint: Verify_Checklist_Constraint;
  update_columns?: Array<Verify_Checklist_Update_Column>;
  where?: InputMaybe<Verify_Checklist_Bool_Exp>;
};

/** Ordering options when selecting data from "verify_checklist". */
export type Verify_Checklist_Order_By = {
  air_filters_clean?: InputMaybe<Order_By>;
  check_id?: InputMaybe<Order_By>;
  compressors_rotating?: InputMaybe<Order_By>;
  configuration_values?: InputMaybe<Order_By>;
  has_no_leaks?: InputMaybe<Order_By>;
  heaters_energized?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_unit?: InputMaybe<Job_Units_Order_By>;
  position_outdoor_fan?: InputMaybe<Order_By>;
  space_sensors_installed?: InputMaybe<Order_By>;
  specific_range?: InputMaybe<Order_By>;
  unit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verify_checklist */
export type Verify_Checklist_Pk_Columns_Input = {
  check_id: Scalars["uuid"]["input"];
};

/** select columns of table "verify_checklist" */
export enum Verify_Checklist_Select_Column {
  /** column name */
  AirFiltersClean = "air_filters_clean",
  /** column name */
  CheckId = "check_id",
  /** column name */
  CompressorsRotating = "compressors_rotating",
  /** column name */
  ConfigurationValues = "configuration_values",
  /** column name */
  HasNoLeaks = "has_no_leaks",
  /** column name */
  HeatersEnergized = "heaters_energized",
  /** column name */
  JobId = "job_id",
  /** column name */
  PositionOutdoorFan = "position_outdoor_fan",
  /** column name */
  SpaceSensorsInstalled = "space_sensors_installed",
  /** column name */
  SpecificRange = "specific_range",
  /** column name */
  UnitId = "unit_id",
}

/** select "verify_checklist_aggregate_bool_exp_bool_and_arguments_columns" columns of table "verify_checklist" */
export enum Verify_Checklist_Select_Column_Verify_Checklist_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AirFiltersClean = "air_filters_clean",
  /** column name */
  CompressorsRotating = "compressors_rotating",
  /** column name */
  ConfigurationValues = "configuration_values",
  /** column name */
  HasNoLeaks = "has_no_leaks",
  /** column name */
  HeatersEnergized = "heaters_energized",
  /** column name */
  PositionOutdoorFan = "position_outdoor_fan",
  /** column name */
  SpaceSensorsInstalled = "space_sensors_installed",
  /** column name */
  SpecificRange = "specific_range",
}

/** select "verify_checklist_aggregate_bool_exp_bool_or_arguments_columns" columns of table "verify_checklist" */
export enum Verify_Checklist_Select_Column_Verify_Checklist_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AirFiltersClean = "air_filters_clean",
  /** column name */
  CompressorsRotating = "compressors_rotating",
  /** column name */
  ConfigurationValues = "configuration_values",
  /** column name */
  HasNoLeaks = "has_no_leaks",
  /** column name */
  HeatersEnergized = "heaters_energized",
  /** column name */
  PositionOutdoorFan = "position_outdoor_fan",
  /** column name */
  SpaceSensorsInstalled = "space_sensors_installed",
  /** column name */
  SpecificRange = "specific_range",
}

/** input type for updating data in table "verify_checklist" */
export type Verify_Checklist_Set_Input = {
  air_filters_clean?: InputMaybe<Scalars["Boolean"]["input"]>;
  check_id?: InputMaybe<Scalars["uuid"]["input"]>;
  compressors_rotating?: InputMaybe<Scalars["Boolean"]["input"]>;
  configuration_values?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_no_leaks?: InputMaybe<Scalars["Boolean"]["input"]>;
  heaters_energized?: InputMaybe<Scalars["Boolean"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  position_outdoor_fan?: InputMaybe<Scalars["Boolean"]["input"]>;
  space_sensors_installed?: InputMaybe<Scalars["Boolean"]["input"]>;
  specific_range?: InputMaybe<Scalars["Boolean"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "verify_checklist" */
export type Verify_Checklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verify_Checklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verify_Checklist_Stream_Cursor_Value_Input = {
  air_filters_clean?: InputMaybe<Scalars["Boolean"]["input"]>;
  check_id?: InputMaybe<Scalars["uuid"]["input"]>;
  compressors_rotating?: InputMaybe<Scalars["Boolean"]["input"]>;
  configuration_values?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_no_leaks?: InputMaybe<Scalars["Boolean"]["input"]>;
  heaters_energized?: InputMaybe<Scalars["Boolean"]["input"]>;
  job_id?: InputMaybe<Scalars["uuid"]["input"]>;
  position_outdoor_fan?: InputMaybe<Scalars["Boolean"]["input"]>;
  space_sensors_installed?: InputMaybe<Scalars["Boolean"]["input"]>;
  specific_range?: InputMaybe<Scalars["Boolean"]["input"]>;
  unit_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "verify_checklist" */
export enum Verify_Checklist_Update_Column {
  /** column name */
  AirFiltersClean = "air_filters_clean",
  /** column name */
  CheckId = "check_id",
  /** column name */
  CompressorsRotating = "compressors_rotating",
  /** column name */
  ConfigurationValues = "configuration_values",
  /** column name */
  HasNoLeaks = "has_no_leaks",
  /** column name */
  HeatersEnergized = "heaters_energized",
  /** column name */
  JobId = "job_id",
  /** column name */
  PositionOutdoorFan = "position_outdoor_fan",
  /** column name */
  SpaceSensorsInstalled = "space_sensors_installed",
  /** column name */
  SpecificRange = "specific_range",
  /** column name */
  UnitId = "unit_id",
}

export type Verify_Checklist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verify_Checklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verify_Checklist_Bool_Exp;
};

export type GetJobListQueryVariables = Exact<{ [key: string]: never }>;

export type GetJobListQuery = {
  __typename?: "query_root";
  jobs: Array<{
    __typename?: "jobs";
    address?: string | null;
    business_name?: string | null;
    city?: string | null;
    company_id?: any | null;
    created_at?: any | null;
    id: any;
    job_date?: any | null;
    job_type: string;
    location_number?: number | null;
    notes?: string | null;
    report_submitted?: boolean | null;
    state?: string | null;
    technician?: string | null;
    status?: string | null;
    updated_at?: any | null;
    user_id: string;
    zipcode?: string | null;
    company?: {
      __typename?: "register";
      company_zip_code?: string | null;
      company_state?: string | null;
      company_name?: string | null;
      company_landline?: string | null;
      company_id: any;
      company_email?: string | null;
      company_city?: string | null;
      account_delete_status?: boolean | null;
      company_address?: string | null;
    } | null;
    job_units: Array<{
      __typename?: "job_units";
      checklist_submitted?: boolean | null;
      created_at?: any | null;
      id: any;
      is_gas_or_electric_unit?: boolean | null;
      job_id?: any | null;
      model_no?: string | null;
      photo_barcode_url?: string | null;
      serial_no?: string | null;
      unit_name?: string | null;
      updated_at?: any | null;
      inspection: Array<{
        __typename?: "job_inspection_questionaire";
        array_value?: Array<string> | null;
        created_at: any;
        job_id: any;
        question: any;
        questionaire: any;
        section: any;
        unit_id: any;
        value?: string | null;
        updated_at: any;
        questionaire_section: {
          __typename?: "questionaire_sections";
          order: number;
          questionaire: any;
          section: string;
          section_fields: Array<{
            __typename?: "questionaire_schemas";
            type: Questionaire_Data_Type_Enum;
            section: any;
            questionaire: any;
            order: number;
            updated_at: any;
            options?: Array<string> | null;
            label: string;
            id: any;
            optional: boolean;
          }>;
        };
        schema?: {
          __typename?: "questionaire_schemas";
          type: Questionaire_Data_Type_Enum;
          section: any;
          order: number;
          questionaire: any;
          options?: Array<string> | null;
          optional: boolean;
          label: string;
          id: any;
        } | null;
      }>;
      steps: Array<{
        __typename?: "job_unit_steps";
        created_at?: any | null;
        id: any;
        job_id?: any | null;
        photo?: string | null;
        step_id?: any | null;
        unit_id?: any | null;
        updated_at?: any | null;
        steps_type?: {
          __typename?: "step_types";
          id: any;
          is_gas_electric_step: boolean;
          updated_at: any;
          definition: string;
        } | null;
      }>;
    }>;
  }>;
};

export type UpdateJobsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars["uuid"]["input"]> | Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateJobsMutation = {
  __typename?: "mutation_root";
  update_jobs_many?: Array<{
    __typename?: "jobs_mutation_response";
    affected_rows: number;
    returning: Array<{
      __typename?: "jobs";
      id: any;
      address?: string | null;
      business_name?: string | null;
      city?: string | null;
      company_id?: any | null;
      created_at?: any | null;
      job_date?: any | null;
      notes?: string | null;
      report_submitted?: boolean | null;
      state?: string | null;
      status?: string | null;
      updated_at?: any | null;
      zipcode?: string | null;
      technician?: string | null;
      company?: {
        __typename?: "register";
        company_address?: string | null;
        company_city?: string | null;
        company_email?: string | null;
        company_id: any;
        company_landline?: string | null;
        company_name?: string | null;
        company_state?: string | null;
        company_zip_code?: string | null;
      } | null;
      job_units: Array<{
        __typename?: "job_units";
        id: any;
        checklist_submitted?: boolean | null;
        created_at?: any | null;
        is_gas_or_electric_unit?: boolean | null;
        job_id?: any | null;
        model_no?: string | null;
        photo_barcode_url?: string | null;
        serial_no?: string | null;
        unit_name?: string | null;
        steps: Array<{
          __typename?: "job_unit_steps";
          photo?: string | null;
          step_id?: any | null;
          id: any;
          steps_type?: {
            __typename?: "step_types";
            definition: string;
            is_gas_electric_step: boolean;
          } | null;
        }>;
      }>;
    }>;
  } | null> | null;
};

export const GetJobListDocument = `
    query getJobList {
  jobs(where: {_or: [{status: {_eq: "COMPLETED"}}, {status: {_eq: "APPROVED"}}]}) {
    address
    business_name
    city
    company_id
    created_at
    id
    job_date
    job_type
    location_number
    notes
    report_submitted
    state
    technician
    status
    updated_at
    user_id
    company {
      company_zip_code
      company_state
      company_name
      company_landline
      company_id
      company_email
      company_city
      account_delete_status
      company_address
    }
    job_units {
      checklist_submitted
      created_at
      id
      is_gas_or_electric_unit
      job_id
      model_no
      photo_barcode_url
      serial_no
      unit_name
      inspection {
        array_value
        created_at
        job_id
        question
        questionaire
        section
        unit_id
        value
        updated_at
        questionaire_section {
          order
          questionaire
          section
          section_fields {
            type
            section
            questionaire
            order
            updated_at
            options
            label
            id
            optional
          }
        }
        schema {
          type
          section
          order
          questionaire
          options
          optional
          label
          id
        }
      }
      steps {
        created_at
        id
        job_id
        photo
        step_id
        unit_id
        updated_at
        steps_type {
          id
          is_gas_electric_step
          updated_at
          definition
        }
      }
      updated_at
    }
    zipcode
  }
}
    `;
export const UpdateJobsDocument = `
    mutation updateJobs($ids: [uuid!], $status: String) {
  update_jobs_many(updates: {where: {id: {_in: $ids}}, _set: {status: $status}}) {
    affected_rows
    returning {
      id
      address
      business_name
      city
      company_id
      created_at
      job_date
      notes
      report_submitted
      state
      status
      updated_at
      zipcode
      technician
      company {
        company_address
        company_city
        company_email
        company_id
        company_landline
        company_name
        company_state
        company_zip_code
      }
      job_units {
        id
        checklist_submitted
        created_at
        is_gas_or_electric_unit
        job_id
        model_no
        photo_barcode_url
        serial_no
        unit_name
        steps {
          photo
          step_id
          steps_type {
            definition
            is_gas_electric_step
          }
          id
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getJobList: build.query<GetJobListQuery, GetJobListQueryVariables | void>({
      query: (variables) => ({ document: GetJobListDocument, variables }),
    }),
    updateJobs: build.mutation<
      UpdateJobsMutation,
      UpdateJobsMutationVariables | void
    >({
      query: (variables) => ({ document: UpdateJobsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetJobListQuery,
  useLazyGetJobListQuery,
  useUpdateJobsMutation,
} = injectedRtkApi;
