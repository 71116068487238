import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";

import { environment } from "../environments/environment";

import { createApi } from "@reduxjs/toolkit/query/react";

export const client = new GraphQLClient(<string>environment.hasuraApiUrl, {
  headers: {
    "Content-Type": "application/json",
  },
  method: "POST",
});

const graphqlBaseQuery = graphqlRequestBaseQuery({
  client,
  prepareHeaders: (headers) => {
    return {
      ...headers,
      "x-hasura-admin-secret": environment.hasuraApiSecret,
    };
  },
});

export const api = createApi({
  baseQuery: graphqlBaseQuery,
  endpoints: () => ({}),
  reducerPath: "startupApi",
});
