import React, { useEffect, useState } from "react";

import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { Img } from "react-image";
import ReactLoading from "react-loading";

import { environment } from "../../environments/environment";

import styles from "../app.module.scss";

interface Props {
  fileName: string;
  onClick: (imageUrl: string, title: string) => void;
  title: string;
}

const ImageWithUrl: React.FC<Props> = ({ fileName, title, onClick }) => {
  const { authState } = useOktaAuth();
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = `${environment.uploadServiceUrl}/files/download`;

        const accessToken: string | undefined =
          authState?.accessToken?.accessToken;
        const response = await axios.get<{ url: string }>(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "GET",
          params: { appId: "startup", file: fileName },
        });
        setImageUrl(response.data.url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [authState?.accessToken?.accessToken, fileName]);

  return (
    <div className={styles.imageData} onClick={() => onClick(imageUrl, title)}>
      <Img
        src={imageUrl}
        className={styles.imageView}
        loader={
          <div className={styles.loading}>
            <ReactLoading
              type={"spin"}
              color={"#002EFF"}
              height={50}
              width={50}
            />
          </div>
        }
      />
      {<div className={styles.imageTitle}>{title}</div>}
    </div>
  );
};

export default ImageWithUrl;
