import React from "react";

import "../../styles.scss";

interface RoundedButtonViewProps {
  isApproveModalButton?: boolean;
  isDisabled?: boolean;
  isDownload?: boolean;
  label: string;
  onClick: () => void;
}

const RoundedButton: React.FC<RoundedButtonViewProps> = ({
  isDisabled,
  label,
  onClick,
  isDownload,
  isApproveModalButton,
}) => {
  return (
    <button
      className={
        isApproveModalButton
          ? `approval-modal-button ${label === "Cancel" ? "cancel" : ""}`
          : `buttonView ${isDownload && (isDisabled ? "downloaddisabled" : "downloadenabled")} ${isDisabled ? "disabled" : "enabled"}`
      }
      onClick={onClick}
      disabled={isDisabled}
    >
      {label}
    </button>
  );
};

export default RoundedButton;
