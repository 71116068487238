import React, { useEffect, useState } from "react";

import { Modal } from "antd";
import ReactLoading from "react-loading";

import closeIcon from "../../assets/ic_closeIcon.png";

import "../../styles.scss";

interface Props {
  component?: React.FC;
  customClassName?: string;
  footer?: React.ReactNode;
  isImageViewer?: boolean;
  loading: boolean;
  onModalClose?: () => void;
  title?: string;
  visible: boolean;
}

const ModalView: React.FC<Props> = ({
  visible,
  title,
  footer,
  loading,
  component: Component,
  onModalClose,
  customClassName,
  isImageViewer = false,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  return (
    <Modal
      open={isVisible}
      title={title}
      footer={footer}
      width={loading ? "100vw" : "60vw"}
      className={["fullscreen-modal", customClassName].join(" ")}
      onCancel={() => {
        setIsVisible(false);
        onModalClose && onModalClose();
      }}
      centered={true}
      style={
        customClassName
          ? {
              position: "fixed",
              transform: `translate(-24%, 40%)`,
            }
          : {}
      }
      closeIcon={
        !isImageViewer ? null : (
          <img className="info-icon" src={closeIcon} alt="" />
        )
      }
    >
      {!isImageViewer ? (
        <div
          className="custom-close-button"
          onClick={() => {
            setIsVisible(false);
            onModalClose && onModalClose();
          }}
        >
          <img className="info-icon" src={closeIcon} alt="" />
        </div>
      ) : (
        <></>
      )}
      {loading ? (
        <div className="loading-view">
          <ReactLoading
            type={"spin"}
            color={"#002EFF"}
            height={50}
            width={50}
          />
        </div>
      ) : Component ? (
        <Component />
      ) : null}
    </Modal>
  );
};

export default ModalView;
