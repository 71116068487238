import { UnitDetail } from "./utils/attatchDataToDoc";

export const StoreDetails = [
  {
    address: "Address",
    startDate: "Start Date",
    storeCity: "StoreCity",
    storeName: "Store Name",
    storeState: "StoreState",
    zipcode: "Zipcode",
  },
];

export const ContractorDetails = [
  {
    totalUnits: "Total Units",
  },
];

export const UnitDetails: UnitDetail[] = [
  {
    images: [],
    modelNo: "Model No",
    serialNo: "Serial No",
  },
];

export const staticDocData = {
  header: "UNIT START-UP INSPECTION",
  headerDescription: "(Remove and Store in Job File)",
  modelNo: "MODEL NO",
  preStartUpTitle: "PRE-START-UP",
  serialNo: "SERIAL NO",
  startUpTitle: "START-UP",
};

export const preStartUpData = [
  {
    checked: true,
    text: "Check position of outdoor fan and setscrew is tight",
  },
  {
    checked: true,
    text: "Configuration values for electronic controls",
  },
  {
    checked: true,
    text: "Crankcase heaters have been energized for 24 hrs.",
  },
  {
    checked: true,
    text: "Gas piping has no leaks",
  },
  {
    checked: true,
    text: "Gas pressure is within spec. range",
  },
  {
    checked: true,
    text: "Indoor air filters are clean and in place",
  },
  {
    checked: true,
    text: "Scroll compressors are rotating in the proper direction",
  },
  {
    checked: true,
    text: "T-stats/space sensors are installed",
  },
];

export const startupData = [
  {
    heading: "ELECTRICAL",
    rowsData: [
      {
        fills: "AFTER",
        heading: "SUPPLY VOLTAGE",
        items: ["L1-L2", "L2-L3", "L3-L1"],
      },
      {
        fills: "AFTER",
        heading: "COMPRESSOR AMPS 1",
        items: ["L1", "L2", "L3"],
      },
      {
        fills: "AFTER",
        heading: "COMPRESSOR AMPS 2",
        items: ["L1", "L2", "L3"],
      },
      {
        fills: "AFTER",
        heading: "SUPPLY FAN AMPS",
        items: ["L1", "L2", "L3"],
      },
    ],
  },
  {
    heading: "TEMPERATURES",
    rowsData: [
      {
        fills: "BEFORE",
        heading: "OUTDOOR-AIR TEMPERATURE",
        items: ["°F DB (DRY BULB)"],
      },
      {
        fills: "BEFORE",
        heading: "RETURN-AIR TEMPERATURE",
        items: ["°FDB", "°F WB (WET BULB)"],
      },
      {
        fills: "BEFORE",
        heading: "COOLING SUPPLY AIR TEMPERATURE",
        items: ["°F"],
      },
      {
        fills: "BEFORE",
        heading: "GAS HEAT SUPPLY AIR",
        items: ["°F"],
      },
    ],
  },
  {
    heading: "PRESSURES",
    rowsData: [
      {
        fills: "BEFORE",
        heading: "GAS INLET PRESSURE",
        items: ["IN. WG"],
      },
      {
        fills: "BEFORE",
        heading: "GAS MANIFOLD PRESSURE STAGE 1",
        items: ["IN. WG"],
      },
      {
        fills: "BEFORE",
        heading: "GAS MANIFOLD PRESSURE STAGE 2",
        items: ["IN. WG"],
      },
      {
        fills: "BEFORE",
        heading: "REFRIGERANT SUCTION CIRCUIT A",
        items: ["PSIG"],
      },
      {
        fills: "BEFORE",
        heading: "REFRIGERANT SUCTION CIRCUIT B",
        items: ["PSIG"],
      },
      {
        fills: "BEFORE",
        heading: "REFRIGERANT DISCHARGE CIRCUIT A",
        items: ["PSIG"],
      },
      {
        fills: "BEFORE",
        heading: "REFRIGERANT DISCHARGE CIRCUIT B",
        items: ["PSIG"],
      },
    ],
  },
];

export const startupCheckData = [
  "VERIFY REFRIGERANT CHARGE USING CHARGING CHARTS",
  "ECONOMIZER MINIMUM VENT AND CHANGEOVER SETTINGS TO JOB REQUIREMENTS (IF EQUIPPED)",
  "VERIFY SMOKE DETECTOR UNIT SHUTDOWN BY UTILIZING MAGNET TEST",
];
