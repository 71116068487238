import { createSlice } from "@reduxjs/toolkit";

export interface DownloadZipState {
  downloadZip: boolean;
}

const initialState: DownloadZipState = {
  downloadZip: false,
};

export const downloadZipSlice = createSlice({
  initialState: initialState,
  name: "downloadZip",
  reducers: {
    setDownloadZip(state, action) {
      state.downloadZip = action.payload.downloadZip;
    },
  },
});

export const { setDownloadZip } = downloadZipSlice.actions;

export default downloadZipSlice.reducer;
