import React from "react";

import { JobDetailsBasicUnit, SectionData } from "../jobslist";

import { convertArrayToString } from "./accordianDetails";
import ImageWithUrl from "./imageData";

import "../../styles.scss";

type ImageGridProps = {
  // Array of image URLs
  onClick: (imageUrl: string, title: string) => void;
  sectionData: JobDetailsBasicUnit[];
};

const ImageGrid: React.FC<ImageGridProps> = ({ sectionData, onClick }) => {
  return (
    <div className="image-container">
      {sectionData.map((item, imageIndex) =>
        Array.isArray((item.value as SectionData).value) ? (
          ((item.value as SectionData).value as string[])?.map(
            (imageUrl, index) => (
              <ImageWithUrl
                fileName={convertArrayToString(imageUrl)}
                title={(item.value as SectionData).sectionField}
                key={index}
                onClick={onClick}
              />
            ),
          )
        ) : (
          <ImageWithUrl
            fileName={convertArrayToString((item.value as SectionData).value)}
            title={(item.value as SectionData).sectionField}
            key={imageIndex}
            onClick={onClick}
          />
        ),
      )}
    </div>
  );
};

export default ImageGrid;
