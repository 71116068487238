import { AuthState } from "@okta/okta-auth-js";
import axios from "axios";

import { environment } from "../../../environments/environment";
import { ImageData, JobDetailsBasicUnit } from "../../jobslist";
import { ContractorDetails, StoreDetails, UnitDetails } from "../data";

interface Props {
  authState: AuthState | null;
  contractorDetails: JobDetailsBasicUnit[];
  jobDetailsUnitsCollection: JobDetailsBasicUnit[][];
  storeDetails: JobDetailsBasicUnit[];
}

interface StoreDetail {
  address: string;
  startDate: string;
  storeCity: string;
  storeName: string;
  storeState: string;
  zipcode: string;
}

interface ContractorDetail {
  totalUnits: string;
}

export interface UnitDetail {
  images: (string | Buffer | Uint8Array | ArrayBuffer)[];
  modelNo: string;
  serialNo: string;
}

const addStoreDetailsToDocument = (documentData: JobDetailsBasicUnit[]) => {
  const storeDetailValues: StoreDetail = {
    address: "",
    startDate: "",
    storeCity: "",
    storeName: "",
    storeState: "",
    zipcode: "",
  };

  documentData.forEach(({ key, value }) => {
    storeDetailValues.address =
      key === "Address"
        ? value != null
          ? value.toString()
          : storeDetailValues.address
        : storeDetailValues.address;
    storeDetailValues.startDate =
      key === "Start Date"
        ? value != null
          ? value.toString()
          : storeDetailValues.startDate
        : storeDetailValues.startDate;
    storeDetailValues.storeName =
      key === "Store Name"
        ? value != null
          ? value.toString()
          : storeDetailValues.storeName
        : storeDetailValues.storeName;
    storeDetailValues.storeCity =
      key === "City"
        ? value != null
          ? value.toString()
          : storeDetailValues.storeCity
        : storeDetailValues.storeCity;
    storeDetailValues.storeState =
      key === "State"
        ? value != null
          ? value.toString()
          : storeDetailValues.storeState
        : storeDetailValues.storeState;
    storeDetailValues.zipcode =
      key === "Zipcode"
        ? value != null
          ? value.toString()
          : storeDetailValues.zipcode || ""
        : storeDetailValues.zipcode || "";
  });

  StoreDetails.push(storeDetailValues);
};

const addContractorDetailsToDocument = (
  documentData: JobDetailsBasicUnit[],
) => {
  const contractorDetailValues: ContractorDetail = {
    totalUnits: "",
  };

  documentData.forEach(({ key, value }) => {
    contractorDetailValues.totalUnits =
      key === "Total units"
        ? value != null
          ? value.toString()
          : contractorDetailValues.totalUnits
        : contractorDetailValues.totalUnits;
  });

  ContractorDetails.push(contractorDetailValues);
};

const addUnitDetailsToDocument = async (
  documentData: JobDetailsBasicUnit[][],
  authState: AuthState,
) => {
  for (const unitItem of documentData) {
    const unitDetailValues: UnitDetail = {
      images: [],
      modelNo: "",
      serialNo: "",
    };
    const otherData = unitItem.filter((item) => item.key !== "Image Details");
    otherData.forEach((item) => {
      if (item.key === "Model No#") {
        unitDetailValues.modelNo = item.value as string;
      } else if (item.key === "Serial Number") {
        unitDetailValues.serialNo = item.value as string;
      }
    });

    const imageData = unitItem.filter((item) => {
      return (
        item.key === "Image Details" &&
        ((item.value as ImageData).stepType === "Unit name plate" ||
          (item.value as ImageData).stepType === "Exhaust Hood")
      );
    });
    try {
      const promises = imageData.map(async (item) => {
        const image = (item.value as unknown as ImageData).photo;
        const blob = await getImageBlob(authState, image);
        return blob;
      });
      const blobs = await Promise.all(promises);
      unitDetailValues.images.push(...blobs);
    } catch (error) {
      console.error("Error fetching image blobs:", error);
    }
    UnitDetails.push(unitDetailValues);
  }
};

const getImageBlob = async (
  authState: AuthState,
  fileName: string,
): Promise<ArrayBuffer> => {
  try {
    const url = `${environment.uploadServiceUrl}/files/download`;

    const accessToken: string | undefined = authState?.accessToken?.accessToken;
    const response = await axios.get<{ url: string }>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
      params: { appId: "startup", file: fileName },
    });

    const blob = await fetch(response.data.url).then((r) => r.arrayBuffer());
    return blob;
  } catch (error) {
    console.error("Error fetching image:", error);
    return new ArrayBuffer(0);
  }
};

const AttachDataToDoc = async ({
  storeDetails,
  contractorDetails,
  jobDetailsUnitsCollection,
  authState,
}: Props) => {
  // clearing the previous data
  StoreDetails.length = 1;
  ContractorDetails.length = 1;
  UnitDetails.length = 1;

  // appending the new data
  addStoreDetailsToDocument(storeDetails);
  addContractorDetailsToDocument(contractorDetails);
  await addUnitDetailsToDocument(jobDetailsUnitsCollection, authState!);
};

export default AttachDataToDoc;
