import {
  AlignmentType,
  BorderStyle,
  Document,
  ImageRun,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextDirection,
  TextRun,
  WidthType,
} from "docx";

import { JobDetailsBasicUnit } from "../jobslist";

import {
  preStartUpData,
  startupCheckData,
  startupData,
  staticDocData,
  StoreDetails,
  UnitDetails,
} from "./data";

export class DocumentCreator {
  public async create(unitDetails: JobDetailsBasicUnit[][]): Promise<Document> {
    const { storeName, storeCity, address, storeState, zipcode } =
      StoreDetails.length > 1
        ? StoreDetails[1]
        : {
            address: "",
            storeCity: "",
            storeName: "",
            storeState: "",
            zipcode: "",
          };

    const document = new Document({
      sections: unitDetails.map((unitDetail, unitIndex) => ({
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                size: 48,
                text: staticDocData.header,
              }),
              new TextRun({
                break: 1,
                size: 28,
                text: storeName,
              }),
              new TextRun({
                break: 1,
                size: 28,
                text: address,
              }),
              new TextRun({
                break: 1,
                size: 28,
                text: storeCity + ", ",
              }),
              new TextRun({
                size: 28,
                text: storeState + " " + zipcode,
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                size: 24,
                text: `Unit #${unitIndex + 1}`,
              }),
              ...this.modelSerial(staticDocData.modelNo, unitDetail[0]?.value),
              ...this.modelSerial(staticDocData.serialNo, unitDetail[1]?.value),
            ],
            spacing: {
              before: 100,
            },
            text: "",
          }),
          this.createPreStartUp(),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                size: 28,
                text: "Installation Photos",
              }),
            ],
            spacing: {
              after: 200,
              before: 200,
            },
          }),
          this.createStartupPhotosTable(
            3,
            unitDetail[0]?.value as string,
            unitDetail[1]?.value as string,
          ),
        ],
      })),
    });

    return document;
  }

  private modelSerial(
    label: string,
    value: JobDetailsBasicUnit["value"],
  ): TextRun[] {
    const children: TextRun[] = [];

    if (value != null) {
      children.push(
        new TextRun({
          bold: true,
          size: 24,
          text: `${getSpaces(20) + label + ":- "}`,
        }),
        new TextRun({
          bold: true,
          italics: true,
          size: 24,
          text: value as string,
        }),
      );
    }

    return children;
  }

  private createPreStartUp(): Paragraph {
    const children: TextRun[] = [];
    children.push(
      new TextRun({
        bold: true,
        break: 1,
        text: `${toRoman(1) + getSpaces(4) + staticDocData.preStartUpTitle}`,
      }),
      new TextRun({
        break: 11 / 10,
      }),
    );
    preStartUpData.forEach(
      (step: { checked: boolean; text: string }, index: number) => {
        children.push(
          new TextRun({
            text: `${getSpaces(6)}`,
          }),
          new TextRun({
            text: `${step.checked ? "☑" : "☐"}${getSpaces(2)}`,
          }),
          new TextRun({
            text: `${step.text}`,
          }),
        );
        if (index !== preStartUpData.length - 1) {
          children.push(
            new TextRun({
              break: 1,
            }),
          );
        }
      },
    );

    return new Paragraph({
      children: children,
    });
  }

  private createStartupPhotosTable = (
    columns: number,
    modelNo: string,
    serialNo: string,
  ): Table => {
    const rows: TableRow[] = [];
    let rowCells: TableCell[] = [];

    UnitDetails.forEach((unitDetail) => {
      for (let i = 0; i < unitDetail.images.length; i++) {
        if (
          unitDetail.modelNo === modelNo &&
          unitDetail.serialNo === serialNo
        ) {
          const imageRun = unitDetail.images[i];
          const cell = new TableCell({
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: imageRun,
                    transformation: { height: 185, width: 243 },
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    size: 28,
                    text: i === 0 ? "Unit Name Plate" : "Exhaust Hood",
                  }),
                ],
                spacing: {
                  after: 200,
                  before: 200,
                },
              }),
            ],
            margins: { bottom: 100, left: 100, right: 100, top: 100 },
          });

          rowCells.push(cell);

          if ((i + 1) % columns === 0 || i === unitDetail.images.length - 1) {
            rows.push(
              new TableRow({
                children: rowCells,
              }),
            );
            rowCells = [];
          }
        }
      }
    });
    return new Table({
      rows,
    });
  };

  private createStartupData(): Paragraph[] {
    const paragraphs: Paragraph[] = [];
    paragraphs.push(
      new Paragraph({
        children: [
          new TextRun({
            bold: true,
            break: 1,
            text: `${toRoman(2) + getSpaces(4) + staticDocData.startUpTitle}`,
          }),
        ],
        spacing: {
          after: 100,
        },
      }),
    );

    startupData.forEach((section) => {
      paragraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              bold: true,
              text: `${getSpaces(6) + section.heading}`,
            }),
          ],
          spacing: {
            after: 100,
          },
        }),
      );
      const headingParagraph = new Paragraph({
        children: [new TextRun({})],
      });
      paragraphs.push(headingParagraph);
      section.rowsData.forEach(
        (row: { fills: string; heading: string; items: string[] }) => {
          const table = createStartupTable(row);
          headingParagraph.addChildElement(table);
        },
      );
    });

    return paragraphs;
  }
  private createStartupCheckData(): Paragraph {
    const paragraph = new Paragraph({});

    startupCheckData.forEach((check: string) => {
      paragraph.addChildElement(
        new TextRun({
          break: 1,
          text: `${getCheckboxes(false) + getSpaces(2)}`,
        }),
      );
      paragraph.addChildElement(
        new TextRun({
          size: 16,
          text: `${check}`,
        }),
      );
    });

    return paragraph;
  }
}

function toRoman(num: number): string {
  const romanMap: [number, string][] = [
    [5000, "V̅"],
    [1000, "M"],
    [900, "CM"],
    [500, "D"],
    [400, "CD"],
    [100, "C"],
    [90, "XC"],
    [50, "L"],
    [40, "XL"],
    [10, "X"],
    [9, "IX"],
    [5, "V"],
    [4, "IV"],
    [1, "I"],
  ];
  let result = "";
  for (const [value, numeral] of romanMap) {
    while (num >= value) {
      result += numeral;
      num -= value;
    }
  }
  return result;
}

function getSpaces(count: number): string {
  return " ".repeat(count);
}

function getUnderScores(count: number): string {
  return "_".repeat(count);
}
function getCheckboxes(check: boolean): string {
  return check ? "☑" : "☐";
}

function createStartupTable(row: {
  fills: string;
  heading: string;
  items: string[];
}) {
  const cells = row.items.map((item) => {
    let text = item;
    row.fills === "AFTER"
      ? (text += ":- " + getUnderScores(10))
      : (text = `${getUnderScores(10) + text}`);

    return new TableCell({
      borders: {
        bottom: { size: 0, style: BorderStyle.NONE },
        left: { size: 0, style: BorderStyle.NONE },
        right: { size: 0, style: BorderStyle.NONE },
        top: { size: 0, style: BorderStyle.NONE },
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [new TextRun({ text })],
        }),
      ],
      margins: {
        left: 60,
      },

      textDirection: TextDirection.LEFT_TO_RIGHT_TOP_TO_BOTTOM,
    });
  });

  // Add the heading cell
  const headingCell = new TableCell({
    borders: {
      bottom: { size: 0, style: BorderStyle.NONE },
      left: { size: 0, style: BorderStyle.NONE },
      right: { size: 0, style: BorderStyle.NONE },
      top: { size: 0, style: BorderStyle.NONE },
    },
    children: [
      new Paragraph({
        children: [new TextRun({ text: `${row.heading}:` })],
      }),
    ],
    margins: {
      left: 100,
      top: 80,
    },
  });

  const rowCells = [headingCell, ...cells];
  const tableRow = new TableRow({ children: rowCells });

  return new Table({
    layout: "fixed",
    rows: [tableRow],
    width: {
      size: 10000,
      type: WidthType.DXA,
    },
  });
}
