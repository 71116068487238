import React, {
  LegacyRef,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import { SortOrder } from "antd/es/table/interface";
import * as moment from "moment";

import { GetJobListQuery, useGetJobListQuery } from "../api";
import logo from "../assets/ic_logo.png";
import sortIcon from "../assets/ic_sortIcon.png";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { jobListSlice } from "../redux/slices/joblist";

import Accordion from "./components/accordion";
import ModalView from "./components/modalView";
import useOuterClickExcludingButton from "./components/useOuterClick";
import { oktaAuth } from "./app";

import styles from "./app.module.scss";

export enum Status {
  Approved = "Approved",
  NotApproved = "Not Approved",
  Rejected = "Rejected",
}

export enum StepType {
  DRAIN_PIPE = "Condensate drainpipe",
  ELECTRICAL_CONNECTIONS = "Electrical Connection",
  EXHAUST_HOOD = "Exhaust Hood",
  FLUE_EXHAUST = "Flue Exhaust",
  GAS_PIPING = "Gas Pipe",
}

export enum JobType {
  Inspection = "Inspection",
  PreStartup = "Prestartup",
  Startup = "Startup",
}

export const SERIAL_NO = "Serial Number";
export const UNIT_ID = "Unit ID";

interface StatusInfo {
  backgroundColor: string;
  status: string;
  textColor: string;
}

export interface DataSource {
  contractor: string;
  contractorDetails: JobDetailsBasicUnit[];
  date: string;
  id: string;
  jobDetailsUnitsCollection: JobDetailsBasicUnit[][];
  jobType: string;
  key: number;
  status: string;
  store: string;
  storeDetails: JobDetailsBasicUnit[];
  technician: string;
}

export interface Column {
  dataIndex: keyof DataSource;
  key: keyof DataSource;
  render?: (text: string, record: DataSource) => React.ReactNode;
  sortDirections?: SortOrder[];
  sortIcon: (props: { sortOrder: SortOrder }) => React.ReactNode;
  sorter?: (a: DataSource, b: DataSource) => number;
  title: ReactElement;
}

export interface ImageData {
  photo: string;
  stepType: string;
}

export interface SectionData {
  order: number;
  section: string;
  sectionField: string;
  sectionOrder: number;
  value: string | string[] | null | undefined;
  valueType: string;
}

export interface JobDetailsBasicUnit {
  key: string;
  value: string | Date | null | undefined | ImageData | SectionData;
}

const getStoreDetailsArray = (
  job: GetJobListQuery["jobs"][0],
): JobDetailsBasicUnit[] => [
  { key: "Store Name", value: job.business_name },
  { key: "Address", value: job.address },
  { key: "City", value: job.city },
  { key: "State", value: job.state },
  { key: "Zipcode", value: job.zipcode },
  { key: "Contact No", value: job.company?.company_landline },
];

const getContractorDetailsArray = (
  job: GetJobListQuery["jobs"][0],
  formattedDate: string,
): JobDetailsBasicUnit[] => [
  { key: "Start Date", value: formattedDate },
  { key: "Total units", value: job.job_units.length.toString() },
];

const transformJob = (job: GetJobListQuery["jobs"][0], index: number) => {
  const date = new Date(job.job_date);
  const formattedDate = moment(date).format("DD-MMM-YYYY");
  const jobData: DataSource = {
    contractor: job.address || "",
    contractorDetails: [],
    date: formattedDate.toString(),
    id: job.id,
    jobDetailsUnitsCollection: [],
    jobType: job.job_type.charAt(0) + job.job_type.toLowerCase().slice(1),
    key: index,
    status:
      job.status === "COMPLETED" || job.status === "ACTIVE"
        ? "Not Approved"
        : job.status === "APPROVED"
          ? "Approved"
          : "Rejected",
    store: job.business_name || "",
    storeDetails: [],
    technician: job.technician || "",
  };

  jobData.storeDetails = getStoreDetailsArray(job);
  jobData.contractorDetails = getContractorDetailsArray(job, formattedDate);

  const jobDetailsUnitsCollection: JobDetailsBasicUnit[][] = [];
  const jobUnitsSorted = [...(job.job_units || [])].sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  jobUnitsSorted.forEach((jobUnit) => {
    const jobDetailsUnits: JobDetailsBasicUnit[] = [];
    if (jobUnit.unit_name) {
      jobDetailsUnits.push({ key: "Unit ID", value: jobUnit.unit_name });
    }
    jobDetailsUnits.push(
      {
        key: "Model No#",
        value: jobUnit.model_no,
      },
      { key: SERIAL_NO, value: jobUnit.serial_no },
    );
    jobUnit?.photo_barcode_url &&
      jobDetailsUnits.push({
        key: "Image Details",
        value: {
          photo: jobUnit?.photo_barcode_url,
          stepType: "Unit name plate",
        },
      });
    jobUnit.steps.forEach((step) => {
      jobDetailsUnits.push({
        key: "Image Details",
        value: {
          photo: step.photo || "",
          stepType:
            StepType[step.steps_type?.definition as keyof typeof StepType],
        },
      });
    });

    jobUnit.inspection.forEach((inspectionData) => {
      jobDetailsUnits.push({
        key: "Section Details",
        value: {
          order: inspectionData.schema?.order || 0,
          section: inspectionData.questionaire_section.section || "",
          sectionField: inspectionData.schema?.label || "",
          sectionOrder: inspectionData.questionaire_section.order || 0,
          value: inspectionData.value || inspectionData.array_value,
          valueType: inspectionData.schema?.type || "",
        },
      });
    });
    jobDetailsUnitsCollection.push(jobDetailsUnits);
  });
  jobData.jobDetailsUnitsCollection = jobDetailsUnitsCollection;

  return jobData;
};

export const renderJobList = (
  jobList: GetJobListQuery | undefined,
  dispatch: ReturnType<typeof useAppDispatch>,
) => {
  let dataInfoArray: DataSource[];

  if (jobList != null && jobList.jobs && jobList.jobs.length > 0) {
    dataInfoArray = jobList.jobs.map(transformJob);
    dispatch(
      jobListSlice.actions.setJobList({
        jobList: dataInfoArray,
      }),
    );
  }
};

function getStatusInfo(status: string): StatusInfo | undefined {
  switch (status) {
    case Status.Approved:
      return {
        backgroundColor: "#EBFEEB",
        status: "Approved",
        textColor: "#058034",
      };
    case Status.NotApproved:
      return {
        backgroundColor: "#EDF0F3",
        status: "Not Approved",
        textColor: "#252A32",
      };
    case Status.Rejected:
      return {
        backgroundColor: "#FEF2F2",
        status: "Approved",
        textColor: "#EA0029",
      };
  }
}

const columns: Column[] = [
  {
    dataIndex: "jobType",
    key: "jobType",
    sortIcon: () => <img className={styles.sortLogo} src={sortIcon} />,
    sorter: (a: { jobType: string }, b: { jobType: string }) =>
      a.jobType.localeCompare(b.jobType),
    title: <span className={styles.headerTitleText}>Job Type</span>,
  },
  {
    dataIndex: "store",
    key: "store",
    render: (text: string) => (
      <span className={styles.storeDetailsText}>{text}</span>
    ),
    sortIcon: () => <img className={styles.sortLogo} src={sortIcon} />,
    sorter: (a: { store: string }, b: { store: string }) =>
      a.store.localeCompare(b.store),
    title: <span className={styles.headerTitleText}>Store Details</span>,
  },
  {
    dataIndex: "date",
    key: "date",
    sortIcon: () => <img className={styles.sortLogo} src={sortIcon} />,
    sorter: (
      a: { date: string | number | Date },
      b: { date: string | number | Date },
    ) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    title: <span className={styles.headerTitleText}>Date</span>,
  },
  {
    dataIndex: "contractor",
    key: "contractor",
    sortIcon: () => <img className={styles.sortLogo} src={sortIcon} />,
    sorter: (a: { contractor: string }, b: { contractor: string }) =>
      a.contractor.localeCompare(b.contractor),
    title: <span className={styles.headerTitleText}>Contractor Details</span>,
  },
  {
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <span
        style={{
          backgroundColor: getStatusInfo(text)?.backgroundColor,
          borderRadius: 8,
          color: getStatusInfo(text)?.textColor,
          height: 28,
          padding: 8,
          width: 82,
        }}
      >
        {text}
      </span>
    ),
    sortIcon: () => <img className={styles.sortLogo} src={sortIcon} />,
    sorter: (a: { status: string }, b: { status: string }) =>
      a.status.localeCompare(b.status),
    title: <span className={styles.headerTitleText}>Status</span>,
  },
  {
    dataIndex: "technician",
    key: "technician",
    sortIcon: () => <img className={styles.sortLogo} src={sortIcon} />,
    sorter: (a: { technician: string }, b: { technician: string }) =>
      a.technician.localeCompare(b.technician),
    title: <span className={styles.headerTitleText}>Technician</span>,
  },
];

function JobsList() {
  const { authState } = useOktaAuth();
  const { data: jobList, isLoading } = useGetJobListQuery();
  const [showPopup, setShowPopup] = useState(false);
  const data: { jobList: DataSource[] } = useAppSelector(
    (state) => state.jobList,
  );
  const email: string | undefined = authState?.accessToken?.claims.sub;
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLElement | null>(null);
  const outerRef = useOuterClickExcludingButton(buttonRef, () => {
    setShowPopup(false);
  });

  const handleLogout = () => {
    setShowPopup(false);
    try {
      oktaAuth
        .signOut()
        .then(() => {})
        .catch(() => {});
    } catch (e) {
      console.error("Error on logout:", e);
    }
  };

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    renderJobList(jobList, dispatch);
  }, [jobList, dispatch]);

  return (
    <div
      className={styles.appContainer}
      ref={outerRef as LegacyRef<HTMLDivElement>}
    >
      <AppBar position="static">
        <Toolbar className={styles.headerBar}>
          <div className={styles.appBarInfo}>
            <img className={styles.logo} src={logo} alt="Logo" />
            <Typography
              className={styles.headerBarText}
              variant="h6"
              component="div"
            >
              HVAC Pre-Startup Admin Panel
            </Typography>
          </div>
          <div className={styles.headerRighIcon} onClick={handleClick}>
            {email?.charAt(0)}
          </div>
        </Toolbar>
      </AppBar>
      <table className={styles.tableInfo}>
        <thead>
          <tr className={styles.headerTitle}>{"Job list"}</tr>
        </thead>
        <tbody>
          <Accordion columns={columns} data={data.jobList} />
        </tbody>
      </table>
      {showPopup && (
        <div
          className={styles.logoutButton}
          onClick={handleLogout}
          ref={buttonRef as LegacyRef<HTMLDivElement>}
        >
          {"Log Out"}
        </div>
      )}
      <ModalView visible={isLoading} loading={true} footer={null} />
    </div>
  );
}

export default JobsList;
