import React from "react";

import Highlighter from "react-highlight-words";

import styles from "../app.module.scss";

interface HighlightedTextProps {
  searchWords: string[];
  title: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
  title,
  searchWords,
}) => {
  return (
    <Highlighter
      unhighlightClassName={styles.headerTitleText}
      searchWords={searchWords}
      autoEscape
      textToHighlight={title}
      highlightClassName={styles.headerTitleTextHighlighted}
    />
  );
};

export default HighlightedText;
