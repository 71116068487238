import { AuthState } from "@okta/okta-auth-js";
import axios from "axios";
import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  HeightRule,
  ImageRun,
  PageNumber,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from "docx";

import logo from "../../assets/ic_brandLogo.png"; // Import your image here
import startIcon from "../../assets/ic_startIcon.png";
import { environment } from "../../environments/environment";
import {
  ImageData,
  JobDetailsBasicUnit,
  SectionData,
  UNIT_ID,
} from "../jobslist";

import {
  convertArrayToString,
  getSectionFieldBySection,
  getSections,
} from "./accordianDetails";

export class InspectionDocumentCreator {
  public async create(
    authState: AuthState | null,
    jobUnitDetailsArray: JobDetailsBasicUnit[][],
    storeDetails: JobDetailsBasicUnit[],
    technician: string,
    startDate: string,
    onProgress: (progress: number) => void,
  ): Promise<Document> {
    let location = "";
    storeDetails.forEach((a) => {
      location = location + " " + a.value;
    });
    const spacerParagraph = new Paragraph({
      spacing: {
        after: 200,
      },
    });
    const document = new Document({
      sections: [
        {
          children: [
            await this.renderStartImage(),
            new Paragraph({
              children: [
                new TextRun({
                  bold: true,
                  font: "Roobert TRIAL",
                  size: 18,
                  text: "Manufacturing Quality Inspection",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  color: "#545F70",
                  font: "Roobert TRIAL",
                  size: 12,
                  text: startDate + " by " + technician,
                }),
              ],
              indent: {
                left: 40,
              },
              spacing: {
                after: 80,
                before: 40,
              },
            }),
            spacerParagraph,
            // Location Details
            new Paragraph({
              children: [
                new TextRun({
                  bold: true,
                  font: "Roobert TRIAL",
                  size: 14,
                  text: "Location Details",
                }),
              ],
              spacing: {
                // Top space in twips
                after: 80,
                before: 80, // Bottom space in twips
              },
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        right: { size: 0, style: BorderStyle.NIL },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              bold: true,
                              font: "Roobert TRIAL",
                              size: 12,
                              text: "Location",
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                      shading: {
                        fill: "#EDF0F3", // Green background color
                      },
                      width: {
                        size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                        type: WidthType.DXA, // Fixed width in DXA (twips)
                      },
                    }),
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: { size: 0, style: BorderStyle.NIL },
                        right: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              bold: true,
                              size: 18,
                              text: " ",
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                      shading: {
                        fill: "#EDF0F3", // Green background color
                      },
                      width: {
                        size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                        type: WidthType.DXA, // Fixed width in DXA (twips)
                      },
                    }),
                  ],
                  height: {
                    // Height in twips (30px = 450 twips)
                    rule: HeightRule.EXACT,
                    value: 450, // Exact height
                  },
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        right: { size: 0, style: BorderStyle.NIL },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              font: "Roobert TRIAL",
                              size: 10,
                              text: location,
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: { size: 0, style: BorderStyle.NIL },
                        right: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              size: 14,
                              text: "",
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                    }),
                  ],
                  height: {
                    rule: HeightRule.EXACT,
                    value: 450,
                  },
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        bottom: { size: 0, style: BorderStyle.NIL },
                        left: { size: 0, style: BorderStyle.NIL },
                        right: { size: 0, style: BorderStyle.NIL },
                        top: { size: 0, style: BorderStyle.NIL },
                      },
                      children: [spacerParagraph],
                    }),
                    new TableCell({
                      borders: {
                        bottom: { size: 0, style: BorderStyle.NIL },
                        left: { size: 0, style: BorderStyle.NIL },
                        right: { size: 0, style: BorderStyle.NIL },
                        top: { size: 0, style: BorderStyle.NIL },
                      },
                      children: [spacerParagraph],
                    }),
                  ],
                }),
              ],
            }),
            // Technician Details
            new Paragraph({
              children: [
                new TextRun({
                  bold: true,
                  font: "Roobert TRIAL",
                  size: 14,
                  text: "Technician Details",
                }),
              ],
              spacing: {
                // Top space in twips
                after: 80,
                before: 80, // Bottom space in twips
              },
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        right: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              bold: true,
                              font: "Roobert TRIAL",
                              size: 12,
                              text: "Prepared By",
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                      shading: {
                        fill: "#EDF0F3", // Green background color
                      },
                      width: {
                        size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                        type: WidthType.DXA, // Fixed width in DXA (twips)
                      },
                    }),
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        right: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              bold: true,
                              font: "Roobert TRIAL",
                              size: 12,
                              text: "Conducted on",
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                      shading: {
                        fill: "#EDF0F3", // Green background color
                      },
                      width: {
                        size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                        type: WidthType.DXA, // Fixed width in DXA (twips)
                      },
                    }),
                  ],
                  height: {
                    // Height in twips (30px = 450 twips)
                    rule: HeightRule.EXACT,
                    value: 450, // Exact height
                  },
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        right: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              font: "Roobert TRIAL",
                              size: 10,
                              text: technician,
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        bottom: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        left: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        right: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                        top: {
                          color: "#EDF0F3",
                          size: 8,
                          style: BorderStyle.SINGLE,
                        },
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              font: "Roobert TRIAL",
                              size: 10,
                              text: startDate,
                            }),
                          ],
                          indent: {
                            left: 40, // Left indentation in twips (acts as left space)
                          },
                          spacing: {
                            // Top space in twips (e.g., 400 twips = ~0.28 inches)
                            after: 80,
                            before: 40, // Bottom space in twips
                          },
                        }),
                      ],
                    }),
                  ],
                  height: {
                    rule: HeightRule.EXACT,
                    value: 450,
                  },
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        bottom: { size: 0, style: BorderStyle.NIL },
                        left: { size: 0, style: BorderStyle.NIL },
                        right: { size: 0, style: BorderStyle.NIL },
                        top: { size: 0, style: BorderStyle.NIL },
                      },
                      children: [spacerParagraph],
                    }),
                    new TableCell({
                      borders: {
                        bottom: { size: 0, style: BorderStyle.NIL },
                        left: { size: 0, style: BorderStyle.NIL },
                        right: { size: 0, style: BorderStyle.NIL },
                        top: { size: 0, style: BorderStyle.NIL },
                      },
                      children: [spacerParagraph],
                    }),
                  ],
                }),
              ],
            }),
            ...(
              await Promise.all(
                jobUnitDetailsArray.map(async (jobUnitDetails, index) => [
                  // Unit Details
                  new Paragraph({
                    children: [
                      new TextRun({
                        bold: true,
                        font: "Roobert TRIAL",
                        size: 14,
                        text: "Unit Details",
                      }),
                    ],
                    spacing: {
                      // Top space in twips
                      after: 80,
                      before: 80, // Bottom space in twips
                    },
                  }),
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    bold: true,
                                    font: "Roobert TRIAL",
                                    size: 12,
                                    text: "Unit Id",
                                  }),
                                ],
                                indent: {
                                  left: 40,
                                },
                                spacing: {
                                  after: 80,
                                  before: 40,
                                },
                              }),
                            ],
                            shading: {
                              fill: "#EDF0F3",
                            },
                            width: {
                              size: 240 * 20,
                              type: WidthType.DXA,
                            },
                          }),
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    bold: true,
                                    font: "Roobert TRIAL",
                                    size: 12,
                                    text: "Model Number",
                                  }),
                                ],
                                indent: {
                                  left: 40,
                                },
                                spacing: {
                                  after: 80,
                                  before: 40,
                                },
                              }),
                            ],
                            shading: {
                              fill: "#EDF0F3",
                            },
                            width: {
                              size: 240 * 20,
                              type: WidthType.DXA,
                            },
                          }),
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    bold: true,
                                    font: "Roobert TRIAL",
                                    size: 12,
                                    text: "Serial Number",
                                  }),
                                ],
                                indent: {
                                  left: 40, // Left indentation in twips (acts as left space)
                                },
                                spacing: {
                                  // Top space in twips (e.g., 400 twips = ~0.28 inches)
                                  after: 80,
                                  before: 40, // Bottom space in twips
                                },
                              }),
                            ],
                            shading: {
                              fill: "#EDF0F3", // Green background color
                            },
                            width: {
                              size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                              type: WidthType.DXA, // Fixed width in DXA (twips)
                            },
                          }),
                        ],
                        height: {
                          rule: HeightRule.EXACT,
                          value: 450,
                        },
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    font: "Roobert TRIAL",
                                    size: 10,
                                    text: this.getModelNo(jobUnitDetails),
                                  }),
                                ],
                                indent: {
                                  left: 40, // Left indentation in twips (acts as left space)
                                },
                                spacing: {
                                  // Top space in twips (e.g., 400 twips = ~0.28 inches)
                                  after: 80,
                                  before: 40, // Bottom space in twips
                                },
                              }),
                            ],
                          }),
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    font: "Roobert TRIAL",
                                    size: 10,
                                    text: this.getModelNo(jobUnitDetails),
                                  }),
                                ],
                                indent: {
                                  left: 40, // Left indentation in twips (acts as left space)
                                },
                                spacing: {
                                  // Top space in twips (e.g., 400 twips = ~0.28 inches)
                                  after: 80,
                                  before: 40, // Bottom space in twips
                                },
                              }),
                            ],
                          }),
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    font: "Roobert TRIAL",
                                    size: 10,
                                    text: this.getSerialNo(jobUnitDetails),
                                  }),
                                ],
                                indent: {
                                  left: 40, // Left indentation in twips (acts as left space)
                                },
                                spacing: {
                                  // Top space in twips (e.g., 400 twips = ~0.28 inches)
                                  after: 80,
                                  before: 40, // Bottom space in twips
                                },
                              }),
                            ],
                          }),
                        ],
                        height: {
                          rule: HeightRule.EXACT,
                          value: 450,
                        },
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              bottom: { size: 0, style: BorderStyle.NIL },
                              left: { size: 0, style: BorderStyle.NIL },
                              right: { size: 0, style: BorderStyle.NIL },
                              top: { size: 0, style: BorderStyle.NIL },
                            },
                            children: [spacerParagraph],
                          }),
                          new TableCell({
                            borders: {
                              bottom: { size: 0, style: BorderStyle.NIL },
                              left: { size: 0, style: BorderStyle.NIL },
                              right: { size: 0, style: BorderStyle.NIL },
                              top: { size: 0, style: BorderStyle.NIL },
                            },
                            children: [spacerParagraph],
                          }),
                        ],
                        height: {
                          rule: HeightRule.EXACT,
                          value: 450,
                        },
                      }),
                    ],
                  }),
                  // Results
                  new Paragraph({
                    children: [
                      new TextRun({
                        bold: true,
                        font: "Roobert TRIAL",
                        size: 14,
                        text: "Results",
                      }),
                    ],
                    spacing: {
                      // Top space in twips
                      after: 80,
                      before: 80, // Bottom space in twips
                    },
                  }),
                  this.renderResults(jobUnitDetails),
                  spacerParagraph,
                  this.renderFlaggedItems(jobUnitDetails),
                  // Page Break for the next section
                  new Paragraph({
                    children: [],
                    pageBreakBefore: true, // This will create a page break before this paragraph
                  }),
                  // Second Page (Model/Serial and Images)
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              right: { size: 0, style: BorderStyle.NIL },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    bold: true,
                                    font: "Roobert TRIAL",
                                    size: 12,
                                    text: "Model/Serial",
                                  }),
                                ],
                                indent: {
                                  left: 40, // Left indentation in twips (acts as left space)
                                },
                                spacing: {
                                  // Top space in twips (e.g., 400 twips = ~0.28 inches)
                                  after: 80,
                                  before: 40, // Bottom space in twips
                                },
                              }),
                            ],
                            shading: {
                              fill: "#EDF0F3", // Green background color
                            },
                            width: {
                              size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                              type: WidthType.DXA, // Fixed width in DXA (twips)
                            },
                          }),
                          new TableCell({
                            borders: {
                              bottom: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              left: { size: 0, style: BorderStyle.NIL },
                              right: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                              top: {
                                color: "#EDF0F3",
                                size: 8,
                                style: BorderStyle.SINGLE,
                              },
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    bold: true,
                                    size: 12,
                                    text: " ",
                                  }),
                                ],
                                indent: {
                                  left: 40, // Left indentation in twips (acts as left space)
                                },
                                spacing: {
                                  // Top space in twips (e.g., 400 twips = ~0.28 inches)
                                  after: 80,
                                  before: 40, // Bottom space in twips
                                },
                              }),
                            ],
                            shading: {
                              fill: "#EDF0F3", // Green background color
                            },
                            width: {
                              size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                              type: WidthType.DXA, // Fixed width in DXA (twips)
                            },
                          }),
                        ],
                      }),
                    ],
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        font: "Roobert TRIAL",
                        size: 14,
                        text: "Unit Nameplate Picture",
                      }),
                    ],
                  }),
                  new Paragraph({ text: " " }),
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: await this.getImageBlob(
                          authState!,
                          (
                            this.getInstallationDetails(jobUnitDetails)[0]
                              .value as ImageData
                          ).photo,
                        ),
                        transformation: {
                          height: 100,
                          width: 100,
                        },
                      }),
                    ],
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        font: "Roobert TRIAL",
                        size: 14,
                        text: (
                          this.getInstallationDetails(jobUnitDetails)[0]
                            .value as ImageData
                        ).stepType,
                      }),
                    ],
                  }),
                  new Paragraph({ text: " " }),
                  await this.generateSection(
                    jobUnitDetails,
                    authState!,
                    onProgress,
                  ),
                  this.getPageBreak(jobUnitDetailsArray, index),
                ]),
              )
            ).flat(),
          ],
          footers: {
            default: await this.renderFooter(), // Use the footer for all pages
          },
        },
      ],
    });
    return document;
  }

  public async generateSection(
    jobUnitDetails: JobDetailsBasicUnit[],
    authState: AuthState,
    onProgress: (progress: number) => void,
  ): Promise<Table> {
    const sectionDetails = jobUnitDetails.filter(
      (item) => item.key === "Section Details",
    );
    const sortBySectionOrder = sectionDetails.sort(function (a, b) {
      return (
        (a.value as SectionData).sectionOrder -
        (b.value as SectionData).sectionOrder
      );
    });
    const sections = getSections(sortBySectionOrder);
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              borders: {
                bottom: { size: 0, style: BorderStyle.NIL },
                left: { size: 0, style: BorderStyle.NIL },
                right: { size: 0, style: BorderStyle.NIL },
                top: { size: 0, style: BorderStyle.NIL },
              },
              children: await this.renderSectionInfo(
                sections,
                sectionDetails,
                authState,
                onProgress,
              ),
            }),
          ],
        }),
      ],
    });
  }

  public async generateSectionImages(
    section: string,
    sectionDetails: JobDetailsBasicUnit[],
    authState: AuthState,
  ): Promise<Table> {
    return new Table({
      rows: await this.renderSectionImage(section, sectionDetails, authState),
    });
  }

  public async renderSectionInfo(
    sections: string[],
    sectionDetails: JobDetailsBasicUnit[],
    authState: AuthState,
    onProgress: (progress: number) => void,
  ): Promise<Table[]> {
    const tables: Table[] = [];
    const keyGetter = (item: JobDetailsBasicUnit) => {
      return (item.value as SectionData).section;
    };
    const groupedData = getSectionFieldBySection(sectionDetails, keyGetter);
    const totalSections = sections.length;
    let completedSections = 0;

    for (const section of sections) {
      const rows: TableRow[] = [];
      const sectionInfo = groupedData.get(section)!;
      const sortByOrder = sectionInfo.sort(function (a, b) {
        return (a.value as SectionData).order - (b.value as SectionData).order;
      });
      const filterSections = sortByOrder.filter(
        (sectionData) =>
          (sectionData.value as SectionData).valueType !== "PICTURE",
      );
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              borders: {
                bottom: { size: 0, style: BorderStyle.NIL },
                left: { size: 0, style: BorderStyle.NIL },
                right: { size: 0, style: BorderStyle.NIL },
                top: { size: 0, style: BorderStyle.NIL },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      font: "Roobert TRIAL",
                      size: 12,
                      text: section,
                    }),
                  ],
                  indent: {
                    left: 40, // Left indentation in twips (acts as left space)
                  },
                  spacing: {
                    // Top space in twips (e.g., 400 twips = ~0.28 inches)
                    after: 80,
                    before: 40, // Bottom space in twips
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3", // Green background color
              },
            }),
            new TableCell({
              borders: {
                bottom: { size: 0, style: BorderStyle.NIL },
                left: { size: 0, style: BorderStyle.NIL },
                right: { size: 0, style: BorderStyle.NIL },
                top: { size: 0, style: BorderStyle.NIL },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      size: 12,
                      text: "",
                    }),
                  ],
                  indent: {
                    left: 40, // Left indentation in twips (acts as left space)
                  },
                  spacing: {
                    // Top space in twips (e.g., 400 twips = ~0.28 inches)
                    after: 80,
                    before: 40, // Bottom space in twips
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3", // Green background color
              },
            }),
          ],
        }),
      );
      filterSections.forEach(async (sectionData) => {
        rows.push(
          new TableRow({
            children: [...this.renderRowData(sectionData)],
          }),
        );
      });
      tables.push(
        new Table({
          rows: [
            new TableRow({
              cantSplit: true,
              children: [
                new TableCell({
                  borders: {
                    bottom: {
                      color: "#EDF0F3",
                      size: 1,
                      style: BorderStyle.SINGLE,
                    },
                    left: {
                      color: "#EDF0F3",
                      size: 1,
                      style: BorderStyle.SINGLE,
                    },
                    right: {
                      color: "#EDF0F3",
                      size: 1,
                      style: BorderStyle.SINGLE,
                    },
                    top: {
                      color: "#EDF0F3",
                      size: 1,
                      style: BorderStyle.SINGLE,
                    },
                  },
                  children: [
                    new Table({
                      rows: rows,
                    }),
                    new Paragraph({ text: "" }),
                    await this.generateSectionImages(
                      section,
                      sectionDetails,
                      authState!,
                    ),
                    new Paragraph({ text: "" }),
                  ],
                }),
              ],
            }),
          ],
        }),
      );
      completedSections += 1;
      const progress = Math.round((completedSections / totalSections) * 100);
      onProgress(progress);
    }
    return tables;
  }

  public async renderSectionImage(
    section: string,
    sectionDetails: JobDetailsBasicUnit[],
    authState: AuthState,
  ): Promise<TableRow[]> {
    const rows: TableRow[] = [];
    const keyGetter = (item: JobDetailsBasicUnit) => {
      return (item.value as SectionData).section;
    };
    const groupedData = getSectionFieldBySection(sectionDetails, keyGetter);
    const sectionInfo = groupedData.get(section)!;
    const imagesInfo = await this.renderImageTable(sectionInfo, authState!);
    rows.push(...imagesInfo);
    return rows;
  }

  public renderRowData(sectionData: JobDetailsBasicUnit): TableCell[] {
    const cells: TableCell[] = [];
    cells.push(
      new TableCell({
        borders: {
          bottom: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
          left: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
          right: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
          top: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                font: "Roobert TRIAL",
                size: 10,
                text: (sectionData.value as SectionData).sectionField,
              }),
            ],
            indent: {
              left: 40, // Left indentation in twips (acts as left space)
            },
            spacing: {
              // Top space in twips (e.g., 400 twips = ~0.28 inches)
              after: 80,
              before: 40, // Bottom space in twips
            },
          }),
        ],
        width: {
          size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
          type: WidthType.DXA, // Fixed width in DXA (twips)
        },
      }),
    );
    cells.push(
      new TableCell({
        borders: {
          bottom: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
          left: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
          right: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
          top: { color: "#EDF0F3", size: 8, style: BorderStyle.SINGLE },
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                color:
                  convertArrayToString(
                    (sectionData.value as SectionData).value,
                  ).toLowerCase() === "no"
                    ? "#EA0029"
                    : convertArrayToString(
                          (sectionData.value as SectionData).value,
                        ).toLowerCase() === "na"
                      ? "#000000"
                      : "#058034",
                font: "Roobert TRIAL",
                size: 10,
                text: convertArrayToString(
                  (sectionData.value as SectionData).value,
                ),
              }),
            ],
            indent: {
              left: 40, // Left indentation in twips (acts as left space)
            },
            spacing: {
              // Top space in twips (e.g., 400 twips = ~0.28 inches)
              after: 80,
              before: 40, // Bottom space in twips
            },
          }),
        ],
        width: {
          size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
          type: WidthType.DXA, // Fixed width in DXA (twips)
        },
      }),
    );
    return cells;
  }

  public async renderImageData(
    sectionInfo: JobDetailsBasicUnit[],
    authState: AuthState,
  ): Promise<Paragraph> {
    const paragraph = new Paragraph({});
    const imageSections = sectionInfo.filter(
      (sectionData) =>
        (sectionData.value as SectionData).valueType === "PICTURE",
    );
    for (const sectionData of imageSections) {
      const imageData = await this.getImageBlob(
        authState,
        convertArrayToString((sectionData.value as SectionData).value),
      );
      paragraph.addChildElement(
        new ImageRun({
          data: imageData,
          transformation: {
            height: 100,
            width: 100,
          },
        }),
      );
    }
    return paragraph;
  }

  public async getImageBlob(
    authState: AuthState,
    fileName: string,
  ): Promise<ArrayBuffer> {
    try {
      const url = `${environment.uploadServiceUrl}/files/download`;

      const accessToken: string | undefined =
        authState?.accessToken?.accessToken;
      const response = await axios.get<{ url: string }>(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
        params: { appId: "startup", file: fileName },
      });

      const blob = await fetch(response.data.url).then((r) => r.arrayBuffer());
      return blob;
    } catch (error) {
      console.error("Error fetching image:", error);
      return new ArrayBuffer(0);
    }
  }

  public async renderImageTable(
    sectionInfo: JobDetailsBasicUnit[],
    authState: AuthState,
  ): Promise<TableRow[]> {
    const spacerParagraph = new Paragraph({
      spacing: {
        after: 10,
      },
    });
    const cells: TableCell[] = [];
    const rows: TableRow[] = [];
    const imageSections = sectionInfo.filter(
      (sectionData) =>
        (sectionData.value as SectionData).valueType === "PICTURE",
    );
    for (const sectionData of imageSections) {
      if (Array.isArray((sectionData.value as SectionData).value)) {
        const imageArray = (sectionData.value as SectionData).value as string[];
        for (const imageUrl of imageArray) {
          const imageData = await this.getImageBlob(authState, imageUrl);
          const imageParagraph = new Paragraph({
            children: [
              new ImageRun({
                data: imageData,
                transformation: {
                  height: 100,
                  width: 100,
                },
              }),
            ],
          });
          const textParagraph = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                font: "Roobert TRIAL",
                size: 10,
                text: (sectionData.value as SectionData).sectionField,
              }),
            ],
            spacing: {
              after: 20,
              before: 20,
            },
          });
          cells.push(
            new TableCell({
              borders: {
                bottom: { size: 0, style: BorderStyle.NIL },
                left: { size: 0, style: BorderStyle.NIL },
                right: { size: 0, style: BorderStyle.NIL },
                top: { size: 0, style: BorderStyle.NIL },
              },
              children: [
                imageParagraph,
                textParagraph,
                new Paragraph({ text: "" }),
                new Paragraph({ text: "" }),
              ],
              margins: {
                bottom: 20,
                left: 20,
                right: 20,
                top: 20,
              },
            }),
          );
        }
      } else {
        const imageData = await this.getImageBlob(
          authState,
          convertArrayToString((sectionData.value as SectionData).value),
        );
        const imageParagraph = new Paragraph({
          children: [
            new ImageRun({
              data: imageData,
              transformation: {
                height: 100,
                width: 100,
              },
            }),
          ],
        });
        const textParagraph = new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              font: "Roobert TRIAL",
              size: 10,
              text: (sectionData.value as SectionData).sectionField,
            }),
          ],
          spacing: {
            after: 20,
            before: 20,
          },
        });
        cells.push(
          new TableCell({
            borders: {
              bottom: { size: 0, style: BorderStyle.NIL },
              left: { size: 0, style: BorderStyle.NIL },
              right: { size: 0, style: BorderStyle.NIL },
              top: { size: 0, style: BorderStyle.NIL },
            },
            children: [
              imageParagraph,
              textParagraph,
              new Paragraph({ text: "" }),
              new Paragraph({ text: "" }),
            ],
            margins: {
              bottom: 20,
              left: 20,
              right: 20,
              top: 20,
            },
          }),
        );
      }
    }

    if (cells.length > 5) {
      const half_length = Math.ceil(cells.length / 2);

      const firstRow = cells.slice(0, half_length);
      const secondRow = cells.slice(half_length, cells.length);
      const firstTableRow = new TableRow({
        children: firstRow,
      });
      rows.push(firstTableRow);
      const secondTableRow = new TableRow({
        children: secondRow,
      });
      rows.push(secondTableRow);
    } else if (cells.length > 0) {
      const row = new TableRow({
        children: cells,
      });
      rows.push(row);
    } else {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              borders: {
                bottom: { size: 0, style: BorderStyle.NIL },
                left: { size: 0, style: BorderStyle.NIL },
                right: { size: 0, style: BorderStyle.NIL },
                top: { size: 0, style: BorderStyle.NIL },
              },
              children: [spacerParagraph],
            }),
            new TableCell({
              borders: {
                bottom: { size: 0, style: BorderStyle.NIL },
                left: { size: 0, style: BorderStyle.NIL },
                right: { size: 0, style: BorderStyle.NIL },
                top: { size: 0, style: BorderStyle.NIL },
              },
              children: [spacerParagraph],
            }),
          ],
          height: {
            rule: HeightRule.EXACT,
            value: 450,
          },
        }),
      );
    }
    return rows;
  }

  public async renderFooter(): Promise<Footer> {
    const response = await fetch(logo);
    const imageData = await response.arrayBuffer();
    const footer = new Footer({
      children: [
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new ImageRun({
              data: imageData,
              transformation: {
                height: 14,
                width: 69,
              },
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              children: [PageNumber.CURRENT],
              font: "Roobert TRIAL",
              size: 8,
            }),
            new TextRun({
              children: ["/", PageNumber.TOTAL_PAGES],
              font: "Roobert TRIAL",
              size: 8,
            }),
          ],
        }),
      ],
    });
    return footer;
  }

  public async renderStartImage(): Promise<Paragraph> {
    const response = await fetch(startIcon);
    const imageData = await response.arrayBuffer();
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new ImageRun({
          data: imageData,
          transformation: {
            height: 60,
            width: 105,
          },
        }),
      ],
    });
  }

  public renderFlaggedItems(jobUnitDetails: JobDetailsBasicUnit[]): Table {
    const sectionDetails = jobUnitDetails.filter(
      (item) => item.key === "Section Details",
    );
    const sortBySectionOrder = sectionDetails.sort(function (a, b) {
      return (
        (a.value as SectionData).sectionOrder -
        (b.value as SectionData).sectionOrder
      );
    });
    const sections = getSections(sortBySectionOrder);
    const flaggedSectionsArray = this.getFlagItemsArray(
      sections,
      sectionDetails,
    );
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: { size: 0, style: BorderStyle.NIL },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      font: "Roobert TRIAL",
                      size: 12,
                      text: "Flagged Items",
                    }),
                  ],
                  indent: {
                    left: 40, // Left indentation in twips (acts as left space)
                  },
                  spacing: {
                    // Top space in twips (e.g., 400 twips = ~0.28 inches)
                    after: 80,
                    before: 40, // Bottom space in twips
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3", // Green background color
              },
              width: {
                size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                type: WidthType.DXA, // Fixed width in DXA (twips)
              },
            }),
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: { size: 0, style: BorderStyle.NIL },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      size: 12,
                      text: " ",
                    }),
                  ],
                  indent: {
                    left: 40, // Left indentation in twips (acts as left space)
                  },
                  spacing: {
                    // Top space in twips (e.g., 400 twips = ~0.28 inches)
                    after: 80,
                    before: 40, // Bottom space in twips
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3", // Green background color
              },
              width: {
                size: 240 * 20, // Width in twips (238.5px = 238.5 * 15 = 3577.5 twips)
                type: WidthType.DXA, // Fixed width in DXA (twips)
              },
            }),
          ],
          height: {
            // Height in twips (30px = 450 twips)
            rule: HeightRule.EXACT,
            value: 450, // Exact height
          },
        }),
        ...this.renderFlaggedItemsRows(flaggedSectionsArray),
      ],
    });
  }

  public renderFlaggedItemsRows(
    flaggedItems: JobDetailsBasicUnit[],
  ): TableRow[] {
    const rows: TableRow[] = [];
    for (const flaggedItem of flaggedItems) {
      const cells: TableCell[] = [];
      cells.push(
        new TableCell({
          borders: {
            bottom: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
            left: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
            right: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
            top: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  color: "#545F70",
                  font: "Roobert TRIAL",
                  size: 8,
                  text: (flaggedItem.value as SectionData).section,
                }),
              ],
              indent: {
                left: 40, // Left indentation in twips (acts as left space)
              },
              spacing: {
                // Top space in twips (e.g., 400 twips = ~0.28 inches)
                after: 80,
                before: 40, // Bottom space in twips
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  color: "#141923",
                  font: "Roobert TRIAL",
                  size: 10,
                  text: (flaggedItem.value as SectionData).sectionField,
                }),
              ],
              indent: {
                left: 40, // Left indentation in twips (acts as left space)
              },
              spacing: {
                // Top space in twips (e.g., 400 twips = ~0.28 inches)
                after: 80,
                before: 40, // Bottom space in twips
              },
            }),
          ],
          width: {
            size: 240 * 20,
            type: WidthType.DXA,
          },
        }),
      );
      cells.push(
        new TableCell({
          borders: {
            bottom: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
            left: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
            right: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
            top: {
              color: "#EDF0F3",
              size: 8,
              style: BorderStyle.SINGLE,
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  color: "#EA0029",
                  font: "Roobert TRIAL",
                  size: 10,
                  text: convertArrayToString(
                    (flaggedItem.value as SectionData).value,
                  ),
                }),
              ],
              indent: {
                left: 40, // Left indentation in twips (acts as left space)
              },
              spacing: {
                // Top space in twips (e.g., 400 twips = ~0.28 inches)
                after: 80,
                before: 40, // Bottom space in twips
              },
            }),
          ],
          width: {
            size: 240 * 20,
            type: WidthType.DXA,
          },
        }),
      );
      rows.push(
        new TableRow({
          children: [...cells],
          height: {
            rule: HeightRule.EXACT,
            value: 450,
          },
        }),
      );
    }
    return rows;
  }

  public renderResults(jobUnitDetails: JobDetailsBasicUnit[]): Table {
    const sectionDetails = jobUnitDetails.filter(
      (item) => item.key === "Section Details",
    );
    const sortBySectionOrder = sectionDetails.sort(function (a, b) {
      return (
        (a.value as SectionData).sectionOrder -
        (b.value as SectionData).sectionOrder
      );
    });
    const sections = getSections(sortBySectionOrder);
    const flaggedSectionsArray = this.getFlagItemsArray(
      sections,
      sectionDetails,
    );

    const percentage = Math.floor(
      (getSections(flaggedSectionsArray).length / sections.length) * 100,
    );
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      font: "Roobert TRIAL",
                      size: 12,
                      text: "Score",
                    }),
                  ],
                  indent: {
                    left: 40,
                  },
                  spacing: {
                    after: 80,
                    before: 40,
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3",
              },
              width: {
                size: 240 * 20,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      font: "Roobert TRIAL",
                      size: 12,
                      text: "Flagged items",
                    }),
                  ],
                  indent: {
                    left: 40,
                  },
                  spacing: {
                    after: 80,
                    before: 40,
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3",
              },
              width: {
                size: 240 * 20,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      bold: true,
                      font: "Roobert TRIAL",
                      size: 12,
                      text: "Actions",
                    }),
                  ],
                  indent: {
                    left: 40,
                  },
                  spacing: {
                    after: 80,
                    before: 40,
                  },
                }),
              ],
              shading: {
                fill: "#EDF0F3",
              },
              width: {
                size: 240 * 20,
                type: WidthType.DXA,
              },
            }),
          ],
          height: {
            rule: HeightRule.EXACT,
            value: 450,
          },
        }),
        new TableRow({
          children: [
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      font: "Roobert TRIAL",
                      size: 10,
                      text: `${getSections(flaggedSectionsArray).length} / ${sections.length} (${percentage}%)`,
                    }),
                  ],
                  indent: {
                    left: 40,
                  },
                  spacing: {
                    after: 80,
                    before: 40,
                  },
                }),
              ],
            }),
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      font: "Roobert TRIAL",
                      size: 10,
                      text: flaggedSectionsArray.length.toString(),
                    }),
                  ],
                  indent: {
                    left: 40,
                  },
                  spacing: {
                    after: 80,
                    before: 40,
                  },
                }),
              ],
            }),
            new TableCell({
              borders: {
                bottom: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                left: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                right: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
                top: {
                  color: "#EDF0F3",
                  size: 8,
                  style: BorderStyle.SINGLE,
                },
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      font: "Roobert TRIAL",
                      size: 10,
                      text: "0",
                    }),
                  ],
                  indent: {
                    left: 40,
                  },
                  spacing: {
                    after: 80,
                    before: 40,
                  },
                }),
              ],
            }),
          ],
          height: {
            rule: HeightRule.EXACT,
            value: 450,
          },
        }),
      ],
    });
  }

  public getFlagItemsArray(
    sections: string[],
    sectionDetails: JobDetailsBasicUnit[],
  ): JobDetailsBasicUnit[] {
    const keyGetter = (item: JobDetailsBasicUnit) => {
      return (item.value as SectionData).section;
    };
    const groupedData = getSectionFieldBySection(sectionDetails, keyGetter);
    const flaggedSectionsArray: JobDetailsBasicUnit[] = [];
    for (const section of sections) {
      const sectionInfo = groupedData.get(section)!;
      const flaggedSections = sectionInfo.filter(
        (sectionData) =>
          (sectionData.value as SectionData).value != null &&
          convertArrayToString(
            (sectionData.value as SectionData).value,
          ).toLowerCase() === "no",
      );
      console.error("flaggedSections", flaggedSections);
      flaggedSectionsArray.push(...flaggedSections);
    }
    return flaggedSectionsArray;
  }

  public getInstallationDetails(
    jobUnitDetails: JobDetailsBasicUnit[],
  ): JobDetailsBasicUnit[] {
    const installationDetails = jobUnitDetails.filter(
      (item) => item.key === "Image Details",
    );
    return installationDetails;
  }

  public getSerialNo(jobUnitDetails: JobDetailsBasicUnit[]): string {
    const serialNo = jobUnitDetails.find(
      (item) => item.key === "Serial Number",
    );
    return serialNo?.value as string;
  }

  public getModelNo(jobUnitDetails: JobDetailsBasicUnit[]): string {
    const modelNo = jobUnitDetails.find((item) => item.key === "Model No#");
    return modelNo?.value as string;
  }

  public getUnitId(jobUnitDetails: JobDetailsBasicUnit[]): string {
    const unitId = jobUnitDetails.find((item) => item.key === UNIT_ID);
    return unitId?.value as string;
  }

  public getPageBreak(
    jobUnitDetailsArray: JobDetailsBasicUnit[][],
    index: number,
  ): Paragraph {
    if (index < jobUnitDetailsArray.length - 1) {
      // Page Break for the next section
      return new Paragraph({
        children: [],
        pageBreakBefore: true, // This will create a page break before this paragraph
      });
    }
    return new Paragraph({
      children: [
        new TextRun({
          font: "Roobert TRIAL",
          size: 10,
          text: "",
        }),
      ],
    });
  }
}
