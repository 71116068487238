import { DataSource } from "../../app/jobslist";

import { createSlice } from "@reduxjs/toolkit";

export interface JobListState {
  jobList: DataSource[];
}

const initialState: JobListState = {
  jobList: [],
};

export const jobListSlice = createSlice({
  initialState: initialState,
  name: "jobList",
  reducers: {
    setJobList(state, action) {
      state.jobList = action.payload.jobList;
    },
  },
});

export const { setJobList } = jobListSlice.actions;

export default jobListSlice.reducer;
