import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { api as startupApi } from "../../api";
import { downloadZipSlice } from "../slices/downloadzip";
import { jobListSlice } from "../slices/joblist";

import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";

const rootPersistConfig = {
  blacklist: [startupApi.reducerPath],
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({
  downloadZip: downloadZipSlice.reducer,
  [startupApi.reducerPath]: startupApi.reducer,
  jobList: jobListSlice.reducer,
});

type RootReducer = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootReducer>(
  rootPersistConfig,
  rootReducer,
);

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([startupApi.middleware as Middleware]),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
