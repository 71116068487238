import { useEffect, useRef } from "react";

type ClickCallback = (event: MouseEvent) => void;

export default function useOuterClickExcludingButton(
  buttonRef: React.RefObject<HTMLElement>,
  callback: ClickCallback,
) {
  const outerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        callback(event);
      }
    };

    outerRef.current?.addEventListener("click", handleClick);

    return () => outerRef.current?.removeEventListener("click", handleClick);
  }, [outerRef, buttonRef, callback]);

  return outerRef;
}
