import { api as generatedApi } from "./startup-sdk-graphql";

export const api = generatedApi.enhanceEndpoints({
  endpoints: {
    getJobList: {},
  },
});

export * from "./startup-sdk-graphql";
export * from "./startup-types";
