import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { Route, Routes, useNavigate } from "react-router-dom";

import JobsList from "./jobslist";
// @ts-expect-error: Should expect type
import config from "./oktaConfig";
import { RequiredAuth } from "./secureRoute";

import "./app.module.scss";

export const oktaAuth = new OktaAuth(config.oidc);

export function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<RequiredAuth />}>
          <Route
            path=""
            element={
              <div>
                <JobsList />
              </div>
            }
          />
        </Route>
        <Route path="/login/callback" element={<LoginCallback />} />
      </Routes>
    </Security>
  );
}

export default App;
