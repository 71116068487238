import React, { ReactNode, useState } from "react";

import "../../styles.scss";

interface FloatLabelProps {
  children: ReactNode;
  label: string;
  value: string | undefined;
}

const FloatLabel: React.FC<FloatLabelProps> = ({ children, label, value }) => {
  const [focus, setFocus] = useState<boolean>(true);

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";

  const handleBlur = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  return (
    <div className="float-label" onBlur={handleBlur} onFocus={handleFocus}>
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;
