import { ReactElement, useState } from "react";

import { useOktaAuth } from "@okta/okta-react";
import { Packer } from "docx";
import saveAs from "file-saver";
import Dropdown, { Option } from "react-dropdown";
import { Img } from "react-image";
import ReactLoading from "react-loading";

import { useLazyGetJobListQuery, useUpdateJobsMutation } from "../../api";
import { useAppDispatch } from "../../redux/hooks";
import {
  ImageData,
  JobDetailsBasicUnit,
  JobType,
  renderJobList,
  SectionData,
  Status,
  UNIT_ID,
} from "../jobslist";

import AttachDataToDoc from "./utils/attatchDataToDoc";
import ApprovalAlert from "./approvalAlert";
import { DocumentCreator } from "./documentGenerator";
import ImageWithUrl from "./imageData";
import ImageGrid from "./ImageGrid";
import { InspectionDocumentCreator } from "./InspectionDocument";
import ModalView from "./modalView";
import RoundedButton from "./roundedButton";

import "react-dropdown/style.css";
import styles from "../app.module.scss";

interface Props {
  contractorDetails: JobDetailsBasicUnit[];
  jobDetailsUnitsCollection: JobDetailsBasicUnit[][];
  jobId: string;
  jobType: string;
  startDate: string;
  status: string;
  storeDetails: JobDetailsBasicUnit[];
  technician: string;
}

export function getSectionFieldBySection<JobDetailsBasicUnit, K>(
  list: JobDetailsBasicUnit[],
  keyGetter: (item: JobDetailsBasicUnit) => K,
): Map<K, JobDetailsBasicUnit[]> {
  const map = new Map<K, JobDetailsBasicUnit[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function convertArrayToString<T>(value: T | T[]): string {
  if (Array.isArray(value)) {
    return value.join(" , ");
  } else {
    return value as string; // Explicitly cast to string
  }
}

export function getSections(sectionDetails: JobDetailsBasicUnit[]): string[] {
  const sections = new Set();
  sectionDetails.forEach((sectionInfo: JobDetailsBasicUnit) => {
    sections.add((sectionInfo.value as SectionData).section);
  });
  return Array.from(sections) as string[];
}

const AccordionDetails: React.FC<Props> = ({
  contractorDetails,
  storeDetails,
  jobDetailsUnitsCollection,
  jobId,
  status,
  jobType,
  technician,
  startDate,
}) => {
  const [unitIndex, setUnitIndex] = useState<number>(0);
  const [updateJobs, { isLoading: updateJobsIsLoading }] =
    useUpdateJobsMutation();
  const dispatch = useAppDispatch();
  const [getJobListQuery, { isLoading }] = useLazyGetJobListQuery();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [imageTitle, setImageTitle] = useState<string>("");
  const [isApproveModalVisible, setIsApproveModalVisible] =
    useState<boolean>(false);
  const { authState } = useOktaAuth();
  const [progress, setProgress] = useState<number>(0);

  const renderDetails = (
    info: {
      key: string;
      value: string | Date | null | undefined | ImageData | SectionData;
    }[],
    title: string,
    isHideTitle?: boolean,
  ): ReactElement => {
    return (
      <div className={styles.detailsInfo}>
        <div
          className={[
            styles.detailsHeader,
            isHideTitle && styles.hideHeader,
          ].join(" ")}
        >
          {title}
        </div>
        <div style={{ width: 400 }}>
          {info != null &&
            info.map((item, index) => {
              if (
                item.key !== "Image Details" &&
                item.key !== "Section Details"
              ) {
                return (
                  <div className={styles.detailsBodyView} key={index}>
                    <div className={styles.detailsBodyText}>{item.key}</div>
                    <div className={styles.detailsBodyTextVlaue}>
                      {item.value?.toString()}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  };

  const renderUnitDetails = (
    unitArray: {
      key: string;
      value: string | Date | null | undefined | ImageData | SectionData;
    }[][],
    title: string,
  ): ReactElement => {
    if (!unitArray.length) {
      return <></>;
    }
    const item = unitArray[unitIndex];
    return <div>{renderDetails(item, title)}</div>;
  };

  const renderPhotoDetails = (
    unitArray: {
      key: string;
      value: string | Date | null | undefined | ImageData | SectionData;
    }[][],
  ): ReactElement => {
    if (!unitArray.length) {
      return <></>;
    }
    const item = unitArray[unitIndex];
    return <div>{renderPhotos(item)}</div>;
  };

  const onSelectItem = (arg: Option) => {
    setUnitIndex(Number(arg.value));
  };

  function getSectionFieldBySection<JobDetailsBasicUnit, K>(
    list: JobDetailsBasicUnit[],
    keyGetter: (item: JobDetailsBasicUnit) => K,
  ): Map<K, JobDetailsBasicUnit[]> {
    const map = new Map<K, JobDetailsBasicUnit[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const renderInspectionDetails = () => {
    const jobUnitDetails = jobDetailsUnitsCollection[unitIndex];
    const keyGetter = (item: JobDetailsBasicUnit) => {
      return (item.value as SectionData).section;
    };
    const sectionDetails = jobUnitDetails.filter(
      (item) => item.key === "Section Details",
    );
    const sortBySectionOrder = sectionDetails.sort(function (a, b) {
      return (
        (a.value as SectionData).sectionOrder -
        (b.value as SectionData).sectionOrder
      );
    });
    const sections = getSections(sortBySectionOrder);
    const groupedData = getSectionFieldBySection(sortBySectionOrder, keyGetter);
    return (
      <div className={styles.mainSection}>
        {sections.map((section, index) => {
          return (
            <>
              <div className={styles.sectionHeader} key={section + index}>
                <h3>{section}</h3>
              </div>
              {renderSectionInfo(groupedData.get(section)!, index, section)}
            </>
          );
        })}
      </div>
    );
  };

  const renderSectionInfo = (
    sectionData: JobDetailsBasicUnit[],
    index: number,
    section: string,
  ): ReactElement => {
    const sortByOrder = sectionData.sort(function (a, b) {
      return (a.value as SectionData).order - (b.value as SectionData).order;
    });
    const filterSections = sortByOrder.filter(
      (section) => (section.value as SectionData).valueType !== "PICTURE",
    );
    const imageSections = sortByOrder.filter(
      (section) => (section.value as SectionData).valueType === "PICTURE",
    );
    return (
      <div className={styles.sectionFieldInfo} key={section + index}>
        <div className={styles.sectionData}>
          {filterSections.map((item: JobDetailsBasicUnit, unitIndex) => {
            return (
              <div
                className={styles.sectionFieldInfo}
                key={(item.value as SectionData).sectionField + unitIndex}
              >
                <div className={styles.leftText}>
                  {(item.value as SectionData).sectionField}
                </div>
                <div className={styles.rightText}>
                  {convertArrayToString((item.value as SectionData).value)}
                </div>
              </div>
            );
          })}
        </div>
        {
          <ImageGrid
            sectionData={imageSections}
            onClick={(imageUrl: string, title: string) => {
              setImageUrl(imageUrl);
              setIsModalVisible(true);
              setImageTitle(title);
            }}
          />
        }
      </div>
    );
  };

  const renderDropDown = (
    info: {
      key: string;
      value: string | Date | null | undefined | ImageData | SectionData;
    }[][],
  ) => {
    const options: Option[] = [];

    if (jobType === JobType.Inspection) {
      jobDetailsUnitsCollection.forEach(
        (jobDetailsUnits, jobDetailsUnitsCollectionIndex) => {
          jobDetailsUnits
            .filter((jobDetailsUnit) => jobDetailsUnit.key === UNIT_ID)
            .forEach((jobDetailsUnit) => {
              options.push({
                label: jobDetailsUnit.value?.toString() || "",
                value: jobDetailsUnitsCollectionIndex.toString(),
              });
            });
        },
      );
    } else {
      info.forEach((item, index) => {
        options.push({ label: `Unit ${index + 1}`, value: index.toString() });
      });
    }

    const defaultOption = options[unitIndex];

    return (
      <Dropdown
        className={styles.dropDown}
        controlClassName={styles.dropDownClass}
        options={options}
        menuClassName={styles.unitDropDownItem}
        onChange={onSelectItem}
        value={defaultOption}
        placeholder="Select an option"
        arrowClassName={styles.arrowStyle}
      />
    );
  };

  const renderPhotos = (
    info: {
      key: string;
      value: string | Date | null | undefined | ImageData | SectionData;
    }[],
  ): ReactElement => {
    return (
      <div className={styles.photoGrid}>
        {info.map((item, index) => {
          if (item.key === "Image Details") {
            const imageInfo = item.value as ImageData;
            return (
              <div key={index} className={styles.photoInfo}>
                <ImageWithUrl
                  fileName={imageInfo.photo}
                  title={imageInfo.stepType}
                  key={index}
                  onClick={(imageUrl: string, title: string) => {
                    setImageUrl(imageUrl);
                    setIsModalVisible(true);
                    setImageTitle(title);
                  }}
                />
              </div>
            );
          }
          return <></>;
        })}
      </div>
    );
  };

  const onApproveClick = async (isDownloadChecked: boolean) => {
    if (status === Status.NotApproved) {
      await updateJobs({
        ids: [jobId],
        status: Status.Approved.toUpperCase(),
      });
      await getJobListInfo();
    }
    if (isDownloadChecked) {
      onDownloadClick();
    }
    setIsApproveModalVisible(false);
  };

  const onDownloadClick = async () => {
    if (jobType === JobType.Inspection) {
      setProgress(1);
      const documentCreator = new InspectionDocumentCreator();
      const doc = await documentCreator.create(
        authState,
        jobDetailsUnitsCollection,
        storeDetails,
        technician,
        startDate,
        (currentProgress) => {
          setProgress(currentProgress);
        },
      );
      setProgress(100);
      Packer.toBlob(doc).then((blob) => {
        const storeName = storeDetails.find(
          (item) => item.key === "Store Name",
        )?.value;
        saveAs(blob, `${storeName + " Report"}`);
      });
    } else {
      setProgress(1);
      await AttachDataToDoc({
        authState,
        contractorDetails,
        jobDetailsUnitsCollection,
        storeDetails,
      });

      const documentCreator = new DocumentCreator();
      const doc = await documentCreator.create(jobDetailsUnitsCollection);
      setProgress(100);
      Packer.toBlob(doc).then((blob) => {
        const storeName = storeDetails.find(
          (item) => item.key === "Store Name",
        )?.value;
        saveAs(blob, `${storeName + " Report"}`);
      });
    }
  };

  const getJobListInfo = async () => {
    const jobListResponse = await getJobListQuery();
    const jobList = jobListResponse.data;
    renderJobList(jobList, dispatch);
  };

  const renderImage = (): ReactElement => {
    return (
      <div>
        <div className={styles.modalTitle}>{"Photo Preview"}</div>
        <Img
          src={imageUrl}
          className={styles.imageLoadView}
          loader={
            <div className={styles.loading}>
              <ReactLoading
                type={"spin"}
                color={"#002EFF"}
                height={50}
                width={50}
              />
            </div>
          }
        />
        <div className={styles.modalFooter}>{imageTitle}</div>
      </div>
    );
  };

  const renderApproveDetails = (): ReactElement => {
    return (
      <ApprovalAlert
        onApproveClick={onApproveClick}
        onCancelClick={() => setIsApproveModalVisible(false)}
        itemsCount={1}
        isBulkApproval={false}
      />
    );
  };

  return (
    <div>
      <div className={styles.detailsBodyView}>
        {renderDetails(storeDetails, "Store Details")}
        {renderDetails(contractorDetails, " ", true)}
      </div>
      <div className={styles.seperator}></div>
      <div className={styles.detailsBodyViewBottom}>
        <div className={styles.detailsBottomView}>
          {renderDropDown(jobDetailsUnitsCollection)}
          {renderUnitDetails(jobDetailsUnitsCollection, "")}
        </div>
        <div className={styles.fillRemainingSpace}></div>
        <div className={styles.footerStyle}>
          {status === Status.Approved && (
            <div className={styles.statusLabel}>{Status.Approved}</div>
          )}
          <RoundedButton
            label={
              status === Status.Approved
                ? progress > 0 && progress < 100
                  ? progress === 1
                    ? "Downloading"
                    : progress.toString() + "%"
                  : "Download"
                : "Approve"
            }
            isDisabled={progress > 0 && progress < 100}
            onClick={() => {
              status === Status.NotApproved
                ? setIsApproveModalVisible(true)
                : onDownloadClick();
            }}
            isDownload={status === Status.Approved}
          />
        </div>
      </div>
      <div className={styles.imageHeader}>
        <h3>Installation Photos</h3>
      </div>
      {renderPhotoDetails(jobDetailsUnitsCollection)}
      {jobType === JobType.Inspection && (
        <div className={styles.imageHeader}>
          <h3>Inspection Details</h3>
        </div>
      )}
      {renderInspectionDetails()}
      {imageUrl && (
        <ModalView
          visible={isModalVisible}
          loading={false}
          footer={null}
          component={renderImage}
          onModalClose={() => {
            setImageUrl("");
            setIsModalVisible(false);
          }}
          isImageViewer={true}
        />
      )}
      <ModalView
        visible={isApproveModalVisible}
        loading={false}
        footer={null}
        component={renderApproveDetails}
        onModalClose={() => {
          setIsApproveModalVisible(false);
        }}
        customClassName="custom-modal"
      />
      <ModalView
        visible={updateJobsIsLoading || isLoading}
        loading={true}
        footer={null}
      />
    </div>
  );
};

export default AccordionDetails;
