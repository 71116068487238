import React from "react";

import { Switch, Tooltip } from "antd";

import infoIcon from "../../assets/ic_infoIcon.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { downloadZipSlice } from "../../redux/slices/downloadzip";

import RoundedButton from "./roundedButton";

import "../../styles.scss";

interface Props {
  isBulkApproval: boolean;
  itemsCount?: number;
  onApproveClick: (isDownloadChecked: boolean) => void;
  onCancelClick: () => void;
}
const ApprovalAlert: React.FC<Props> = ({
  onApproveClick,
  onCancelClick,
  isBulkApproval,
}) => {
  const dispatch = useAppDispatch();
  const data: { downloadZip: boolean } = useAppSelector(
    (state) => state.downloadZip,
  );
  const onChange = (checked: boolean) => {
    dispatch(
      downloadZipSlice.actions.setDownloadZip({
        downloadZip: checked,
      }),
    );
  };

  return (
    <div className="approval-modal-view">
      <Tooltip
        color={"#F3F5F7"}
        title={
          <div className="approval-modal-tooltip">
            {
              "You can toggle the option below to choose whether or not to download an archive with the associated documents."
            }
          </div>
        }
        className="approval-modal-tooltip-view"
      >
        <img className="info-icon" src={infoIcon} alt="" />
      </Tooltip>
      <div className="approval-modal-title">{"Approval Confirmation"}</div>
      <div className="approval-modal-message">
        {"You are about to approve this item. Are you sure?"}
      </div>
      <div className="approval-modal-body-view">
        <div className="approval-modal-footer-view-title">
          {isBulkApproval
            ? "Download the zip file containing all approved documents"
            : "Download approved document"}
        </div>
        <Switch
          defaultChecked={data.downloadZip}
          onChange={onChange}
          value={data.downloadZip}
          className="approval-modal-switch"
        />
      </div>
      <div className="approval-modal-footer">
        <RoundedButton
          label={"Approve"}
          onClick={() => onApproveClick(data.downloadZip)}
          isApproveModalButton={true}
        />
        <RoundedButton
          label={"Cancel"}
          onClick={onCancelClick}
          isApproveModalButton={true}
        />
      </div>
    </div>
  );
};

export default ApprovalAlert;
