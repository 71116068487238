import React, {
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useOktaAuth } from "@okta/okta-react";
import { Checkbox, Input, Pagination, Select, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { Document, Packer } from "docx";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import { useLazyGetJobListQuery, useUpdateJobsMutation } from "../../api";
import downArrow from "../../assets/ic_downArrow.png";
import upArrow from "../../assets/ic_upArrow.png";
import { useAppDispatch } from "../../redux/hooks";
import { downloadZipSlice } from "../../redux/slices/downloadzip";
import AccordionDetails from "../components/accordianDetails";
import { Column, DataSource, renderJobList, Status } from "../jobslist";

import AttachDataToDoc from "./utils/attatchDataToDoc";
import ApprovalAlert from "./approvalAlert";
import { DocumentCreator } from "./documentGenerator";
import FloatLabel from "./floatingLabel";
import HighlightedText from "./highlightedText";
import ModalView from "./modalView";
import RoundedButton from "./roundedButton";

import styles from "../app.module.scss";

interface Props {
  columns: Column[];
  data: DataSource[];
}

const FilterType = {
  ALL: "all",
  CONTRACTOR: "contractor",
  JOB_TYPE: "jobtype",
  STORE: "store",
  TECHNICIAN: "technician",
};

const Accordion: React.FC<Props> = ({ data, columns }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortInfo, setSortInfo] = useState<{
    columnKey?: keyof DataSource;
    order?: "ascend" | "descend";
  }>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);
  const [isApproveEnabled, setIsApproveEnabled] = useState<boolean>(false);
  // const [isDeleteEnabled, setIsDeleteEnabled] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<"All" | keyof DataSource>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [selectedJobIds, setSelectedJobIds] = useState<string[]>([]);
  const [isApproveModalVisible, setIsApproveModalVisible] =
    useState<boolean>(false);
  const [updateJobs, { isLoading: updateJobsIsLoading }] =
    useUpdateJobsMutation();
  const [getJobListQuery, { isLoading }] = useLazyGetJobListQuery();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();

  const handleExpand = (expanded: boolean, record: DataSource) => {
    const { key } = record;
    const newExpandedRowKeys = expanded ? [key] : ([] as string[]);
    setExpandedRowKeys(newExpandedRowKeys as SetStateAction<string[]>);
  };

  const [pageSize, setPageSize] = useState(10);

  const dataInfoArray: DataSource[] = Object.values(data).map((item) => item);

  const filteredData = dataInfoArray?.filter((item) => {
    const searchLower = searchValue?.toLowerCase() || "";
    const filterLower = filterValue?.toLowerCase();
    const { store = "", contractor = "", technician = "", jobType = "" } = item;

    const fieldMatchesSearch = (field: string) =>
      field.toLowerCase().includes(searchLower);

    if (!filterLower || filterLower === FilterType.ALL) {
      return (
        fieldMatchesSearch(store) ||
        fieldMatchesSearch(contractor) ||
        fieldMatchesSearch(technician) ||
        fieldMatchesSearch(jobType)
      );
    }

    switch (filterLower) {
      case FilterType.JOB_TYPE:
        return fieldMatchesSearch(jobType);
      case FilterType.STORE:
        return fieldMatchesSearch(store);
      case FilterType.CONTRACTOR:
        return fieldMatchesSearch(contractor);
      case FilterType.TECHNICIAN:
        return fieldMatchesSearch(technician);
      default:
        return false; // Handle unexpected filter values
    }
  });

  const isPrevAvilable: boolean = currentPage > 1;
  const isNextAvilable: boolean =
    currentPage < Math.ceil(filteredData.length / 10);

  const modifiedColumns = [
    {
      dataIndex: "selection",
      key: "selection",
      render: (_: SortOrder, record: { key: number }) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const keys = e.target.checked
              ? [...selectedRowKeys, record.key]
              : selectedRowKeys.filter((key) => key !== record.key);
            setSelectedRowKeys(keys);
          }}
        />
      ),
      title: (
        <Checkbox
          onChange={(e) => {
            const selectedKeys = e.target.checked
              ? data.map((item) => item.key)
              : [];
            setSelectedRowKeys(selectedKeys);
          }}
        />
      ),
    },
    ...columns.map((column) => {
      const isHighlightedColumn =
        !filterValue || filterValue.toLowerCase() === "all"
          ? [
              FilterType.STORE,
              FilterType.JOB_TYPE,
              FilterType.CONTRACTOR,
              FilterType.TECHNICIAN,
            ].includes(column.key.toLowerCase())
          : column.key.toLowerCase() === filterValue.toLowerCase();

      return {
        ...column,
        ...(isHighlightedColumn && {
          render: (text: string) => (
            <HighlightedText title={text} searchWords={[searchValue]} />
          ),
        }),
        sortDirections: ["ascend", "descend"] as SortOrder[],
        sortOrder:
          column.dataIndex === sortInfo.columnKey ? sortInfo.order : undefined,
        sorter: column.sorter,
      };
    }),
  ];

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const onChangeSort = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataSource> | SorterResult<DataSource>[],
  ) => {
    if (Array.isArray(sorter) && sorter.length > 0 && sorter[0]?.columnKey) {
      const { columnKey, order } = sorter[0];
      setSortInfo({
        columnKey: columnKey as keyof DataSource,
        order: order || undefined,
      });
    } else if (!Array.isArray(sorter) && sorter?.columnKey) {
      const { columnKey, order } = sorter;
      setSortInfo({
        columnKey: columnKey as keyof DataSource,
        order: order || undefined,
      });
    } else {
      setSortInfo({});
    }
  };

  const onChange = (value: string) => {
    setFilterValue(value as keyof DataSource);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const ids = [];
    const jobStatus = [];
    for (const key of selectedRowKeys) {
      const record = data[key];
      const { status } = record;
      if (status === Status.NotApproved) {
        jobStatus.push(status);
        ids.push(data[key].id);
      } else if (status === Status.Approved) {
        jobStatus.push(status);
      }
    }
    const isJobStatusSelected = (status: string, jobStatus: Status[]) => {
      const filterStatus = jobStatus.filter(
        (statusInfo) => statusInfo === status,
      );
      return (
        jobStatus.length > 0 && filterStatus.length === selectedRowKeys.length
      );
    };
    setIsApproveEnabled(isJobStatusSelected(Status.NotApproved, jobStatus));
    setIsDownloadEnabled(isJobStatusSelected(Status.Approved, jobStatus));
    setSelectedJobIds(ids);
  }, [data, selectedRowKeys]);

  const onApproveClick = async (isDownloadChecked: boolean) => {
    setIsApproveModalVisible(false);
    await updateJobs({
      ids: selectedJobIds,
      status: Status.Approved.toUpperCase(),
    });
    await getJobListInfo();
    setSelectedRowKeys([]);
    if (isDownloadChecked) {
      onDownloadClick();
    }
  };

  const onDownloadClick = async () => {
    const blobList = [];
    for (const key of selectedRowKeys) {
      const record = data[key];
      const blobInfo = await addDataInDocument(record);
      blobList.push(blobInfo);
    }
    onCreateZip(blobList);
    setSelectedRowKeys([]);
  };

  const addDataInDocument = async (
    record: DataSource,
  ): Promise<{ blob: Blob; name: string }> => {
    const { contractorDetails, storeDetails, jobDetailsUnitsCollection } =
      record;
    await AttachDataToDoc({
      authState,
      contractorDetails,
      jobDetailsUnitsCollection,
      storeDetails,
    });

    const documentCreator = new DocumentCreator();
    const doc: Document = await documentCreator.create(
      jobDetailsUnitsCollection,
    );

    return Packer.toBlob(doc).then((blob) => {
      const storeName = storeDetails.find(
        (item) => item.key === "Store Name",
      )?.value;
      return { blob, name: typeof storeName === "string" ? storeName : "" };
    });
  };

  const onCreateZip = (blobs: { blob: Blob; name: string }[]) => {
    const zip = new JSZip();
    const blobPromises = blobs.map((blobInfo) => {
      return zip.file(`${blobInfo.name}.docx`, blobInfo.blob);
    });
    Promise.all(blobPromises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "checklistDocuments.zip");
      });
    });
  };

  const getJobListInfo = async () => {
    const jobListResponse = await getJobListQuery();
    const jobList = jobListResponse.data;
    renderJobList(jobList, dispatch);
  };

  const renderApproveDetails = (): ReactElement => {
    return (
      <ApprovalAlert
        onApproveClick={onApproveClick}
        onCancelClick={() => setIsApproveModalVisible(false)}
        itemsCount={selectedRowKeys.length}
        isBulkApproval={true}
      />
    );
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div className={styles.detailsView}>
      <div className={styles.tableHeaderView}>
        <div className={styles.tableHeaderSubView}>
          <div className={styles.headerViewInfo}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e.target.value)}
              className={styles.searchStyle}
            />
          </div>
          <div className={styles.filterStyle}>
            <FloatLabel label="Filter by" value={filterValue}>
              <Select
                defaultValue="All"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                className="ant-select-custom"
                options={[
                  {
                    label: "All",
                    value: "All",
                  },
                  {
                    label: "Job Type",
                    value: "JobType",
                  },
                  {
                    label: "Store Details",
                    value: "Store",
                  },
                  {
                    label: "Contractor Details",
                    value: "Contractor",
                  },
                  {
                    label: "Technician",
                    value: "Technician",
                  },
                ]}
              />
            </FloatLabel>
          </div>
        </div>
        <div className={styles.tableHeaderSubView}>
          {/* <RoundedButton
            label={"Delete"}
            isDisabled={!isDeleteEnabled}
            onClick={() => {
              setIsDeleteEnabled(true);
            }}
          /> */}
          <RoundedButton
            label={"Download"}
            isDisabled={!isDownloadEnabled}
            onClick={onDownloadClick}
            isDownload={true}
          />
          <RoundedButton
            label={"Approve"}
            isDisabled={!isApproveEnabled}
            onClick={() => {
              dispatch(
                downloadZipSlice.actions.setDownloadZip({
                  downloadZip: true,
                }),
              );
              setIsApproveModalVisible(true);
            }}
          />
        </div>
      </div>

      <div className={styles.tableView}>
        <Table
          className="custom-table"
          expandIconColumnIndex={modifiedColumns.length}
          columns={modifiedColumns.map((column) => ({
            ...column,
            align: "center",
          }))}
          dataSource={
            filteredData.length < 10
              ? filteredData
              : filteredData.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize,
                )
          }
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <div onClick={(e) => onExpand(record, e)}>
                <img src={upArrow} className={styles.arrowImageStyle} />
              </div>
            ) : (
              <div onClick={(e) => onExpand(record, e)}>
                <img src={downArrow} className={styles.arrowImageStyle} />
              </div>
            )
          }
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: (record) => {
              return (
                <div className={styles.tableExpandleView}>
                  <AccordionDetails
                    storeDetails={record.storeDetails}
                    contractorDetails={record.contractorDetails}
                    jobDetailsUnitsCollection={record.jobDetailsUnitsCollection}
                    jobId={record.id}
                    status={record.status}
                    jobType={record.jobType}
                    technician={record.technician}
                    startDate={record.date}
                  />
                </div>
              );
            },
          }}
          pagination={false}
          onChange={onChangeSort}
          footer={() => (
            <div className={styles.footerStyle}>
              <div
                onClick={() =>
                  isPrevAvilable ? setCurrentPage(currentPage - 1) : null
                }
                className={styles.arrowLeftStyle}
                style={{
                  cursor: isPrevAvilable ? "pointer" : "not-allowed",
                  opacity: isPrevAvilable ? 1 : 0.5,
                }}
              >
                <ArrowLeftOutlined className={styles.arrowLeftOutlinedStyle} />
                <span>Previous</span>
              </div>
              <div className={styles.paginationStyle}>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={filteredData.length}
                  onChange={onChangePage}
                  prevIcon={null}
                  nextIcon={null}
                  onShowSizeChange={handlePageSizeChange}
                />
              </div>
              <div
                onClick={() =>
                  isNextAvilable ? setCurrentPage(currentPage + 1) : null
                }
                className={styles.arrowRightOutlinedViewStyle}
                style={{
                  cursor: isNextAvilable ? "pointer" : "not-allowed",
                  opacity: isNextAvilable ? 1 : 0.5,
                }}
              >
                <span>Next</span>
                <ArrowRightOutlined
                  className={styles.arrowRightOutlinedStyle}
                />
              </div>
            </div>
          )}
          expandedRowKeys={expandedRowKeys}
          onExpand={handleExpand}
        />
      </div>
      <ModalView
        visible={updateJobsIsLoading || isLoading}
        loading={true}
        footer={null}
      />
      <ModalView
        visible={isApproveModalVisible}
        loading={false}
        footer={null}
        component={renderApproveDetails}
        onModalClose={() => {
          setIsApproveModalVisible(false);
        }}
        customClassName="custom-modal"
      />
    </div>
  );
};

export default Accordion;
