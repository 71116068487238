import { environment } from "../environments/environment";

const CLIENT_ID = environment.clientId;
const ISSUER = environment.issuer;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = "";
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    issuer: ISSUER,
    pkce: false,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
  },
};
